import dayjs, { Dayjs } from 'dayjs'
import duration from 'dayjs/plugin/duration'

import { DateFormat, DateTimeSubtype } from '../requests'

dayjs.extend(duration)

const parseDurationNumbers = (num: number) => (num > 9 ? num : `0${num}`)

export const humanizeDuration = (duration: duration.Duration) => {
  return `${parseDurationNumbers(duration.hours())}:${parseDurationNumbers(duration.minutes())}:${parseDurationNumbers(
    duration.seconds()
  )}`
}

export const getDateTimeSubtypeFormat = (dateFormat?: DateFormat) => {
  switch (dateFormat) {
    case DateFormat.AU:
      return {
        [DateTimeSubtype.Time]: 'HH:mm',
        [DateTimeSubtype.Date]: 'D/MM/YYYY',
        [DateTimeSubtype.DateTime]: 'D/MM/YYYY HH:mm',
        [DateTimeSubtype.Year]: 'YYYY',
        [DateTimeSubtype.YearMonth]: 'MM/YYYY',
        [DateTimeSubtype.SwitchableDate]: ''
      }
    case DateFormat.PH:
      return {
        [DateTimeSubtype.Time]: 'HH:mm',
        [DateTimeSubtype.Date]: 'M/D/YYYY',
        [DateTimeSubtype.DateTime]: 'M/D/YYYY HH:mm',
        [DateTimeSubtype.Year]: 'YYYY',
        [DateTimeSubtype.YearMonth]: 'M/YYYY',
        [DateTimeSubtype.SwitchableDate]: ''
      }
    case DateFormat.DE:
      return {
        [DateTimeSubtype.Time]: 'HH:mm',
        [DateTimeSubtype.Date]: 'DD.MM.YYYY',
        [DateTimeSubtype.DateTime]: 'DD.MM.YYYY HH:mm',
        [DateTimeSubtype.Year]: 'YYYY',
        [DateTimeSubtype.YearMonth]: 'MM.YYYY',
        [DateTimeSubtype.SwitchableDate]: ''
      }
    case DateFormat.US:
      return {
        [DateTimeSubtype.Time]: 'HH:mm',
        [DateTimeSubtype.Date]: 'MM/DD/YYYY',
        [DateTimeSubtype.DateTime]: 'MM/DD/YYYY HH:mm',
        [DateTimeSubtype.Year]: 'YYYY',
        [DateTimeSubtype.YearMonth]: 'MM/YYYY',
        [DateTimeSubtype.SwitchableDate]: ''
      }
    case DateFormat.UK:
      return {
        [DateTimeSubtype.Time]: 'HH:mm',
        [DateTimeSubtype.Date]: 'DD/MM/YYYY',
        [DateTimeSubtype.DateTime]: 'DD/MM/YYYY HH:mm',
        [DateTimeSubtype.Year]: 'YYYY',
        [DateTimeSubtype.YearMonth]: 'MM/YYYY',
        [DateTimeSubtype.SwitchableDate]: ''
      }
    case DateFormat.CA:
      return {
        [DateTimeSubtype.Time]: 'HH:mm',
        [DateTimeSubtype.Date]: 'YYYY-MM-DD',
        [DateTimeSubtype.DateTime]: 'YYYY-MM-DD HH:mm',
        [DateTimeSubtype.Year]: 'YYYY',
        [DateTimeSubtype.YearMonth]: 'YYYY-MM',
        [DateTimeSubtype.SwitchableDate]: ''
      }

    default:
      return {
        [DateTimeSubtype.Time]: 'HH:mm',
        [DateTimeSubtype.Date]: 'YYYY-MM-DD',
        [DateTimeSubtype.DateTime]: 'YYYY-MM-DDTHH:mm',
        [DateTimeSubtype.Year]: 'YYYY',
        [DateTimeSubtype.YearMonth]: 'YYYY-MM',
        [DateTimeSubtype.SwitchableDate]: ''
      }
  }
}

export const parseOutputDateTime = (value: Dayjs, subtype: DateTimeSubtype): string => {
  return value && subtype ? value.format(getDateTimeSubtypeFormat()[subtype]) : undefined
}

export const parseInputDateTime = (value: string, subtype: DateTimeSubtype): Dayjs => {
  return value && subtype ? dayjs(value, getDateTimeSubtypeFormat()[subtype]) : undefined
}

export const getSwitchableDateType = (value: string) => {
  const validValue = (subtype: DateTimeSubtype) =>
    dayjs(value, getDateTimeSubtypeFormat()[subtype], true).isValid() ? value : undefined

  if (validValue(DateTimeSubtype.Date)) return DateTimeSubtype.Date
  if (validValue(DateTimeSubtype.YearMonth)) return DateTimeSubtype.YearMonth
  return DateTimeSubtype.Year
}

interface DisableFutureTimeHelper {
  type: 'hours' | 'minutes'
  selectedHour?: number
  selectedDate?: Dayjs
}
export const disableFutureTimeHelper = ({ type, selectedHour, selectedDate }: DisableFutureTimeHelper): number[] => {
  if (selectedDate && selectedDate.isBefore(dayjs(), 'day')) {
    return []
  }

  const hour = dayjs().hour()
  const minutes = dayjs().minute()

  if (type === 'hours') {
    return Array.from({ length: 24 - hour }, (_, i) => i + hour + 1)
  }

  if (type === 'minutes') {
    return selectedHour !== hour ? [] : Array.from({ length: 60 - minutes }, (_, i) => i + minutes + 1)
  }
  return []
}
