import { createErrorsHandlers, prepareSorter } from '../../utils'
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'
import { CenterData, CenterStatus, CentersSorter, RemoteCenterData, parseRemoteCenterData } from '../generalSettings'

const getCentersUrl = (studyId: string) => `studies/${studyId}/centers`

interface FetchStudyCentersOptions {
  studyId: string
  options?: {
    limit?: number
    offset?: number
    sorter?: CentersSorter
    search?: string
    filters?: Record<string, string[]>
  }
}

interface FetchStudyCentersSuccessOptions {
  centers: CenterData[]
  allCentersCount: number
  countries: string[]
}
interface FetchStudyCentersResponseHandlers {
  onSuccess?: (options: FetchStudyCentersSuccessOptions) => void
  onRequestError?: (code: number) => void
}

interface FetchStudyCentersResponse {
  count: number
  countries: string[]
  results: RemoteCenterData[]
}

export const fetchStudyCenters = (
  { studyId, options }: FetchStudyCentersOptions,
  responseHandlers?: FetchStudyCentersResponseHandlers
) => {
  const sorter = prepareSorter<null, CentersSorter>(null, options.sorter, 'name')
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    search: options.search,
    status: options.filters?.status || CenterStatus.Active,
    country: options.filters?.country
  }

  const { req, cancel } = fetchApi.get<FetchStudyCentersResponse>(getCentersUrl(studyId), query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchStudyCentersResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        centers: body.results.map(parseRemoteCenterData),
        allCentersCount: body.count,
        countries: body.countries
      })
    }
  })

  return cancel
}

interface DeleteStudyCenterOptions {
  studyId: string
  centerId: string
}

interface DeleteStudyCenterResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onCenterCannotBeRemoved?: () => void
  onCenterCantDeleteLast?: () => void
  onError?: () => void
}

export const deleteStudyCenter = (
  { studyId, centerId }: DeleteStudyCenterOptions,
  responseHandlers?: DeleteStudyCenterResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete(`${getCentersUrl(studyId)}/${centerId}`, {}, { studyId })
  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<DeleteStudyCenterResponseHandlers>(
        {
          [BackendError.STUDY_CENTER_CANT_DELETE_USED]: 'onCenterCannotBeRemoved',
          [BackendError.STUDY_CENTER_CANT_DELETE_LAST]: 'onCenterCantDeleteLast'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface AddStudyCentersOptions {
  studyId: string
  centerIds: string[]
}

interface AddStudyCentersResponseHandlers {
  onSuccess?: () => void
  onTooManyCenters?: () => void
  onRequestError?: (code: number) => void
  onError?: () => void
}

export const addStudyCenter = (
  { studyId, centerIds }: AddStudyCentersOptions,
  responseHandlers?: AddStudyCentersResponseHandlers
) => {
  const { req, cancel } = fetchApi.post<AddStudyCentersOptions>(
    getCentersUrl(studyId),
    { center_ids: centerIds },
    { studyId }
  )

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<AddStudyCentersResponseHandlers>(
        {
          [BackendError.STUDY_CENTERS_LIMIT_REACHED]: 'onTooManyCenters'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface EditStudyCenterOptions {
  centerId: string
  studyId: string
  data: {
    code: string
    target: number
  }
}

interface EditStudyCenterResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onCodeNotUnique?: () => void
  onCodeNotEditable?: () => void
}

export const editStudyCenter = (
  { centerId, studyId, data }: EditStudyCenterOptions,
  responseHandlers?: EditStudyCenterResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch(`${getCentersUrl(studyId)}/${centerId}`, { ...data })
  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<EditStudyCenterResponseHandlers>(
        {
          [BackendError.STUDY_CENTER_CODE_ALREADY_EXISTS]: 'onCodeNotUnique',
          [BackendError.STUDY_CENTER_CODE_NOT_ALLOW_TO_MODIFY]: 'onCodeNotEditable'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
