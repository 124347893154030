/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { fetchApi } from '../fetchApi'
import { PaymentStatsValues } from '../payments/stats'

export interface PaymentOrderStats {
  processing: PaymentStatsValues
  error: PaymentStatsValues
  paid: PaymentStatsValues
  total: PaymentStatsValues
}

interface FetchPaymentOrdersOptions {
  options?: {
    search?: string
    filters?: {
      status: string[]
      center: string[]
      recruitment_reference: string[]
    }
  }
}

interface FetchPaymentOrderStatsResponseHandlers {
  onSuccess?: (stats: PaymentOrderStats) => void
  onRequestError?: (code: number) => void
}

export const fetchPaymentOrderStats = (
  { options }: FetchPaymentOrdersOptions,
  responseHandlers: FetchPaymentOrderStatsResponseHandlers
) => {
  const query = {
    search: options.search,
    status: options.filters?.status,
    center: options.filters?.center,
    recruitment_reference: options.filters?.recruitment_reference
  }
  const { req, cancel } = fetchApi.get<PaymentOrderStats>('payments/orders/stats', query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchPaymentOrderStatsResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}
