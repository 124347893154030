/* eslint-disable camelcase */
import { ContentState, EditorState, convertFromHTML, convertFromRaw, convertToRaw } from 'draft-js'

import { createErrorsHandlers } from '../../utils'
import { fetchApi } from '../fetchApi'

enum ConsentUrl {
  Single = 'customer_settings/consents',
  List = 'customer_settings/consent_list'
}

const parseConsentBodyForSave = (body: ContentState | EditorState) => {
  try {
    return JSON.stringify(convertToRaw((body as EditorState)?.getCurrentContent()))
  } catch {
    return JSON.stringify(convertToRaw(body as ContentState))
  }
}

const parseRemoteConsentBody = (remoteBody: string) => {
  try {
    return convertFromRaw(JSON.parse(remoteBody))
  } catch {
    const blocksFromHTML = convertFromHTML(remoteBody)
    return ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
  }
}

export interface CustomConsent {
  id?: number
  order?: number
  body: ContentState | EditorState
}

interface RemoteCustomConsent {
  id?: number
  order?: number
  body: string
}

interface CustomConsentResponse {
  results: RemoteCustomConsent[]
}

interface FetchCustomConsentsResponseHandlers {
  onSuccess?: (consents: CustomConsent[]) => void
  onRequestError?: (code: number) => void
}

export const fetchCustomConsents = (
  { language }: { language?: string },
  responseHandlers?: FetchCustomConsentsResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<CustomConsentResponse>(ConsentUrl.List, {}, { language })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchCustomConsentsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(
        body.results.map(consent => ({ ...consent, body: parseRemoteConsentBody(consent.body) }))
      )
    }
  })

  return cancel
}

interface UpdateCustomConsentResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const updateCustomConsent = (
  { id, body, order }: CustomConsent,
  responseHandlers?: UpdateCustomConsentResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch(`${ConsentUrl.Single}/${id}`, {
    body: parseConsentBodyForSave(body),
    order: order == null ? undefined : order
  })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<UpdateCustomConsentResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface DeleteCustomConsentResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const deleteCustomConsent = ({ id }: { id: number }, responseHandlers?: DeleteCustomConsentResponseHandlers) => {
  const { req, cancel } = fetchApi.delete(`${ConsentUrl.Single}/${id}`)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<DeleteCustomConsentResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface AddCustomConsentResponseHandlers {
  onSuccess?: () => void
  onLimitReached?: () => void
  onRequestError?: (code: number) => void
  onError?: () => void
}

export const addCustomConsent = ({ body }: CustomConsent, responseHandlers?: AddCustomConsentResponseHandlers) => {
  const { req, cancel } = fetchApi.post(ConsentUrl.Single, { body: parseConsentBodyForSave(body), order: 0 })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<AddCustomConsentResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
