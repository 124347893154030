/* eslint-disable camelcase */
import { getLanguage } from '../../utils'
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'
import { fetchOpenEndpointFilesUrls } from '../file'
import {
  BaseRemoteQuestionAnswer,
  FileAnswer,
  RemoteFileAnswer,
  SurveyAnswer,
  SurveyData,
  SurveyRemoteBlocks,
  deleteAnswer,
  deleteFileAnswer,
  downloadFileAnswer,
  fetchConditionalLogic,
  parseRemoteFileAnswer,
  parseRemoteSurveyAnswer,
  parseSurveyBlocks,
  saveAnswer,
  saveFileAnswer
} from '../forms'

export interface EproSurveyRemoteQuestionAnswer extends BaseRemoteQuestionAnswer {
  epro_record: string
}

export interface RemoteEproSurvey {
  subject_id: string
  survey_name: string
  epro_record: number
  blocks: SurveyRemoteBlocks[]
}

interface FetchEproSurveyOptions {
  token: string
}

interface FetchEproSurveyResponseHandlers {
  onSuccess?: (survey: SurveyData) => void
  onNotFound?: () => void
  onExpired?: () => void
  onRequestError?: () => void
}

export const fetchEproSurvey = (
  { token }: FetchEproSurveyOptions,
  responseHandlers?: FetchEproSurveyResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteEproSurvey>(`epro/fulfillment/${token}`, {}, { language: getLanguage() })

  req.then(({ error, body, status }) => {
    if (!error && responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        surveyName: body.survey_name,
        subjectId: body.subject_id,
        recordId: body.epro_record,
        ...parseSurveyBlocks(body.blocks)
      })
      return
    }

    if (status === 404 && responseHandlers?.onNotFound) {
      responseHandlers.onNotFound()
      return
    }

    if (
      [
        BackendError.EPRO_STATUS_ENDED,
        BackendError.STUDY_STATUS_ENDED,
        BackendError.STUDY_STATUS_ARCHIVED,
        BackendError.STUDY_STATUS_SUSPENDED,
        BackendError.EPRO_STATUS_ARCHIVED
      ].includes(error.code) &&
      responseHandlers?.onExpired
    ) {
      responseHandlers.onExpired()
      return
    }

    if (responseHandlers?.onRequestError) {
      responseHandlers.onRequestError()
    } else {
      throw error
    }
  })

  return cancel
}

interface EproAnswerFields {
  recordId: number
  token?: string
  studyId?: string
}

const eproAnswerMapping = {
  recordId: 'epro_record',
  token: 'token'
}

const getFilePath = () => 'uploaded_files/epro/answer'
const getAnswerPath = () => 'epro/answer'

export const saveEproSurveyAnswer = saveAnswer<EproAnswerFields, EproSurveyRemoteQuestionAnswer, SurveyAnswer>(
  getAnswerPath,
  eproAnswerMapping,
  parseRemoteSurveyAnswer
)

export const deleteEproSurveyAnswer = deleteAnswer<EproAnswerFields>(getAnswerPath, eproAnswerMapping)

export const saveEproFileAnswer = saveFileAnswer<EproAnswerFields, RemoteFileAnswer, FileAnswer>(
  getFilePath,
  eproAnswerMapping,
  parseRemoteFileAnswer
)

export const saveEproSurveyFileAnswer = saveFileAnswer<EproAnswerFields, EproSurveyRemoteQuestionAnswer, SurveyAnswer>(
  getFilePath,
  eproAnswerMapping,
  parseRemoteSurveyAnswer
)

export const deleteEproFileAnswer = deleteFileAnswer<EproAnswerFields>(getFilePath, eproAnswerMapping)

export const fetchEproFilesUrls = fetchOpenEndpointFilesUrls('uploaded_files/epro/fulfillment')

export const downloadEproFileAnswer = downloadFileAnswer(getFilePath())

export const fetchEproConditionalLogic = fetchConditionalLogic<FetchEproSurveyOptions>(
  ({ token }) => `epro/conditional_logic/${token}`
)
