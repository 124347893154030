/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'

export interface SaveReviewOptions {
  sectionId: string
  subsectionId: string
  wholeSection: boolean
}

export interface SaveEcrfReviewOptions {
  studyId: string
  inclusionId: string
  sectionId: string
  subsectionId: string
  wholeSection: boolean
}

export interface SaveReviewResponseHandlers {
  onSuccess?: () => void
  onAlreadyReviewed?: () => void
  onRequestError?: (code: number) => void
  onError?: () => void
}

export const saveEcrfReview = (
  { studyId, inclusionId, sectionId, subsectionId, wholeSection }: SaveEcrfReviewOptions,
  responseHandlers?: SaveReviewResponseHandlers
) => {
  const query = {
    inclusion: inclusionId,
    section: sectionId,
    subsection: subsectionId
  }
  const path = `review/${wholeSection ? 'section' : 'subsection'}`
  const { req, cancel } = fetchApi.post<SaveReviewOptions>(path, query, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<SaveReviewResponseHandlers>(
        {
          [BackendError.REVIEW_SUBSECTION_ALREADY_REVIEWED_BY_USER]: 'onAlreadyReviewed'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface DeleteReviewOptions {
  studyId: string
  reviewId: string
}

interface DeleteReviewResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const deleteReview = (
  { studyId, reviewId }: DeleteReviewOptions,
  responseHandlers?: DeleteReviewResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete(`review/subsection/${reviewId}`, {}, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<DeleteReviewResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
