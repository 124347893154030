import {
  fetchQuestionsDependencies,
  fetchSection,
  fetchStructure,
  fetchSubsection,
  searchQuestions,
  updateSection,
  updateStructure,
  updateSubsection
} from '../forms'

export const fetchSubjectRepositoryQuestionsDependencies = fetchQuestionsDependencies(
  () => `subject_repository/blocks_for_builder_logic`
)

export const fetchSubjectRepositoryStructure = fetchStructure(() => `subject_repository/structure`)

export const fetchSubjectRepositorySection = fetchSection<{ sectionId: string }>(
  ({ sectionId }) => `subject_repository/structure/${sectionId}`
)

export const fetchSubjectRepositorySubsection = fetchSubsection<{
  sectionId: string
  subsectionId: string
}>(({ sectionId, subsectionId }) => `subject_repository/${sectionId}/${subsectionId}`)

export const updateSubjectRepositoryStructure = updateStructure(() => 'subject_repository/structure', {})

export const searchSubjectRepositoryQuestions = searchQuestions(() => 'subject_repository/variables')

export const updateSubjectRepositorySection = updateSection<{ sectionId: string }>(
  ({ sectionId }) => `subject_repository/structure/${sectionId}`
)

export const updateSubjectRepositorySubsection = updateSubsection<{ sectionId: string; subsectionId: string }>(
  ({ sectionId, subsectionId }) => `subject_repository/${sectionId}/${subsectionId}`
)
