/* eslint-disable camelcase */
import 'moment-timezone'

import { createErrorsHandlers } from '../../../utils'
import { Feature } from '../../Feature'
import { BackendError } from '../../RequestError'
import { DataMapping, fetchApi, mapData } from '../../fetchApi'
import { FileState, RemoteFile, downloadFile } from '../../file'
import { QuestionType } from '../blocks'

export interface RemoteProgress {
  section_progress: number
  inclusion_progress: number
}

export interface FileAnswer {
  id: string
  name: string
  size: number
  state?: FileState
  uploadedSize?: number
  description?: string
}

export interface SaveFileResponseOptions {
  studyId?: string
  questionId: string
  productId?: string
  fileId: string
  reason?: string
  qrCode?: string
}

export interface RemoteFileAnswer {
  files_data: RemoteFile[]
}

export const parseRemoteFileAnswer = (answer: RemoteFileAnswer): FileAnswer => ({
  id: String(answer.files_data[0].uuid),
  name: answer.files_data[0].name,
  size: answer.files_data[0].size
})

export interface SaveFileAnswerResponseHandlers<T> {
  onSuccess?: (savedFile: T) => void
  onRequestError?: (code?: number) => void
  onFileSizeExceeded?: () => void
  onWrongFileFormat?: () => void
}

export const saveFileAnswer =
  <T, K, U>(getPath: (data: T) => string, dataMapping: DataMapping<T>, returnValueParser: (body: K) => U) =>
  (data: T & SaveFileResponseOptions, responseHandlers?: SaveFileAnswerResponseHandlers<U>) => {
    const query = {
      ...mapData(dataMapping, data),
      question: data.questionId,
      uuid: data.fileId,
      ...(data.reason && { reason: data.reason })
    }
    const { studyId, qrCode } = data
    const { req, cancel } = fetchApi.post<K & { progress: RemoteProgress }>(getPath(data), query, { studyId, qrCode })

    req.then(({ error, body, status }) => {
      if (error) {
        createErrorsHandlers<SaveFileAnswerResponseHandlers<U>>(
          {
            [BackendError.UPLOADED_FILE_SIZE_LIMIT_EXCEEDED]: 'onFileSizeExceeded',
            [BackendError.UPLOADED_FILE_INVALID_FORMAT]: 'onWrongFileFormat'
          },
          error,
          responseHandlers,
          status
        )
      } else if (responseHandlers?.onSuccess) {
        responseHandlers.onSuccess(returnValueParser({ ...body, type: QuestionType.File }))
      }
    })
    return cancel
  }

export interface DeleteFileResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code?: number) => void
}

export const deleteFileAnswer =
  <T>(getPath: (data: T) => string, additionalDataMapping?: DataMapping<T>) =>
  (
    { fileId }: { fileId: string },
    responseHandlers: DeleteFileResponseHandlers,
    additionalData?: T & { studyId?: string; qrCode?: string }
  ) => {
    const { req, cancel } = fetchApi.delete(
      `${getPath(additionalData)}/${fileId}`,
      additionalDataMapping ? mapData<T>(additionalDataMapping, additionalData) : null,
      { studyId: additionalData.studyId, qrCode: additionalData.qrCode }
    )

    req.then(({ error, status }) => {
      if (error) {
        createErrorsHandlers<DeleteFileResponseHandlers>({}, error, responseHandlers, status)
      } else if (responseHandlers?.onSuccess) {
        responseHandlers.onSuccess()
      }
    })

    return cancel
  }

export interface DownloadFileAnswerOptions {
  studyId?: string
  fileId: string
  filename: string
  productId?: string
  preview?: boolean
  feature?: Feature
}

interface RemoteDownloadFileAnswer {
  url: string
}

export interface DownloadFileResponseHandlers {
  onSuccess?: (file: string, filename: string) => void
  onRequestError?: (code?: number) => void
}

export const downloadFileAnswer =
  (path: string) =>
  (
    { studyId, fileId, filename, preview }: DownloadFileAnswerOptions,
    responseHandlers: DownloadFileResponseHandlers
  ) => {
    const { req, cancel } = fetchApi.get<RemoteDownloadFileAnswer>(`${path}/${fileId}`, {}, { studyId })

    req.then(({ error, body, status }) => {
      if (error) {
        createErrorsHandlers<DownloadFileResponseHandlers>({}, error, responseHandlers, status)
      } else {
        if (responseHandlers?.onSuccess) {
          responseHandlers.onSuccess(body.url, filename)
        }

        if (!preview) downloadFile(body.url, filename)
      }
    })

    return cancel
  }
