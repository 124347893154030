/* eslint-disable camelcase */
import { getLanguage } from '../../utils'
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'
import { fetchOpenEndpointFilesUrls } from '../file'
import {
  BaseRemoteQuestionAnswer,
  SurveyAnswer,
  SurveyData,
  SurveyRemoteBlocks,
  deleteAnswer,
  deleteFileAnswer,
  downloadFileAnswer,
  fetchConditionalLogic,
  parseRemoteSurveyAnswer,
  parseSurveyBlocks,
  saveAnswer,
  saveFileAnswer
} from '../forms'
import { EconsentStatus } from './dashboard'

export interface EconsentSurveyRemoteQuestionAnswer extends BaseRemoteQuestionAnswer {
  econsent_record: string
}

export interface RemoteEconsentSurvey {
  subject_id: string
  survey_name: string
  econsent_record: number
  blocks: SurveyRemoteBlocks[]
  status: EconsentStatus
}

interface CheckEconsentSurveyOptions {
  token: string
  qrCode?: string
}

interface CheckEconsentSurveyResponseHandlers {
  onSuccess?: () => void
  onNotFound?: () => void
  onExpired?: () => void
  onRequestError?: (code?: number) => void
}

export const checkEconsentSurvey = (
  { token, qrCode }: CheckEconsentSurveyOptions,
  responseHandlers?: CheckEconsentSurveyResponseHandlers
) => {
  const { req, cancel } = fetchApi.get(`subject_accounts/econsent/fulfillment/check/${token}`, {}, { qrCode })
  req.then(({ error, status }) => {
    if (!error && responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
      return
    }

    if (status === 404 && responseHandlers?.onNotFound) {
      responseHandlers.onNotFound()
      return
    }

    if (
      [
        BackendError.EPRO_STATUS_ENDED,
        BackendError.STUDY_STATUS_ENDED,
        BackendError.STUDY_STATUS_ARCHIVED,
        BackendError.STUDY_STATUS_SUSPENDED
      ].includes(error.code) &&
      responseHandlers?.onExpired
    ) {
      responseHandlers.onExpired()
      return
    }

    if (responseHandlers?.onRequestError) {
      responseHandlers.onRequestError()
    } else {
      throw error
    }
  })

  return cancel
}

interface FetchEconsentSurveyOptions {
  token: string
  qrCode: string
}

interface FetchEconsentSurveyResponseHandlers {
  onSuccess?: (survey: SurveyData) => void
  onWrongCode?: () => void
  onNotFound?: () => void
  onExpired?: () => void
  onRequestError?: () => void
}

export const fetchEconsentSurvey = (
  { token, qrCode }: FetchEconsentSurveyOptions,
  responseHandlers?: FetchEconsentSurveyResponseHandlers
) => {
  const path = `subject_accounts/econsent/fulfillment/${token}`
  const { req, cancel } = fetchApi.post<RemoteEconsentSurvey>(path, {}, { language: getLanguage(), qrCode })

  req.then(({ error, body, status }) => {
    if (!error && responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        surveyName: body.survey_name,
        subjectId: body.subject_id,
        recordId: body.econsent_record,
        status: body.status,
        ...parseSurveyBlocks(body.blocks)
      })
      return
    }

    if (status === 404 && responseHandlers?.onNotFound) {
      responseHandlers.onNotFound()
      return
    }

    if (status === 400 && responseHandlers?.onWrongCode) {
      responseHandlers.onWrongCode()
      return
    }

    if (
      [
        BackendError.EPRO_STATUS_ENDED,
        BackendError.STUDY_STATUS_ENDED,
        BackendError.STUDY_STATUS_ARCHIVED,
        BackendError.STUDY_STATUS_SUSPENDED
      ].includes(error.code) &&
      responseHandlers?.onExpired
    ) {
      responseHandlers.onExpired()
      return
    }

    if (responseHandlers?.onRequestError) {
      responseHandlers.onRequestError()
    } else {
      throw error
    }
  })

  return cancel
}

interface EconsentAnswerFields {
  recordId: number
  token: string
  qrCode: string
}

const econsentAnswerMapping = {
  recordId: 'econsent_record',
  token: 'token'
}

const getFilePath = () => 'subject_accounts/econsent/uploaded_files/answer'
const fulfillmentFilePath = 'uploaded_files/econsent/answer'
const getAnswerPath = () => 'subject_accounts/econsent/answer/fulfill'

// eslint-disable-next-line max-len
export const saveEconsentSurveyAnswer = saveAnswer<
  EconsentAnswerFields,
  EconsentSurveyRemoteQuestionAnswer,
  SurveyAnswer
>(getAnswerPath, econsentAnswerMapping, parseRemoteSurveyAnswer)

export const deleteEconsentSurveyAnswer = deleteAnswer<EconsentAnswerFields>(getAnswerPath, econsentAnswerMapping)

// eslint-disable-next-line max-len
export const saveEconsentFileAnswer = saveFileAnswer<
  EconsentAnswerFields,
  EconsentSurveyRemoteQuestionAnswer,
  SurveyAnswer
>(getFilePath, econsentAnswerMapping, parseRemoteSurveyAnswer)

export const deleteEconsentFileAnswer = deleteFileAnswer<EconsentAnswerFields>(getFilePath, econsentAnswerMapping)

export const fetchEconsentFilesUrls = fetchOpenEndpointFilesUrls('subject_accounts/econsent/fulfillment')

export const downloadEconsentFileAnswer = downloadFileAnswer(getFilePath())

export const downloadEconsentFulfillmentFileAnswer = downloadFileAnswer(fulfillmentFilePath)

export const fetchEconsentConditionalLogic = fetchConditionalLogic<FetchEconsentSurveyOptions>(
  ({ token }) => `econsent/conditional_logic/${token}`
)
