/* eslint-disable camelcase */
import { createErrorsHandlers } from '../utils'
import { BackendError } from './RequestError'
import { fetchApi } from './fetchApi'

export enum LsaStatus {
  Started = 'STARTED',
  Failure = 'FAILURE',
  Success = 'SUCCESS'
}

export enum LsaIcon {
  Progress = 'progress',
  Selfie = 'selfie',
  Datacapt = 'datacapt'
}

interface LsaStatusOptions {
  questionId: string
  token: string
  status?: LsaStatus
}

interface LsaStatusResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

const lsaStatusUrl = ({ questionId, token }: LsaStatusOptions) =>
  `subject_accounts/question/${questionId}/meta/${token}`

export const createLsaStatus = (options: LsaStatusOptions, responseHandlers?: LsaStatusResponseHandlers) => {
  const { req, cancel } = fetchApi.post(lsaStatusUrl(options), { lsa_status: LsaStatus.Started })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<LsaStatusResponseHandlers>(
        {
          [BackendError.RECORD_ALREADY_EXISTS]: 'onSuccess' // status already created, we can go further
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

export const updateLsaStatus = (options: LsaStatusOptions, responseHandlers?: LsaStatusResponseHandlers) => {
  const { req, cancel } = fetchApi.patch(lsaStatusUrl(options), { lsa_status: options.status })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<LsaStatusResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface FetchLsaStatusOptions {
  studyId: string
  eproId: string
  recordId: string
  questionId: string
}

interface FetchLsaStatusResponse {
  lsa_status: LsaStatus
}

export interface FetchLsaStatusResponseHandlers {
  onSuccess?: (status: LsaStatus) => void
  onNotFound?: () => void
  onRequestError?: (code: number) => void
}

export const fetchLsaStatus = (
  { studyId, eproId, recordId, questionId }: FetchLsaStatusOptions,
  responseHandlers?: FetchLsaStatusResponseHandlers
) => {
  const url = `epro/${eproId}/record/${recordId}/question/${questionId}`
  const { req, cancel } = fetchApi.get<FetchLsaStatusResponse>(url, {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchLsaStatusResponseHandlers>({ 404: 'onNotFound' }, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.lsa_status)
    }
  })

  return cancel
}
