/* eslint-disable camelcase */
import { createErrorsHandlers } from '../utils'
import { BackendError } from './RequestError'
import { fetchApi } from './fetchApi'

interface FetchExpandedUrlOptions {
  code: string
}

interface FetchExpandedUrlResponse {
  full_url: string
}

interface FetchExpandedUrlResponseHandlers {
  onSuccess?: (url: string) => void
  onRequestError?: (code: number) => void
  onExpiredError?: () => void
}

export const fetchExpandedUrl = (
  { code }: FetchExpandedUrlOptions,
  responseHandlers?: FetchExpandedUrlResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<FetchExpandedUrlResponse>(`invitations/token/${code}`)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchExpandedUrlResponseHandlers>(
        { [BackendError.INVITATION_EXPIRED]: 'onExpiredError' },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.full_url)
    }
  })

  return cancel
}
