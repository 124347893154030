/* eslint-disable camelcase */
import { TemplateType } from '../forms'
import { RoleName } from '../generalSettings'
import { AuditTrailType } from './AuditTrail'

export enum SystemAuditTrailsEventType {
  Users = 'USERS',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Study = 'STUDY',
  Authentication = 'AUTHENTICATION',
  Template = 'TEMPLATE',
  StudyCenter = 'STUDY_CENTER',
  UsersRole = 'USERS_ROLE',
  Password = 'PASSWORD',
  GlobalAuditTrail = 'GLOBAL_AUDIT_TRAIL'
}

export enum SystemAuditTrailsActionType {
  Invited = 'INVITED',
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
  Deleted = 'DELETED',
  Failed = 'FAILED',
  Created = 'CREATED',
  Blocked = 'BLOCKED',
  Unblocked = 'UNBLOCKED',
  AccessRevoked = 'REVOKED',
  Updated = 'UPDATED',
  Imported = 'IMPORTED',
  Exported = 'EXPORTED',
  Archived = 'ARCHIVED'
}

export interface SystemAuditTrail {
  trailType: AuditTrailType.System
  id: string
  eventType: SystemAuditTrailsEventType
  actionType: SystemAuditTrailsActionType
  timestamp: Date
  userName: string
  userEmail: string
  role: RoleName
  companyName: string
  inviteeEmail: string
  deletedUserEmail: string
  deletedUserFullName: string
  newValue: string
  oldValue: string
  email: string
  unblockedUsername: string
  unblockedUserEmail: string
  blockedUsername: string
  blockedUserEmail: string
  templateName: string
  templateType: TemplateType
  centerAbbreviation: string
  centerCountry: string
  centerName: string
  updatedUserEmail: string
  updatedUserFullName: string
  updatedUserNewRole: RoleName
  updatedUserOldRole: RoleName
}

export interface RemoteSystemAuditTrail {
  id: number
  user_name: string
  user_email: string
  event_type: SystemAuditTrailsEventType
  action_type: SystemAuditTrailsActionType
  timestamp: string
  additional_data: {
    role: RoleName
    company_name: string
    invitee_email: string
    deleted_user_full_name: string
    deleted_user_email: string
    user_email: string
    admin_email: string
    unblocked_full_name: string
    unblocked_email: string
    blocked_full_name: string
    blocked_email: string
    template_name: string
    template_type: TemplateType
    template: string
    new_value: string
    old_value: string
    abbreviation: string
    country: string
    name: string
    updated_user_old_role: RoleName
    updated_user_new_role: RoleName
    updated_user_email: string
    updated_user_full_name: string
  }
}

export const parseRemoteSystemTrail = (trail: RemoteSystemAuditTrail): SystemAuditTrail => ({
  trailType: AuditTrailType.System,
  id: trail.id && String(trail.id),
  timestamp: new Date(trail.timestamp),
  userName: trail.user_name,
  userEmail: trail.user_email,
  eventType: trail.event_type,
  actionType: trail.action_type,
  role: trail.additional_data?.role,
  companyName: trail.additional_data?.company_name,
  inviteeEmail: trail.additional_data?.invitee_email,
  deletedUserEmail: trail.additional_data?.deleted_user_email,
  deletedUserFullName: trail.additional_data?.deleted_user_full_name,
  oldValue: trail.additional_data?.old_value,
  newValue: trail.additional_data?.new_value,
  email: trail.additional_data?.user_email,
  unblockedUsername: trail.additional_data?.unblocked_full_name,
  unblockedUserEmail: trail.additional_data?.unblocked_email,
  blockedUsername: trail.additional_data?.blocked_full_name,
  blockedUserEmail: trail.additional_data?.blocked_email,
  templateName: trail.additional_data?.template_name,
  templateType: trail.additional_data?.template_type,
  centerAbbreviation: trail.additional_data?.abbreviation,
  centerCountry: trail.additional_data?.country,
  centerName: trail.additional_data?.name,
  updatedUserEmail: trail.additional_data?.updated_user_email,
  updatedUserFullName: trail.additional_data?.updated_user_full_name,
  updatedUserNewRole: trail.additional_data?.updated_user_new_role,
  updatedUserOldRole: trail.additional_data?.updated_user_old_role
})
