/* eslint-disable camelcase */
import { QueryStatus, SorterOrder, parseRemoteQueryStatus } from '../../requests'
import { createErrorsHandlers, prepareSorter } from '../../utils'
import { fetchApi } from '../fetchApi'

interface MonitoringQueriesResponse {
  queries_count: number
  open_queries_count: number
  closed_queries_count: number
  resolved_queries_count: number
  count: number
  results: {
    id: number
    inclusion: number
    section_id: number
    subsection_id: number
    subject_id_field: string
    center_abbreviation: string
    center_code: string
    center_name: string
    section_name: string
    message: string
    last_reply: string
    reply_last_updated: string
    status: string
  }[]
}

const parseMonitoringQueriesResponse = (response: MonitoringQueriesResponse) => {
  return {
    queriesCount: response.queries_count || 0,
    openQueriesCount: response.open_queries_count || 0,
    closedQueriesCount: response.closed_queries_count || 0,
    resolvedQueriesCount: response.resolved_queries_count || 0,
    count: response.count || 0,
    results: response.results?.map(result => ({
      id: String(result.id),
      inclusionId: String(result.inclusion),
      sectionId: String(result.section_id),
      subsectionId: String(result.subsection_id),
      subjectId: result.subject_id_field,
      centerAbbreviation: result.center_abbreviation,
      centerCode: result.center_code,
      centerName: result.center_name,
      sectionName: result.section_name,
      message: result.message,
      lastReply: result.last_reply,
      lastUpdatedDate: result.reply_last_updated ? new Date(result.reply_last_updated) : null,
      status: parseRemoteQueryStatus(result.status)
    }))
  }
}

export interface MonitoringQueriesSorter {
  field: keyof MonitoringQueriesResult
  order: SorterOrder
}

interface FetchMonitoringQueriesOptions {
  studyId: string
  options?: {
    sorter?: MonitoringQueriesSorter
    filters?: Record<string, string[]>
    search?: string
    limit?: number
    offset?: number
  }
}

export interface MonitoringQueriesResult {
  id: string
  inclusionId: string
  subjectId: string
  centerAbbreviation: string
  centerCode: string
  centerName: string
  sectionName: string
  message: string
  lastReply: string
  lastUpdatedDate: Date
  status: QueryStatus
}

interface MonitoringQueries {
  queriesCount: number
  openQueriesCount: number
  closedQueriesCount: number
  resolvedQueriesCount: number
  count: number
  results: MonitoringQueriesResult[]
}

interface FetchMonitoringQueriesResponseHandlers {
  onSuccess?: (monitoringQueries: MonitoringQueries) => void
  onRequestError?: (code: number) => void
}

const queriesSorterFields = {
  subjectId: ['subject_id_field'],
  centerAbbreviation: ['center_abbreviation'],
  sectionName: ['section_name'],
  lastUpdatedDate: ['reply_last_updated']
}

export const fetchMonitoringQueries = (
  { studyId, options }: FetchMonitoringQueriesOptions,
  responseHandlers?: FetchMonitoringQueriesResponseHandlers
) => {
  const sorter = prepareSorter<typeof queriesSorterFields, MonitoringQueriesSorter>(queriesSorterFields, options.sorter)
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    search: options.search,
    status: options.filters?.status,
    study_center_id: options.filters?.center
  }
  const { req, cancel } = fetchApi.get<MonitoringQueriesResponse>('monitoring/queries', query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchMonitoringQueriesResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseMonitoringQueriesResponse(body))
    }
  })

  return cancel
}

interface MonitoringReviewsResponse {
  reviewed_subsections: number
  all_subsections: number
}

interface MonitoringReviews {
  reviewedSubsections: number
  allSubsections: number
}

const parseMonitoringReviewsResponse = (response: MonitoringReviewsResponse) => {
  return {
    reviewedSubsections: response.reviewed_subsections || 0,
    allSubsections: response.all_subsections || 0
  }
}

interface FetchMonitoringReviewsResponseHandlers {
  onSuccess?: (monitoringReviews: MonitoringReviews) => void
  onRequestError?: (code: number) => void
}

export const fetchMonitoringReviews = (
  { studyId, centers }: { studyId: string; centers: string },
  responseHandlers?: FetchMonitoringReviewsResponseHandlers
) => {
  const path = 'monitoring/reviews'
  const { req, cancel } = fetchApi.get<MonitoringReviewsResponse>(path, { study_center_id: centers }, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchMonitoringReviewsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseMonitoringReviewsResponse(body))
    }
  })

  return cancel
}

export interface MonitoringSdvSorter {
  field: keyof MonitoringSdvRow
  order: SorterOrder
}

const sdvSorterFields = {
  subjectId: ['subject_id_field'],
  randomisationId: ['randomisation_id'],
  sectionName: ['section_name', 'subsection_name', 'title'],
  sdvStatus: ['sdv_status'],
  sdvDate: ['sdv_date'],
  centerName: ['center_abbreviation']
}

export enum SdvStatus {
  Verified = 'VERIFIED',
  Unverified = 'UNVERIFIED'
}
interface RemoteMonitoringSdvRow {
  center_abbreviation: string
  center_name: string
  center_code: string
  subject_id_field: string
  id: number
  question_id: number
  single_measure_id: number
  randomisation_id: string
  inclusion_id: string
  sdv_date: string
  sdv_status: SdvStatus
  sdv_user: number
  section_name: string
  section_id: number
  subsection_name: string
  subsection_id: number
  title: string
  variable: string
}

interface RemoteMonitoringSdv {
  count: number
  results: RemoteMonitoringSdvRow[]
  filtered_required_count: number
  filtered_verified_count: number
}

export interface MonitoringSdvRow {
  id: string
  subjectId: string
  randomisationId: string
  inclusionId: string
  sectionName: string
  sectionId: string
  subsectionName: string
  subsectionId: string
  questionId: string
  measureId: string
  variable: string
  title: string
  sdvStatus: SdvStatus
  sdvUser: string
  sdvDate: Date
  centerAbbreviation: string
  centerCode: string
  centerName: string
}

interface MonitoringSdv {
  count: number
  results: MonitoringSdvRow[]
  verifiedCount: number
  unverifiedCount: number
}

const parseRemoteMonitoringSdv = (response: RemoteMonitoringSdv): MonitoringSdv => {
  return {
    count: response.count,
    results: response.results.map(result => ({
      id: result.id?.toString(),
      subjectId: result.subject_id_field,
      randomisationId: result.randomisation_id,
      inclusionId: result.inclusion_id,
      sectionName: result.section_name,
      sectionId: result.section_id.toString(),
      subsectionName: result.subsection_name,
      subsectionId: result.subsection_id.toString(),
      questionId: result.question_id?.toString(),
      measureId: result.single_measure_id?.toString(),
      variable: result.variable,
      title: result.title,
      sdvStatus: result.sdv_status,
      sdvUser: result.sdv_user?.toString(),
      sdvDate: result.sdv_date && new Date(result.sdv_date),
      centerAbbreviation: result.center_abbreviation,
      centerName: result.center_name,
      centerCode: result.center_code
    })),
    verifiedCount: response.filtered_verified_count,
    unverifiedCount: response.filtered_required_count - response.filtered_verified_count
  }
}

interface FetchMonitoringSdvResponseHandlers {
  onSuccess?: (monitoringSdv: MonitoringSdv) => void
  onRequestError?: (code: number) => void
}

interface FetchMonitoringSdvOptions {
  studyId: string
  options?: {
    sorter?: MonitoringSdvSorter
    filters?: Record<string, string[]>
    search?: string
    limit?: number
    offset?: number
  }
}

export const fetchMonitoringSdv = (
  { studyId, options }: FetchMonitoringSdvOptions,
  responseHandlers?: FetchMonitoringSdvResponseHandlers
) => {
  const path = 'monitoring/sdv'
  const sorter = prepareSorter<typeof sdvSorterFields, MonitoringSdvSorter>(sdvSorterFields, options.sorter)
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    search: options.search,
    sdv_status: options.filters?.status,
    study_center_id: options.filters?.center,
    subject_id_field: options.filters?.subjectId,
    variable: options.filters?.variables
  }
  const { req, cancel } = fetchApi.get<RemoteMonitoringSdv>(path, query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchMonitoringSdvResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteMonitoringSdv(body))
    }
  })

  return cancel
}

export interface MonitoringMissingSorter {
  field: keyof MonitoringMissingRow
  order: SorterOrder
}

const missingSorterFields = {
  subjectId: ['subject_id_field'],
  randomisationId: ['randomisation_id'],
  sectionName: ['section_name', 'subsection_name', 'title'],
  centerAbbreviation: ['center_abbreviation']
}

interface RemoteMonitoringMissingRow {
  center_abbreviation: string
  center_name: string
  center_code: string
  subject_id_field: string
  id: number
  single_measure_id: number
  randomisation_id: string
  inclusion_id: string
  section_name: string
  section_id: number
  subsection_name: string
  subsection_id: number
  title: string
  variable: string
}

interface RemoteMonitoringMissing {
  count: number
  results: RemoteMonitoringMissingRow[]
  missing_count: number
  missing_percentage: number
  missing_subjects_count: number
}

export interface MonitoringMissingRow {
  id: string
  subjectId: string
  randomisationId: string
  inclusionId: string
  sectionName: string
  sectionId: string
  subsectionName: string
  subsectionId: string
  questionId: string
  measureId: string
  variable: string
  title: string
  centerAbbreviation: string
  centerCode: string
  centerName: string
}

interface MonitoringMissing {
  count: number
  results: MonitoringMissingRow[]
  missingCount: number
  missingPercentage: number
  missingSubjectsCount: number
}

const parseRemoteMonitoringMissing = (response: RemoteMonitoringMissing): MonitoringMissing => {
  // id from BE response here is question id, so it's not unique
  return {
    count: response.count,
    missingCount: response.missing_count,
    missingPercentage: response.missing_percentage && Math.round(response.missing_percentage * 10000) / 100,
    missingSubjectsCount: response.missing_subjects_count,
    results: response.results.map(result => ({
      id: `${result.id}-${result.subject_id_field}-${result.single_measure_id}`,
      subjectId: result.subject_id_field,
      randomisationId: result.randomisation_id,
      inclusionId: result.inclusion_id,
      sectionName: result.section_name,
      sectionId: result.section_id.toString(),
      subsectionName: result.subsection_name,
      subsectionId: result.subsection_id.toString(),
      questionId: result.id?.toString(),
      measureId: result.single_measure_id?.toString(),
      variable: result.variable,
      title: result.title,
      centerAbbreviation: result.center_abbreviation,
      centerName: result.center_name,
      centerCode: result.center_code
    }))
  }
}

interface FetchMonitoringMissingResponseHandlers {
  onSuccess?: (monitoringMissing: MonitoringMissing) => void
  onRequestError?: (code: number) => void
}

interface FetchMonitoringMissingOptions {
  studyId: string
  options?: {
    sorter?: MonitoringMissingSorter
    filters?: Record<string, string[]>
    search?: string
    limit?: number
    offset?: number
  }
}

export const fetchMonitoringMissing = (
  { studyId, options }: FetchMonitoringMissingOptions,
  responseHandlers?: FetchMonitoringMissingResponseHandlers
) => {
  const path = 'monitoring/missing'
  const sorter = prepareSorter<typeof missingSorterFields, MonitoringMissingSorter>(missingSorterFields, options.sorter)
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    search: options.search,
    sdv_status: options.filters?.status,
    study_center_id: options.filters?.center,
    subject_id_field: options.filters?.subjectId,
    variable: options.filters?.variables
  }
  const { req, cancel } = fetchApi.get<RemoteMonitoringMissing>(path, query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchMonitoringMissingResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteMonitoringMissing(body))
    }
  })

  return cancel
}
