/* eslint-disable camelcase */
import dayjs, { Dayjs } from 'dayjs'

import { createErrorsHandlers, localeFromPath, prepareSorter } from '../../utils'
import { BackendError } from '../RequestError'
import { SorterOrder } from '../SorterOrder'
import { SelectionKeys } from '../exports'
import { fetchApi } from '../fetchApi'

export enum PaymentOrderStatus {
  Processing = 'PROCESSING',
  Error = 'ERROR',
  Paid = 'PAID'
}

export interface PaymentOrder {
  id: string
  paymentId: string
  datacaptId: string
  fullName: string
  recruitmentReference: string
  creationDate: Dayjs
  paymentDate: Dayjs
  status: PaymentOrderStatus
  statusDetails: string
  accountBeneficiary: string
  accountNumber: string
  swift: string
  transactionId: string
  center: string
  visitName: string
  scheduleName: string
  amount: number
  currency: string
  countryCode: string
  centerNeedsBankDetails: boolean
}

export interface PaymentOrderSorter {
  field: keyof PaymentOrder
  order: SorterOrder
}

const sorterFields = {
  id: ['datacapt_id'],
  fullName: ['subject_full_name'],
  recruitmentReference: ['recruitment_reference'],
  creationDate: ['created_at'],
  paymentDate: ['payment_date'],
  status: ['status'],
  amount: ['amount'],
  transactionId: ['transaction_id'],
  center: ['center_abbreviation']
}

export interface RemotePaymentOrder {
  id: number
  payment_id: number
  subject_datacapt_id: string
  subject_full_name: string
  recruitment_reference: string
  created_at: string
  payment_date: string
  status: PaymentOrderStatus
  status_details: string
  account_beneficiary: string
  account_number: string
  swift: string
  transaction_id: string
  center_abbreviation: string
  visit_name: string
  schedule_name: string
  amount: string
  currency: string
  country_code: string
  center_needs_bank_details: boolean
}

interface FetchPaymentOrdersResponse {
  count: number
  next: number
  results: RemotePaymentOrder[]
}

const parseRemotePaymentOrder: (paymentOrder: RemotePaymentOrder) => PaymentOrder = (
  paymentOrder: RemotePaymentOrder
) => ({
  id: String(paymentOrder.id),
  paymentId: String(paymentOrder.payment_id),
  datacaptId: paymentOrder.subject_datacapt_id,
  fullName: paymentOrder.subject_full_name,
  recruitmentReference: paymentOrder.recruitment_reference,
  creationDate: paymentOrder.created_at && dayjs(paymentOrder.created_at, 'YYYY-MM-DD').locale(localeFromPath()),
  paymentDate: paymentOrder.payment_date && dayjs(paymentOrder.payment_date, 'YYYY-MM-DD').locale(localeFromPath()),
  status: paymentOrder.status,
  statusDetails: paymentOrder.status_details,
  accountBeneficiary: paymentOrder.account_beneficiary,
  accountNumber: paymentOrder.account_number,
  swift: paymentOrder.swift,
  transactionId: paymentOrder.transaction_id,
  center: paymentOrder.center_abbreviation,
  visitName: paymentOrder.visit_name,
  scheduleName: paymentOrder.schedule_name,
  amount: parseFloat(paymentOrder.amount),
  currency: paymentOrder.currency,
  countryCode: paymentOrder.country_code,
  centerNeedsBankDetails: paymentOrder.center_needs_bank_details
})

interface FetchPaymentOrdersOptions {
  options?: {
    limit?: number
    offset?: number
    sorter?: PaymentOrderSorter
    search?: string
    filters?: {
      status?: string[]
      center?: string[]
      visit?: string[]
      recruitment_reference?: string[]
      creation_date_after?: string
      creation_date_before?: string
      payment_date_after?: string
      payment_date_before?: string
    }
  }
}

interface FetchPaymentOrdersResponseHandlers {
  onSuccess?: ({
    paymentOrders,
    allPaymentOrdersCount
  }: {
    paymentOrders: PaymentOrder[]
    allPaymentOrdersCount: number
  }) => void
  onRequestError?: (code: number) => void
}

export const fetchPaymentOrders = (
  { options }: FetchPaymentOrdersOptions,
  responseHandlers?: FetchPaymentOrdersResponseHandlers
) => {
  const sorter = prepareSorter<typeof sorterFields, PaymentOrderSorter>(sorterFields, options.sorter)
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    search: options.search,
    status: options.filters?.status,
    center: options.filters?.center,
    recruitment_reference: options.filters?.recruitment_reference,
    visit: options.filters?.visit,
    creation_date_after: options.filters?.creation_date_after,
    creation_date_before: options.filters?.creation_date_before,
    payment_date_after: options.filters?.payment_date_after,
    payment_date_before: options.filters?.payment_date_before
  }

  const { req, cancel } = fetchApi.get<FetchPaymentOrdersResponse>('payments/orders', query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchPaymentOrdersResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        paymentOrders: body.results.map(parseRemotePaymentOrder),
        allPaymentOrdersCount: body.count
      })
    }
  })

  return cancel
}

type Sepa = {
  data: string
  filename: string
}

export type MakePaymentResult = Partial<Sepa> & { payment_orders?: string[] }

interface BulkMakePaymentsResponseHandlers {
  onSuccess?: (sepaFile?: Sepa, records?: SelectionKeys) => void
  onCenterDataMissing?: () => void
  onCurrencyMismatch?: () => void
  onRequestError?: (code: number) => void
}

interface BulkMakePaymentsOptions {
  records: SelectionKeys
}

export const bulkMakePayments = (
  { records }: BulkMakePaymentsOptions,
  responseHandlers?: BulkMakePaymentsResponseHandlers
) => {
  const path = `payments/transactions`
  const query = {
    payment_orders: records
  }
  const { req, cancel } = fetchApi.post<MakePaymentResult>(path, query, {})

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<BulkMakePaymentsResponseHandlers>(
        {
          [BackendError.PAYMENT_CENTER_DATA_MISSING]: 'onCenterDataMissing',
          [BackendError.PAYMENT_CURRENCY_MISMATCH]: 'onCurrencyMismatch'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      const isFileReturned = !!body.data
      responseHandlers.onSuccess(
        isFileReturned ? { data: body.data, filename: body.filename } : undefined,
        isFileReturned ? undefined : body.payment_orders
      )
    }
  })

  return cancel
}

interface BulkInvalidatePaymentOrdersResponseHandlers {
  onSuccess?: () => void
  onWrongStatus?: () => void
  onRequestError?: (code: number) => void
}

interface BulkInvalidatePaymentOrdersOptions {
  records: SelectionKeys
  details: string
}

export const bulkInvalidatePaymentOrders = (
  { records, details }: BulkInvalidatePaymentOrdersOptions,
  responseHandlers?: BulkInvalidatePaymentOrdersResponseHandlers
) => {
  const path = `payments/orders/invalidate`
  const query = {
    payment_orders: records,
    status_details: details
  }
  const { req, cancel } = fetchApi.patch(path, query, {})

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<BulkInvalidatePaymentOrdersResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface ExportSepaResponseHandlers {
  onSuccess?: (file: Sepa) => void
  onWrongStatus?: () => void
  onRequestError?: (code: number) => void
}

interface ExportSepaOptions {
  transactionId: string
}

export const exportSepa = ({ transactionId }: ExportSepaOptions, responseHandlers?: ExportSepaResponseHandlers) => {
  const path = `payments/transactions/${transactionId}`
  const { req, cancel } = fetchApi.get<Sepa>(path, {})

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<ExportSepaResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}
