/* eslint-disable camelcase */
import dayjs, { Dayjs } from 'dayjs'

import { createErrorsHandlers } from '../../utils'
import { fetchApi } from '../fetchApi'

export enum AppointmentColor {
  Pink1 = 'pink-1',
  Pink2 = 'pink-2',
  Purple1 = 'purple-1',
  Purple2 = 'purple-2',
  Blue2 = 'blue-2',
  Blue12 = 'blue-12',
  Green4 = 'green-4',
  Red5 = 'red-5',
  Red3 = 'red-3',
  Orange1 = 'orange-1',
  Yellow3 = 'yellow-3',
  Yellow1 = 'yellow-1',
  Green7 = 'green-7',
  Green2 = 'green-2'
}

interface RemoteAppointment {
  id: number
  title: string
  public_title: string
  start_datetime: string
  end_datetime: string
  timezone: string
  capacity: number
  subjects: {
    datacapt_id: string
    full_name?: string
    email?: string
  }[]
  study: {
    uuid: string
    name?: string
    reference_number?: string
  }
  center: {
    id: number
    name?: string
    abbreviation?: string
  }
  color: AppointmentColor
}

export interface AppointmentSubject {
  id: string
  fullName?: string
  email?: string
}

export interface Appointment {
  id?: number
  title: string
  publicTitle: string
  startDate: Dayjs
  endDate: Dayjs
  timezone: string
  startTime: string
  endTime: string
  capacity: number
  subjects: AppointmentSubject[]
  study: {
    uuid: string
    name?: string
    reference?: string
  }
  center: {
    id: string
    name?: string
    abbreviation?: string
  }
  color: AppointmentColor
}

const getTimeAndDateInTimezone = (dateTime: string, timezone: string) => {
  const date = dayjs(dateTime)
  const timezoneDate = dayjs.tz(date, timezone)
  return timezoneDate
}

const parseRemoteAppointment = (remoteAppointment: RemoteAppointment): Appointment => {
  const startDate = getTimeAndDateInTimezone(remoteAppointment.start_datetime, remoteAppointment.timezone)
  const endDate = getTimeAndDateInTimezone(remoteAppointment.end_datetime, remoteAppointment.timezone)

  return {
    id: remoteAppointment.id,
    title: remoteAppointment.title,
    publicTitle: remoteAppointment.public_title,
    startDate,
    endDate,
    timezone: remoteAppointment.timezone,
    startTime: startDate.format('HH:mm'),
    endTime: endDate.format('HH:mm'),
    capacity: remoteAppointment.capacity,
    subjects: remoteAppointment.subjects
      ? remoteAppointment.subjects.map(subject => ({
          id: subject.datacapt_id,
          fullName: subject.full_name,
          email: subject.email
        }))
      : [],
    study: {
      uuid: remoteAppointment.study?.uuid,
      name: remoteAppointment.study?.name,
      reference: remoteAppointment.study?.reference_number
    },
    center: {
      id: String(remoteAppointment.center?.id),
      name: remoteAppointment.center?.name,
      abbreviation: remoteAppointment.center?.abbreviation
    },
    color: remoteAppointment.color
  }
}

export const getUtcString = (date: Dayjs) => (date ? date.clone().utc().format('YYYY-MM-DD HH:mm') : undefined)

const getTimeAndDateInUTC = (date: Dayjs, time: string, timezone: string) => {
  const timezoneDate = dayjs.tz(`${date.format('YYYY-MM-DD')} ${time}`, timezone)
  return getUtcString(timezoneDate)
}

const parseAppointmentForSave = (appointment: Appointment) => ({
  id: appointment.id,
  title: appointment.title,
  public_title: appointment.publicTitle,
  timezone: appointment.timezone,
  start_datetime: getTimeAndDateInUTC(appointment.startDate, appointment.startTime, appointment.timezone),
  end_datetime: getTimeAndDateInUTC(appointment.startDate, appointment.endTime, appointment.timezone),
  capacity: appointment.capacity || undefined,
  subjects: appointment.subjects.map(subject => subject.id),
  study: appointment.study.uuid || undefined,
  center: Number(appointment.center.id) || undefined,
  color: appointment.color
})

interface CreateAppointmentResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const createAppointment = (appointment: Appointment, responseHandlers?: CreateAppointmentResponseHandlers) => {
  const { req, cancel } = fetchApi.post('calendar/appointments', parseAppointmentForSave(appointment))

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<CreateAppointmentResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

export const updateAppointment = (appointment: Appointment, responseHandlers?: CreateAppointmentResponseHandlers) => {
  const { req, cancel } = fetchApi.put(`calendar/appointments/${appointment.id}`, parseAppointmentForSave(appointment))

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<CreateAppointmentResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface FetchAppointmentsOptions {
  startDate?: Dayjs
  endDate?: Dayjs
  search?: string
  centerIds?: string[]
}

interface FetchAppointmentsResponse {
  count: number
  next: number
  previous: number
  results: RemoteAppointment[]
}

interface FetchAppointmentsResponseHandlers {
  onSuccess?: ({
    appointments,
    allAppointmentsCount
  }: {
    appointments: Appointment[]
    allAppointmentsCount: number
  }) => void
  onRequestError?: (code: number) => void
}

export const fetchAppointments = (
  { startDate, endDate, search, centerIds }: FetchAppointmentsOptions,
  responseHandlers?: FetchAppointmentsResponseHandlers
) => {
  const query = {
    ordering: ['start_datetime', 'end_datetime'],
    search,
    end_datetime__gte: getUtcString(startDate),
    start_datetime__lte: getUtcString(endDate),
    center_ids: centerIds?.length ? centerIds : undefined
  }

  const { req, cancel } = fetchApi.get<FetchAppointmentsResponse>('calendar/events', query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchAppointmentsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        appointments: body.results.map(parseRemoteAppointment),
        allAppointmentsCount: body.count
      })
    }
  })

  return cancel
}

interface DeleteAppointmentResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const deleteAppointment = ({ id }: { id: number }, responseHandlers?: DeleteAppointmentResponseHandlers) => {
  const { req, cancel } = fetchApi.delete(`calendar/appointments/${id}`)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<DeleteAppointmentResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
