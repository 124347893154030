/* eslint-disable camelcase */
import { createErrorsHandlers, prepareSorter } from '../../utils'
import { SorterOrder } from '../SorterOrder'
import { fetchApi } from '../fetchApi'
import { CenterData, RemoteCenterData, parseRemoteCenterData } from '../generalSettings'
import { Product } from './products'

export enum ProjectStatus {
  Draft = 'DRAFT',
  Live = 'LIVE',
  Ended = 'ENDED'
}

export enum ProjectCategory {
  Haircare = 'HAIRCARE',
  HairColor = 'HAIRCOLOR',
  Styling = 'STYLING',
  Texture = 'TEXTURE'
}

const projectsApiUrl = 'side_by_side/projects'
const brandsApiUrl = 'side_by_side/brands'

interface ProjectCategoryProductKeyMap {
  [ProjectCategory.Haircare]: (keyof Product)[]
  [ProjectCategory.HairColor]: (keyof Product)[]
  [ProjectCategory.Styling]: (keyof Product)[]
  [ProjectCategory.Texture]: (keyof Product)[]
}
export const projectCategoryProductKeyMap: ProjectCategoryProductKeyMap = {
  [ProjectCategory.Haircare]: [
    'formula',
    'type',
    'brand',
    'category',
    'productName',
    'technology',
    'status',
    'batch',
    'quantity',
    'processingTime',
    'specialInstructions',
    'specialApplication'
  ],
  [ProjectCategory.HairColor]: [
    'formula',
    'type',
    'brand',
    'category',
    'productName',
    'technology',
    'status',
    'shadeName',
    'batch',
    'devVol',
    'mixRatio',
    'processingTime',
    'specialInstructions',
    'specialApplication'
  ],
  [ProjectCategory.Styling]: [
    'formula',
    'type',
    'brand',
    'category',
    'productName',
    'technology',
    'status',
    'batch',
    'quantity',
    'specialInstructions',
    'specialApplication'
  ],
  [ProjectCategory.Texture]: [
    'formula',
    'type',
    'brand',
    'category',
    'productName',
    'technology',
    'status',
    'batch',
    'devVol',
    'mixRatio',
    'quantity',
    'processingTime',
    'specialInstructions',
    'specialApplication'
  ]
}

interface RemoteProject {
  centers: RemoteCenterData[]
  name: string
  uuid: string
  status: ProjectStatus
  brand_name: string
  category: ProjectCategory
  panelist_variables: string[]
}

export interface Project {
  id: string
  name: string
  centers: CenterData[]
  status: ProjectStatus
  brand: string
  category: ProjectCategory
  panelistVariables: string[]
}

export interface ProjectSorter {
  field: keyof Project
  order: SorterOrder
}
const sorterFields = {
  brand: ['brand_name']
}

const parseRemoteProject = (project: RemoteProject): Project => {
  return {
    id: project.uuid,
    name: project.name,
    centers: project.centers?.map(parseRemoteCenterData),
    status: project.status,
    brand: project.brand_name,
    category: project.category,
    panelistVariables: project.panelist_variables
  }
}

interface FetchProjectsOptions {
  options?: {
    limit?: number
    offset?: number
    search?: string
    status?: ProjectStatus[]
    category?: ProjectCategory[]
    brand?: string[]
    subjectId?: string
    sorter?: ProjectSorter
  }
}

interface FetchProjectsResponse {
  results: RemoteProject[]
  all_count: number
  count: number
}

interface FetchProjectsResponseHandlers {
  onSuccess?: (projects: Project[], meta: { allProjectsCount: number; projectsCount: number }) => void
  onRequestError?: (code: number) => void
}

export const fetchProjects = ({ options }: FetchProjectsOptions, responseHandlers?: FetchProjectsResponseHandlers) => {
  const ordering = options.sorter
    ? prepareSorter<typeof sorterFields, ProjectSorter>(sorterFields, options.sorter)
    : undefined

  const query = {
    limit: options?.limit,
    offset: options?.offset,
    search: options?.search,
    status: options?.status,
    category: options?.category,
    brand_name: options?.brand,
    datacapt_id: options?.subjectId,
    ordering
  }
  const { req, cancel } = fetchApi.get<FetchProjectsResponse>(projectsApiUrl, query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchProjectsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(
        body.results.map(study => parseRemoteProject(study)),
        {
          allProjectsCount: body.all_count || 0,
          projectsCount: body.count || 0
        }
      )
    }
  })

  return cancel
}

interface CreateProjectResponseHandlers {
  onSuccess?: ({ uuid }: CreateProjectResponse) => void
  onRequestError?: (code: number) => void
}

export interface CreateProjectOptions {
  name: string
  centers: string[]
  category: string
  brand: string
}

interface CreateProjectResponse {
  uuid: string
}

export const createProject = (
  { name, centers, category, brand }: CreateProjectOptions,
  responseHandlers?: CreateProjectResponseHandlers
) => {
  const query = { name, center_ids: centers, category, brand_name: brand }

  const { req, cancel } = fetchApi.post<CreateProjectResponse>(projectsApiUrl, query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<CreateProjectResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}

interface UpdateProjectResponseHandlers {
  onSuccess?: (project: Partial<Project> & { centerIds: string[] }) => void
  onRequestError?: (code: number) => void
}

export interface UpdateProjectOptions {
  name: string
  centerIds: string[]
  category: string
  brand: string
  status: ProjectStatus
  projectId: string
  panelistVariables: string[]
}
type UpdateProjectResponse = Partial<RemoteProject> & { center_ids: number[] }

export const updateProject = (
  { name, centerIds, category, brand, status, projectId, panelistVariables }: Partial<UpdateProjectOptions>,
  responseHandlers?: UpdateProjectResponseHandlers
) => {
  const query = {
    name,
    center_ids: centerIds,
    category,
    brand_name: brand,
    status,
    panelist_variables: panelistVariables
  }

  const { req, cancel } = fetchApi.patch<UpdateProjectResponse>(`${projectsApiUrl}/${projectId}`, query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<UpdateProjectResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        id: body.uuid,
        name: body.name,
        centerIds: body.center_ids.map(String),
        status: body.status,
        brand: body.brand_name,
        category: body.category,
        panelistVariables: body.panelist_variables
      })
    }
  })

  return cancel
}

interface FetchProjectResponseHandlers {
  onSuccess?: (project: Project) => void
  onRequestError?: (code: number) => void
}

interface FetchProjectOptions {
  projectId: string
}

export const fetchProject = ({ projectId }: FetchProjectOptions, responseHandlers?: FetchProjectResponseHandlers) => {
  const { req, cancel } = fetchApi.get<RemoteProject>(`${projectsApiUrl}/${projectId}`)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchProjectResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteProject(body))
    }
  })

  return cancel
}
interface FetchBrandsResponseHandlers {
  onSuccess?: (domains: string[]) => void
  onRequestError?: (code: number) => void
}

export const fetchBrands = (responseHandlers?: FetchBrandsResponseHandlers) => {
  const { req, cancel } = fetchApi.get<string[]>(brandsApiUrl)

  req.then(({ error, body }) => {
    if (error) {
      createErrorsHandlers<FetchBrandsResponseHandlers>({}, error, responseHandlers)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}
