import {
  createFromStructure,
  fetchQuestionsDependencies,
  fetchSection,
  fetchStructure,
  searchQuestions,
  updateSection,
  updateStructure,
  updateSubsection
} from '../forms'

export const fetchEconsentQuestionsDependencies = fetchQuestionsDependencies(() => 'econsent/blocks_for_builder_logic')

export const fetchEconsentStructure = fetchStructure(() => 'econsent')

export const fetchEconsentSection = fetchSection<{ sectionId: string }>(({ sectionId }) => `econsent/${sectionId}`)

export const createEconsentFromStructure = createFromStructure<{ studyId: string }>('econsent', { studyId: 'study' })

export const updateEconsentStructure = updateStructure<{ studyId: string }>(() => 'econsent', { studyId: 'study' })

export const searchEconsentQuestions = searchQuestions(() => 'econsent/variables')

export const updateEconsentSection = updateSection<{ sectionId: string }>(({ sectionId }) => `econsent/${sectionId}`)

export const updateEconsentSubsection = updateSubsection<{ sectionId: string; subsectionId: string }>(
  ({ sectionId, subsectionId }) => `econsent/${sectionId}/${subsectionId}`
)
