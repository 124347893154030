import {
  fetchSection,
  fetchStructure,
  fetchSubsection,
  searchQuestions,
  updateSection,
  updateStructure,
  updateSubsection
} from '../forms'

const apiUrl = 'side_by_side'

export const fetchProjectStructure = fetchStructure<{ projectId: string }>(
  ({ projectId }) => `${apiUrl}/structure/${projectId}`
)

export const fetchProjectSection = fetchSection<{ projectId: string; sectionId: string }>(
  ({ projectId, sectionId }) => `${apiUrl}/structure/${projectId}/${sectionId}`
)

export const fetchProjectSubsection = fetchSubsection<{
  projectId: string
  sectionId: string
  subsectionId: string
}>(({ projectId, sectionId, subsectionId }) => `${apiUrl}/${projectId}/${sectionId}/${subsectionId}`)

export const updateProjectStructure = updateStructure<{ projectId: string }>(
  ({ projectId }) => `${apiUrl}/structure/${projectId}`,
  {}
)

export const searchProjectQuestions = searchQuestions<{ projectId: string }>(
  ({ projectId }) => `${apiUrl}/projects/${projectId}/variables`
)

export const updateProjectSection = updateSection<{ sectionId: string; projectId: string }>(
  ({ sectionId, projectId }) => `${apiUrl}/structure/${projectId}/${sectionId}`
)

export const updateProjectSubsection = updateSubsection<{
  sectionId: string
  subsectionId: string
  projectId: string
}>(({ sectionId, subsectionId, projectId }) => `${apiUrl}/${projectId}/${sectionId}/${subsectionId}`)
