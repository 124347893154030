import { questionIdWithMeasureId } from '.'
import {
  Block,
  BlockType,
  CalculValue,
  ConditionalLogicAction,
  ConditionalLogicJoinOperator,
  ConditionalLogicOperator,
  ConditionalRule,
  Question,
  QuestionBlock,
  ReferenceAnswer,
  Structure
} from '../requests'

export const getConditionalQuestionById = (structure: Structure, questionId: string) => {
  if (!structure || !questionId) return null

  const questions: Question[] = []

  structure.sections.forEach(section => {
    section.subsections.forEach(subsection => {
      subsection.blocks.forEach(block => {
        if (block.blockType === BlockType.Question) questions.push(block)
        if (block.blockType === BlockType.RepeatedMeasures) {
          questions.push(...(block.blocks as QuestionBlock[]).filter(block => block.blockType === BlockType.Question))
        }
      })
    })
  })

  return questions.find(question => question.id === questionId) as Question
}

const getFinalCondition = (conditionalRule: ConditionalRule) =>
  conditionalRule?.conditions?.[conditionalRule.conditions.length - 1]

const getOperationResult = (
  referenceAnswer: CalculValue | number | string[],
  conditionAnswer: number | string[],
  operator: ConditionalLogicOperator
) => {
  const calculAnswer = (referenceAnswer as CalculValue)?.result
  const answer = calculAnswer != null ? calculAnswer : referenceAnswer

  if (typeof answer === 'number' && typeof conditionAnswer === 'number') {
    return (
      (operator === ConditionalLogicOperator.Equal && answer === conditionAnswer) ||
      (operator === ConditionalLogicOperator.NotEqual && answer !== conditionAnswer) ||
      (operator === ConditionalLogicOperator.Greater && answer > conditionAnswer) ||
      (operator === ConditionalLogicOperator.Lower && answer < conditionAnswer)
    )
  }
  if (Array.isArray(answer) && Array.isArray(conditionAnswer)) {
    return (
      (operator === ConditionalLogicOperator.Equal && answer?.some(a => conditionAnswer.includes(a))) ||
      (operator === ConditionalLogicOperator.NotEqual && !answer?.some(a => conditionAnswer.includes(a)))
    )
  }

  return false
}

const getConditionalLogicForBlock = (
  referenceAnswers: ReferenceAnswer,
  conditionalRule: ConditionalRule,
  measureId?: string
) => {
  if (!conditionalRule?.conditions?.length) return null

  const finalCondition = getFinalCondition(conditionalRule)

  const operationResults = conditionalRule.conditions.map(condition => {
    const referenceAnswer = referenceAnswers[questionIdWithMeasureId(condition.referenceQuestionId, measureId)]
    return getOperationResult(referenceAnswer, condition.answerValue, condition.operator)
  })

  return (finalCondition.joinOperator === ConditionalLogicJoinOperator.And && operationResults.every(Boolean)) ||
    (finalCondition.joinOperator === ConditionalLogicJoinOperator.Or && operationResults.includes(true))
    ? conditionalRule
    : null
}

export const shouldDisplayBlock = (block: Block, referenceAnswers: ReferenceAnswer, measureId?: string) => {
  if (block.blockType !== BlockType.Question && block.blockType !== BlockType.StaticContent) return true

  if (!block.conditionalLogic?.length) return true

  return block.conditionalLogic.reduce((acc, conditionalRule) => {
    if (acc === false) return false

    const finalCondition = getFinalCondition(conditionalRule)
    const finalParsedCondition = getFinalCondition(
      getConditionalLogicForBlock(referenceAnswers, conditionalRule, measureId)
    )

    return (
      finalCondition?.action === ConditionalLogicAction.Prefill ||
      finalParsedCondition?.action === ConditionalLogicAction.Show
    )
  }, true)
}
