import {
  fetchQuestionsDependencies,
  fetchSection,
  fetchStructure,
  fetchSubsection,
  searchQuestions,
  updateSection,
  updateStructure,
  updateSubsection
} from '../forms'

export const fetchRecruitmentQuestionsDependencies = fetchQuestionsDependencies<{ recruitmentId: string }>(
  ({ recruitmentId }) => `recruitment/${recruitmentId}/blocks_for_builder_logic`
)

export const fetchRecruitmentStructure = fetchStructure<{ recruitmentId: string }>(
  ({ recruitmentId }) => `recruitment/structure/${recruitmentId}`
)

export const fetchRecruitmentSection = fetchSection<{ recruitmentId: string; sectionId: string }>(
  ({ recruitmentId, sectionId }) => `recruitment/structure/${recruitmentId}/${sectionId}`
)

export const fetchRecruitmentSubsection = fetchSubsection<{
  recruitmentId: string
  sectionId: string
  subsectionId: string
}>(({ recruitmentId, sectionId, subsectionId }) => `recruitment/${recruitmentId}/${sectionId}/${subsectionId}`)

export const updateRecruitmentStructure = updateStructure<{ recruitmentId: string }>(
  ({ recruitmentId }) => `recruitment/structure/${recruitmentId}`,
  {}
)

export const searchRecruitmentQuestions = searchQuestions<{ recruitmentId: string }>(
  ({ recruitmentId }) => `recruitment/${recruitmentId}/variables`
)

export const updateRecruitmentSection = updateSection<{ sectionId: string; recruitmentId: string }>(
  ({ sectionId, recruitmentId }) => `recruitment/structure/${recruitmentId}/${sectionId}`
)

export const updateRecruitmentSubsection = updateSubsection<{
  sectionId: string
  subsectionId: string
  recruitmentId: string
}>(({ sectionId, subsectionId, recruitmentId }) => `recruitment/${recruitmentId}/${sectionId}/${subsectionId}`)
