/* eslint-disable camelcase */
import { createErrorsHandlers, paramsToQueryString, prepareSorter } from '../../utils'
import { BackendError } from '../RequestError'
import { SorterOrder } from '../SorterOrder'
import { SelectionKeys } from '../exports'
import { fetchApi } from '../fetchApi'

export interface ProductSorter {
  field: keyof Product
  order: SorterOrder
}

const sorterFields = {
  formula: ['formula_number'],
  productName: ['product_name'],
  brand: ['brand_name'],
  shadeName: ['shade_name'],
  devVol: ['developer_volume'],
  mixRatio: ['mix_ratio'],
  processingTime: ['processing_time'],
  specialInstructions: ['special_instructions'],
  specialApplication: ['special_application'],
  batch: ['batch_number'],
  technology: ['techno']
}

export enum ProductType {
  Standard = 'STANDARD',
  Sample = 'SAMPLE',
  Benchmark = 'BENCHMARK'
}

export enum ProductStatus {
  NotValidated = 'NOT_VALIDATED',
  Validated = 'VALIDATED',
  InProgress = 'IN_PROGRESS',
  Abandoned = 'ABANDONED'
}

export interface RemoteProduct {
  id: number
  formula_number: string
  type: ProductType
  brand_name: string
  product_name: string
  category: string
  techno: string
  status: ProductStatus
  shade_name: string
  batch_number: string
  developer_volume: string
  mix_ratio: string
  quantity: string
  processing_time: number
  special_instructions: string
  special_application: string
  is_deactivated: boolean
}

export interface Product {
  id?: number
  projectId?: string
  formula: string
  type: ProductType
  brand: string
  category: string
  productName: string
  technology: string
  status: ProductStatus
  shadeName: string
  batch: string
  devVol: string
  mixRatio: string
  quantity: string
  processingTime: number
  specialInstructions: string
  specialApplication: string
  isDeactivated: boolean
}

export const parseRemoteProduct = (remoteProduct: RemoteProduct) => ({
  id: remoteProduct.id,
  formula: remoteProduct.formula_number,
  type: remoteProduct.type,
  brand: remoteProduct.brand_name,
  category: remoteProduct.category,
  productName: remoteProduct.product_name,
  technology: remoteProduct.techno,
  status: remoteProduct.status,
  shadeName: remoteProduct.shade_name,
  batch: remoteProduct.batch_number,
  devVol: remoteProduct.developer_volume,
  mixRatio: remoteProduct.mix_ratio,
  quantity: remoteProduct.quantity,
  processingTime: remoteProduct.processing_time,
  specialInstructions: remoteProduct.special_instructions,
  specialApplication: remoteProduct.special_application,
  isDeactivated: remoteProduct.is_deactivated
})

export enum ProductSearchField {
  All = 'all',
  ShadeName = 'shade_name'
}

const getProductsSearchField = (search: string, searchField: ProductSearchField) =>
  search && searchField !== ProductSearchField.All ? searchField : undefined

export interface FetchProductsOptions {
  search?: string
  searchField?: ProductSearchField
  status?: string[]
  type?: string[]
  brand?: string[]
  isDeactivated?: boolean
  limit?: number
  offset?: number
  sorter?: ProductSorter
}

interface FetchProductsResponse {
  count: number
  standard_count: number
  benchmark_count: number
  sample_count: number
  next: number
  previous: number
  results: RemoteProduct[]
}

interface FetchProductsSuccessProps {
  products: Product[]
  allProductsCount: number
  standardProductsCount?: number
  benchmarkProductsCount?: number
  sampleProductsCount?: number
}

export interface FetchProductsResponseHandlers {
  onSuccess?: ({ products, allProductsCount }: FetchProductsSuccessProps) => void
  onRequestError?: (code: number) => void
}

const fetchProducts =
  (path: string) =>
  (
    { search, searchField, status, brand, type, isDeactivated, limit, offset, sorter }: FetchProductsOptions,
    responseHandlers: FetchProductsResponseHandlers
  ) => {
    const ordering = prepareSorter<typeof sorterFields, ProductSorter>(sorterFields, sorter)
    const query = {
      limit,
      offset,
      search,
      search_field: getProductsSearchField(search, searchField),
      status,
      type,
      brand_name: brand,
      is_deactivated: isDeactivated,
      ordering
    }

    const { req, cancel } = fetchApi.get<FetchProductsResponse>(path, query)

    req.then(({ error, body, status }) => {
      if (error) {
        createErrorsHandlers<FetchProductsResponseHandlers>({}, error, responseHandlers, status)
      } else {
        responseHandlers.onSuccess({
          products: body.results.map(parseRemoteProduct),
          allProductsCount: body.count,
          standardProductsCount: body.standard_count,
          benchmarkProductsCount: body.benchmark_count,
          sampleProductsCount: body.sample_count
        })
      }
    })

    return cancel
  }

export const fetchGlobalProducts = fetchProducts('product_database/products')
export const fetchProjectProducts = (projectId: string) => fetchProducts(`side_by_side/projects/${projectId}/products`)

interface ProductResponseHandlers {
  onSuccess?: () => void
  onProductAlreadyExists?: () => void
  onProductAlreadyHasTests?: () => void
  onRequestError?: (code: number) => void
}

export const parseProductForSave = (product: Product) => ({
  formula_number: product.formula,
  type: product.type,
  brand_name: product.brand,
  category: product.category,
  product_name: product.productName,
  techno: product.technology,
  status: product.status,
  shade_name: product.shadeName,
  batch_number: product.batch,
  developer_volume: product.devVol,
  mix_ratio: product.mixRatio,
  quantity: product.quantity,
  processing_time: product.processingTime,
  special_instructions: product.specialInstructions,
  special_application: product.specialApplication
})

export const createProduct = (product: Product, responseHandlers?: ProductResponseHandlers) => {
  const { req, cancel } = fetchApi.post('product_database/products', parseProductForSave(product))

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<ProductResponseHandlers>(
        {
          [BackendError.PRODUCT_DATABASE_DUPLICATE_FORMULA]: 'onProductAlreadyExists'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

export const editFormula = (product: Product, responseHandlers?: ProductResponseHandlers) => {
  const url = `side_by_side/projects/${product.projectId}/products/${product.id}`
  const { req, cancel } = fetchApi.patch(url, parseProductForSave(product))

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<ProductResponseHandlers>(
        {
          [BackendError.PRODUCT_DATABASE_DUPLICATE_FORMULA]: 'onProductAlreadyExists',
          [BackendError.PRODUCT_DATABASE_FORMULA_HAS_TESTS]: 'onProductAlreadyHasTests'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

type CreateFormulaOptions = Product & { isMasterProduct?: boolean }

export const createFormula = (options: CreateFormulaOptions, responseHandlers?: ProductResponseHandlers) => {
  const url = `side_by_side/projects/${options.projectId}/products`
  const { req, cancel } = fetchApi.post(url, { ...parseProductForSave(options), master: options.isMasterProduct })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<ProductResponseHandlers>(
        {
          [BackendError.PRODUCT_DATABASE_DUPLICATE_FORMULA]: 'onProductAlreadyExists'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface FormulasInProjectOptions {
  products: SelectionKeys
  search?: string
  searchField?: ProductSearchField
  status?: string[]
  type?: string[]
  brand?: string[]
  projectId: string
}

interface AssignToProjectResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const assignToProject = (
  { search, searchField, type, status, brand, projectId, products }: FormulasInProjectOptions,
  responseHandlers: AssignToProjectResponseHandlers
) => {
  const urlParams = {
    brand_name: brand,
    search_field: getProductsSearchField(search, searchField),
    type,
    status,
    search
  }
  const url = `product_database/products/assign/?${paramsToQueryString(urlParams)}&`
  const query = {
    project_uuid: projectId,
    ...(products?.length ? { products } : { undefined })
  }

  const { req, cancel } = fetchApi.post(url, query)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<AssignToProjectResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface ChangeFormulaVisibilityResponseHandlers {
  onSuccess?: (nrOfChangedFormulas: number) => void
  onRequestError?: (code: number) => void
}

export const changeFormulaVisibility = (
  {
    search,
    searchField,
    type,
    status,
    brand,
    projectId,
    products,
    isActivating
  }: FormulasInProjectOptions & { isActivating: boolean },
  responseHandlers?: ChangeFormulaVisibilityResponseHandlers
) => {
  const urlParams = {
    brand_name: brand,
    search_field: getProductsSearchField(search, searchField),
    type,
    status,
    search
  }
  const url = `side_by_side/projects/${projectId}/products/${
    isActivating ? 'activate' : 'deactivate'
  }?${paramsToQueryString(urlParams)}&`

  const query = {
    project_uuid: projectId,
    ...(products?.length ? { products } : { undefined })
  }

  const { req, cancel } = fetchApi.post<number>(url, query)

  req.then(({ body, error, status }) => {
    if (error) {
      createErrorsHandlers<ChangeFormulaVisibilityResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}
