/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'

export enum PredefinedUserRoleName {
  Admin = 'ADMIN',
  Investigator = 'INVESTIGATOR',
  Monitor = 'MONITOR',
  Auditor = 'AUDITOR',
  ProjectManager = 'PROJECT_MANAGER',
  DataManager = 'DATA_MANAGER',
  MainInvestigator = 'MAIN_INVESTIGATOR'
}

export type RoleName = PredefinedUserRoleName | string

export enum AclCategory {
  GlobalSettings = 'GLOBAL_SETTINGS',
  Study = 'STUDY',
  StudyAddons = 'STUDY_ADDONS',
  Ecrf = 'ECRF',
  Epro = 'EPRO',
  Econsent = 'ECONSENT',
  Econsult = 'ECONSULT',
  Analysis = 'ANALYSIS',
  SubjectRepository = 'SUBJECT_REPOSITORY',
  Recruitment = 'RECRUITMENT',
  Payments = 'PAYMENTS',
  SideBySide = 'SIDE_BY_SIDE',
  Calendar = 'CALENDAR'
}

export enum AclFeature {
  Analytics = 'analytics',
  AuditTrails = 'audit_trails',
  Centers = 'centers',
  DocumentManagement = 'document_management',
  EconsentBuilder = 'econsent_builder',
  EconsentDashboard = 'econsent_dashboard',
  EconsentSignature = 'econsent_signature',
  EcrfBuilder = 'ecrf_builder',
  EcrfDashboard = 'ecrf_dashboard',
  EcrfRecordList = 'ecrf_record_list',
  EcrfRecords = 'ecrf_records',
  EcrfFiles = 'ecrf_files',
  EproBuilder = 'epro_builder',
  EproDashboard = 'epro_dashboard',
  EproRecordsList = 'epro_records_list',
  EproSettings = 'epro_settings',
  EproFiles = 'epro_files',
  GlobalUsersManagement = 'global_users_management',
  Monitoring = 'monitoring',
  Password = 'password',
  PersonalDetail = 'personal_detail',
  Roles = 'roles',
  StudyAuditTrails = 'study_audit_trails',
  StudyList = 'study_list',
  StudySettings = 'study_settings',
  StudyUsersManagement = 'study_users_management',
  SubjectManagement = 'subject_management',
  Templates = 'templates',
  Econsult = 'econsult',
  Randomisation = 'randomisation',
  Automation = 'automation',
  Translations = 'translations',
  DraftStudy = 'draft_study',
  DataAnalysis = 'data_analysis',
  SubjectRepository = 'subject_repository',
  SubjectRepositoryBuilder = 'subject_repository_builder',
  SubjectRepositoryCampaign = 'subject_repository_campaign',
  SubjectConsents = 'subject_consents',
  ApiKey = 'api_key',
  GeneralSettings = 'general_settings',
  Recruitment = 'recruitment',
  RecruitmentBuilder = 'recruitment_builder',
  RecruitmentRecords = 'recruitment_records',
  Payments = 'payments',
  PaymentOrders = 'payment_orders',
  SideBySide = 'side_by_side',
  Calendar = 'calendar',
  Customisation = 'customization'
}

export const GlobalFeatures = [
  AclFeature.AuditTrails,
  AclFeature.Centers,
  AclFeature.GlobalUsersManagement,
  AclFeature.Password,
  AclFeature.PersonalDetail,
  AclFeature.Roles,
  AclFeature.Templates,
  AclFeature.StudyList,
  AclFeature.SubjectRepository,
  AclFeature.SubjectRepositoryBuilder,
  AclFeature.SubjectRepositoryCampaign,
  AclFeature.SubjectConsents,
  AclFeature.ApiKey,
  AclFeature.GeneralSettings,
  AclFeature.Recruitment,
  AclFeature.RecruitmentBuilder,
  AclFeature.RecruitmentRecords,
  AclFeature.Payments,
  AclFeature.PaymentOrders,
  AclFeature.SideBySide,
  AclFeature.Calendar,
  AclFeature.Customisation
]

export enum AclAction {
  Access = 'access',
  AccessList = 'access_list',
  AccessAll = 'access_all',
  Add = 'add',
  AddQueries = 'add_queries',
  Delete = 'delete',
  Edit = 'edit',
  Export = 'export',
  Import = 'import',
  Exclude = 'exclude',
  Sign = 'sign',
  Search = 'search',
  Unblock = 'unblock',
  Block = 'block',
  Lock = 'lock',
  Invite = 'invite',
  Answer = 'answer',
  ResolveQueries = 'resolve_queries',
  CloseQueries = 'close_queries',
  Upload = 'upload',
  StartCall = 'start_call',
  Redirect = 'redirect',
  Review = 'review',
  Reminders = 'reminders',
  BasicSettings = 'basic_settings',
  Comments = 'comments',
  Archive = 'archive',
  GenerateLink = 'generate_link',
  Randomisation = 'randomisation',
  Refresh = 'refresh'
}

export interface RemoteRoleData {
  id: number
  name: string
  users_count: number
  invitations_count: number
  is_predefined: boolean
}

export interface RoleData {
  id: string
  name: string
  usersCount: number
  invitationsCount: number
  isPredefined: boolean
}

export const parseRemoteRole = (role: RemoteRoleData) => {
  return {
    id: String(role.id),
    name: role.name,
    usersCount: role.users_count,
    invitationsCount: role.invitations_count,
    isPredefined: role.is_predefined
  }
}

interface FetchRolesOptions {
  studyId?: string
  options?: {
    limit?: number
    offset?: number
  }
}

interface FetchRolesResponseHandlers {
  onSuccess?: ({ roles, allUsersCount }: { roles: RoleData[]; allUsersCount: number }) => void
  onRequestError?: (code: number) => void
}

interface FetchRolesResponse {
  count: number
  results: RemoteRoleData[]
}

export const fetchRoles = ({ options, studyId }: FetchRolesOptions, responseHandlers?: FetchRolesResponseHandlers) => {
  const query = options?.limit
    ? {
        limit: options.limit,
        offset: options.offset
      }
    : {}

  const { req, cancel } = fetchApi.get<FetchRolesResponse>('users/roles', query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchRolesResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({ roles: body.results.map(parseRemoteRole), allUsersCount: body.count })
    }
  })

  return cancel
}

type RemotePermissions = Record<AclCategory, Record<AclFeature, RemoteSinglePermission[]>>
export type PermissionsCategories = Record<AclCategory, AclFeature[]>

const parseRemotePermissions = (remotePermissions: RemotePermissions) => {
  const categories = {} as PermissionsCategories
  const permissions = {} as PermissionsType

  Object.entries(remotePermissions).forEach(([category, features]) => {
    categories[category as AclCategory] = Object.keys(features) as AclFeature[]

    Object.entries(features).forEach(([feature, featurePermissions]) => {
      permissions[feature as AclFeature] = featurePermissions.map(parseSinglePermission)
    })
  })

  return { categories, permissions }
}

const parseRemoteRoleDetails = (remoteRoleDetails: RemoteRoleDetails) => {
  const { categories, permissions } = parseRemotePermissions(remoteRoleDetails.permissions)

  return {
    id: String(remoteRoleDetails.id),
    name: remoteRoleDetails.name,
    permissions,
    categories
  }
}

interface RemoteSinglePermission {
  id: string
  name: AclAction
  is_on: boolean
}

export interface SinglePermission {
  id: string
  name: AclAction
  isOn: boolean
}

const parseSinglePermission = (remoteSinglePermission: RemoteSinglePermission): SinglePermission => ({
  id: remoteSinglePermission.id,
  name: remoteSinglePermission.name,
  isOn: remoteSinglePermission.is_on
})

export interface RemoteRoleDetails {
  id: number
  name: string
  permissions: RemotePermissions
}

export type PermissionsType = Record<AclFeature, SinglePermission[]>

export interface RoleDetails {
  id: string
  name: RoleName
  permissions: PermissionsType
  categories: PermissionsCategories
}

interface FetchRoleDetailsResponseHandlers {
  onSuccess?: (roleDetails: RoleDetails) => void
  onRequestError?: (code: number) => void
}

export const fetchRoleDetails = ({ id }: { id: string }, responseHandlers?: FetchRoleDetailsResponseHandlers) => {
  const { req, cancel } = fetchApi.get<RemoteRoleDetails>(`users/roles/${id}`)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchRoleDetailsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteRoleDetails(body))
    }
  })

  return cancel
}

export interface UpdateRoleOptions {
  id: string
  name: string
  permissions: string[]
}

interface UpdateRoleDetailsResponseHandlers {
  onSuccess?: (roleDetails: RoleDetails) => void
  onRequestError?: (code: number) => void
  onNameTaken?: () => void
}

export const updateRoleDetails = (options: UpdateRoleOptions, responseHandlers?: UpdateRoleDetailsResponseHandlers) => {
  const { req, cancel } = fetchApi.patch<RemoteRoleDetails>(`users/roles/${options.id}`, {
    name: options.name,
    permissions: options.permissions
  })

  req.then(({ body, error, status }) => {
    if (error) {
      createErrorsHandlers<UpdateRoleDetailsResponseHandlers>(
        {
          [BackendError.ROLE_NAME_NOT_UNIQUE]: 'onNameTaken'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteRoleDetails(body))
    }
  })

  return cancel
}

interface AddRoleResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onNameTaken?: () => void
}

export const addRole = ({ name }: { name: string }, responseHandlers?: AddRoleResponseHandlers) => {
  const { req, cancel } = fetchApi.post('users/roles', { name })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<AddRoleResponseHandlers>(
        {
          [BackendError.ROLE_NAME_NOT_UNIQUE]: 'onNameTaken'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
