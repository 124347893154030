/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { fetchApi } from '../fetchApi'

interface CreateEsignatureLinkResponse {
  signature_link: string
}

interface CreateEsignatureLinkResponseHandlers {
  onSuccess?: (link: string) => void
  onRequestError?: (code: number) => void
}

export const createEsignatureLink = (
  { token, qrCode }: { token: string; qrCode: string },
  responseHandlers: CreateEsignatureLinkResponseHandlers
) => {
  const path = `subject_accounts/econsent/answer/sign/esignature/${token}`
  const { req, cancel } = fetchApi.post<CreateEsignatureLinkResponse>(path, {}, { qrCode })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<CreateEsignatureLinkResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess(body.signature_link)
    }
  })

  return cancel
}

export const createCounterEsignatureLink = (
  { studyId, recordId }: { studyId: string; recordId: string },
  responseHandlers: CreateEsignatureLinkResponseHandlers
) => {
  const path = `econsent/records/${recordId}/countersign/esignature`
  const { req, cancel } = fetchApi.post<CreateEsignatureLinkResponse>(path, {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<CreateEsignatureLinkResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess(body.signature_link)
    }
  })

  return cancel
}
