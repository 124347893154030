/* eslint-disable camelcase */
import { createErrorsHandlers, prepareSorter } from '../utils'
import { BackendError } from './RequestError'
import { SorterOrder } from './SorterOrder'
import { fetchApi } from './fetchApi'
import {
  RecordStatus,
  RemoteSectionAdvancement,
  SectionAdvancement,
  parseRemoteProgress,
  parseRemoteRecordAdvancement
} from './sectionAdvancement'
import {
  RecordVariable,
  RecordVariableRemote,
  VariableAnswerTypes,
  VariableBasic,
  parseRecordVariable
} from './variables'

interface RandomisationStratum {
  center: string
  questions: Record<string, string>
}

export interface Visit {
  id: string
  queries: number
  status: RecordStatus
  signature: boolean
  verification: boolean
  locked: boolean
}

interface RemoteVisit {
  id: string
  queries: number
  status: RecordStatus
  signature: boolean
  verification: boolean
  locked: boolean
}

export interface Inclusion {
  id: string
  name: string
  startDate: Date
  investigator: string
  progress: number
  subject: string
  studyName: string
  status: RecordStatus
  lastUpdate: Date
  lastUpdateAuthor: string
  sectionsAdvancement?: { [sectionId: string]: SectionAdvancement }
  variableAnswer: VariableAnswerTypes
  randomisationId: string
  randomisationInfo?: string
  randomisationGroup: string
  randomisationKit?: string
  randomisationDate: string
  randomisationUserName: string
  randomisationUserEmail: string
  randomisationStratum?: RandomisationStratum
  reviewStatus: RecordStatus
  reviewProgress: number
  studyCenterId: string
  visits?: Visit[]
}

interface RemoteRandomisationStratum {
  stratum_center: string
  stratum_answers: string[][]
}

export interface RemoteInclusion {
  id: number
  investigator: string
  last_updated: string
  last_updated_author: string
  start_date: string
  study_uuid: string
  study_name: string
  study_center_id: number
  datacapt_id: string
  subject_id_field: string
  status: RecordStatus
  progress: number
  sections?: RemoteSectionAdvancement[]
  variable_answer: {
    value: VariableAnswerTypes
  }
  randomisation_id: string
  randomisation_info?: string
  randomisation_date: string
  randomisation_user_name: string
  randomisation_user_email: string
  randomisation_group_name: string
  randomisation_kit?: string
  randomisation_stratum?: RemoteRandomisationStratum
  review_status: RecordStatus
  reviewed_subsections_percent: number
  visits?: Visit[]
  locked: boolean
}

interface FetchInclusionsVariablePayload {
  studyId: string
  searchPhrase?: string
  limit?: boolean
}

interface SetInclusionsVariablePayload {
  studyId: string
  variable?: VariableBasic
}

interface FetchInclusionResponse {
  count: number
  next: number
  previous: number
  results: RemoteInclusion[]
}

const parseRemoteRandomisationStratum = (remoteRandomisationStratum: RemoteRandomisationStratum) => ({
  center: remoteRandomisationStratum.stratum_center,
  questions: remoteRandomisationStratum.stratum_answers?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr[0]]: curr[1]
    }),
    {}
  )
})

const parseRemoteVisit = (visit: RemoteVisit) => ({
  id: String(visit.id),
  queries: visit.queries,
  status: visit.status,
  signature: visit.signature,
  verification: visit.verification,
  locked: visit.locked
})

const parseRemoteInclusion: (inclusion: RemoteInclusion) => Inclusion = (inclusion: RemoteInclusion) => ({
  id: String(inclusion.id),
  name: inclusion.subject_id_field,
  startDate: inclusion.start_date ? new Date(inclusion.start_date) : undefined,
  investigator: inclusion.investigator,
  progress: inclusion.progress,
  status: inclusion.status,
  studyName: inclusion.study_name,
  lastUpdate: inclusion.start_date ? new Date(inclusion.last_updated) : undefined,
  lastUpdateAuthor: inclusion.last_updated_author,
  subject: inclusion.datacapt_id,
  sectionsAdvancement: parseRemoteRecordAdvancement(inclusion.sections),
  variableAnswer: inclusion.variable_answer ? inclusion.variable_answer.value : null,
  randomisationId: inclusion.randomisation_id,
  randomisationInfo: inclusion.randomisation_info,
  randomisationUserName: inclusion.randomisation_user_name,
  randomisationUserEmail: inclusion.randomisation_user_email,
  randomisationDate: inclusion.randomisation_date,
  randomisationGroup: inclusion.randomisation_group_name,
  randomisationKit: inclusion.randomisation_kit,
  randomisationStratum: inclusion.randomisation_stratum
    ? parseRemoteRandomisationStratum(inclusion.randomisation_stratum)
    : null,
  reviewStatus: inclusion.review_status,
  reviewProgress: parseRemoteProgress(inclusion.reviewed_subsections_percent),
  studyCenterId: String(inclusion.study_center_id),
  visits: inclusion.visits?.map(parseRemoteVisit),
  locked: inclusion.locked
})

interface CreateNewInclusionOptions {
  studyId: string
  subjectId: string
  studyCenterId: string
  subjectIdFreeText?: string
}

interface CreateNewInclusionResponseHandlers {
  onSuccess?: (inclusion: Inclusion) => void
  onRequestError?: (code: number) => void
  onNoSubjectWithId?: () => void
  onIdNotUnique?: () => void
  onInclusionAlreadyExists?: () => void
}

interface FetchInclusionsVariableResponseHandlers {
  onSuccess?: (response: RecordVariable[]) => void
  onRequestError?: (code: number) => void
}

interface SetInclusionsVariableResponseHandlers {
  onSuccess?: (variable: RecordVariable) => void
  onRequestError?: (code: number) => void
}

export const createNewInclusion = (
  { studyId, subjectId, studyCenterId, subjectIdFreeText }: CreateNewInclusionOptions,
  responseHandlers?: CreateNewInclusionResponseHandlers
) => {
  const query = {
    datacapt_id: subjectId.toUpperCase(),
    study_center_id: studyCenterId,
    subject_id_free_text: subjectIdFreeText || null
  }
  const { req, cancel } = fetchApi.post<RemoteInclusion>('inclusions', query, { studyId })

  req.then(({ error, body: inclusion, status }) => {
    if (error) {
      createErrorsHandlers<CreateNewInclusionResponseHandlers>(
        {
          [BackendError.INCLUSION_ALREADY_EXISTS]: 'onInclusionAlreadyExists',
          [BackendError.SUBJECT_NOT_EXISTS]: 'onNoSubjectWithId',
          [BackendError.SUBJECT_ID_NOT_UNIQUE]: 'onIdNotUnique'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteInclusion(inclusion))
    }
  })

  return cancel
}

export interface InclusionsSorter {
  field: keyof Inclusion
  order: SorterOrder
}

const sorterFields = {
  name: ['abbrev', 'datacapt_id_sort'],
  startDate: ['start_date'],
  lastUpdate: ['last_updated'],
  investigator: ['investigator_name'],
  randomisationId: ['randomisation_id'],
  randomisationGroup: ['randomisation_group_name'],
  randomisationKit: ['randomisation_kit']
}

export interface InclusionsFilters {
  status?: RecordStatus
}

export enum InclusionsTab {
  List = 'LIST',
  Visit = 'VISIT'
}
interface FetchInclusionsOptions {
  studyId: string
  options?: {
    limit?: number
    offset?: number
    sorter?: InclusionsSorter
    search?: string
    filters?: Record<string, string[]>
    tab?: InclusionsTab
  }
}

interface FetchInclusionsResponseHandlers {
  onSuccess?: ({ inclusions, allInclusionsCount }: { inclusions: Inclusion[]; allInclusionsCount: number }) => void
  onRequestError?: (code: number) => void
}

export const fetchInclusions = (
  { studyId, options }: FetchInclusionsOptions,
  responseHandlers?: FetchInclusionsResponseHandlers
) => {
  const sorter = prepareSorter<typeof sorterFields, InclusionsSorter>(sorterFields, options.sorter)
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    search: options.search,
    status: options.filters?.status,
    review_status: options.filters?.reviewStatus,
    study_center_ids: options.filters?.center,
    investigator_id: options.filters?.investigator,
    tab: options.tab ? options.tab : InclusionsTab.List
  }

  const { req, cancel } = fetchApi.get<FetchInclusionResponse>('inclusions', query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchInclusionsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        inclusions: body.results.map(parseRemoteInclusion),
        allInclusionsCount: body.count
      })
    }
  })

  return cancel
}

interface FetchInclusionOptions {
  studyId: string
  inclusionId: string
}

interface FetchInclusionResponseHandlers {
  onSuccess?: (inclusion: Inclusion) => void
  onNotFound?: () => void
  onRequestError?: (code: number) => void
}

export const fetchInclusion = (
  { studyId, inclusionId }: FetchInclusionOptions,
  responseHandlers?: FetchInclusionResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteInclusion>(`inclusions/${inclusionId}`, {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchInclusionResponseHandlers>({ 404: 'onNotFound' }, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteInclusion(body))
    }
  })

  return cancel
}

interface DeleteInclusionOptions {
  studyId: string
  inclusionId: string
}

interface DeleteInclusionResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const deleteInclusion = (
  { studyId, inclusionId }: DeleteInclusionOptions,
  responseHandlers?: DeleteInclusionResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete(`inclusions/${inclusionId}`, {}, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<DeleteInclusionResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface FetchInclusionsResponseHandlers {
  onSuccess?: ({ inclusions, allInclusionsCount }: { inclusions: Inclusion[]; allInclusionsCount: number }) => void
  onRequestError?: (code: number) => void
}

export interface RemoteSectionSignatureBase {
  id: number
  signed_by: number
  signed_by_txt: string
  type: string
  date_signed: string
}

interface RemoteSectionSignature {
  id: number
  name: string
  order: number
  is_missing_required_answers: boolean
  signature?: RemoteSectionSignatureBase
}

export enum SignatureType {
  NotSigned = 'NOT_SIGNED',
  Signed = 'SIGNED'
}

export interface SectionSignatureBase {
  signatureType: SignatureType
  authorId?: string
  authorName?: string
  signingDate?: Date
}
export interface SectionSignature extends SectionSignatureBase {
  id: string
  name: string
  isMissingRequiredAnswers: boolean
}

const parseRemoteSignatureType = (type?: string) => {
  switch (type) {
    case 'SIGN':
      return SignatureType.Signed
    case 'NOT_SIGNED':
    default:
      return SignatureType.NotSigned
  }
}

export const parseRemoteSectionSignatureBase = (sectionsSignatureBase: RemoteSectionSignatureBase) => ({
  signatureType: parseRemoteSignatureType(sectionsSignatureBase?.type),
  authorId: sectionsSignatureBase?.signed_by && String(sectionsSignatureBase?.signed_by),
  authorName: sectionsSignatureBase?.signed_by_txt,
  signingDate: sectionsSignatureBase?.date_signed ? new Date(sectionsSignatureBase.date_signed) : undefined
})

const parseRemoteSectionSignatures = (sectionsSignatures: RemoteSectionSignature[]) =>
  sectionsSignatures.map(
    ss =>
      ({
        id: String(ss.id),
        name: ss.name,
        isMissingRequiredAnswers: !!ss.is_missing_required_answers,
        ...parseRemoteSectionSignatureBase(ss.signature)
      } as SectionSignature)
  )

interface FetchSectionSignaturesOptions {
  studyId: string
  inclusionId: string
}

interface FetchSectionSignaturesResponseHandlers {
  onSuccess?: (signatures: SectionSignature[]) => void
  onRequestError?: (code: number) => void
}

export const fetchSectionSignatures = (
  { studyId, inclusionId }: FetchSectionSignaturesOptions,
  responseHandlers?: FetchSectionSignaturesResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteSectionSignature[]>(`signature/${inclusionId}`, {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSectionSignaturesResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteSectionSignatures(body))
    }
  })

  return cancel
}

interface UpdateSectionSignaturesOptions {
  studyId: string
  inclusionId: string
  email: string
  password: string
  sectionsToSign: string[]
  sectionsToUnsign: string[]
}

interface UpdateSectionSignaturesResponseHandlers {
  onSuccess?: () => void
  onInvalidEmailOrPassword?: () => void
  onUserBlocked?: () => void
  onWrongSectionIds?: () => void
  onCannotSign?: () => void
  onCannotUnsign?: () => void
  onAlreadySigned?: () => void
  onRequestError?: (code: number) => void
}

export const updateSectionSignatures = (
  { studyId, inclusionId, email, password, sectionsToSign, sectionsToUnsign }: UpdateSectionSignaturesOptions,
  responseHandlers?: UpdateSectionSignaturesResponseHandlers
) => {
  const query = {
    inclusion_id: Number(inclusionId),
    email,
    password,
    sections_to_sign: sectionsToSign.map(sectionId => Number(sectionId)),
    sections_to_unsign: sectionsToUnsign.map(sectionId => Number(sectionId))
  }
  const { req, cancel } = fetchApi.post('signature', query, { studyId })
  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<UpdateSectionSignaturesResponseHandlers>(
        {
          [BackendError.USER_PASSWORD_WRONG]: 'onInvalidEmailOrPassword',
          [BackendError.USER_TEMPORARILY_BLOCKED]: 'onUserBlocked',
          [BackendError.SIGNATURE_WRONG_SECTION_IDS]: 'onWrongSectionIds',
          [BackendError.SIGNATURE_CANNOT_SIGN_SECTION]: 'onCannotSign',
          [BackendError.SIGNATURE_CANNOT_UNSIGN_SECTION]: 'onCannotUnsign',
          [BackendError.SIGNATURE_SECTION_ALREADY_SIGNED]: 'onAlreadySigned'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface ExcludeInclusionOptions {
  studyId: string
  inclusionId: string
  password: string
  reason: string
}

interface ExcludeInclusionResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onInvalidPassword?: () => void
  onUserBlocked?: () => void
}

export const excludeInclusion = (
  { studyId, inclusionId, password, reason }: ExcludeInclusionOptions,
  responseHandlers?: ExcludeInclusionResponseHandlers
) => {
  const query = {
    inclusion_id: inclusionId,
    password,
    reason
  }
  const { req, cancel } = fetchApi.post('inclusions/exclude', query, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<ExcludeInclusionResponseHandlers>(
        {
          [BackendError.USER_PASSWORD_WRONG]: 'onInvalidPassword',
          [BackendError.USER_TEMPORARILY_BLOCKED]: 'onUserBlocked'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface UnexcludeInclusionOptions {
  studyId: string
  inclusionId: string
  reason: string
}

interface UnexcludeInclusionResponse {
  status: RecordStatus
}

interface UnexcludeInclusionResponseHandlers {
  onSuccess?: (status: RecordStatus) => void
  onRequestError?: (code: number) => void
}

export const unexcludeInclusion = (
  { studyId, inclusionId, reason }: UnexcludeInclusionOptions,
  responseHandlers?: UnexcludeInclusionResponseHandlers
) => {
  const query = {
    inclusion_id: inclusionId,
    reason
  }
  const { req, cancel } = fetchApi.post<UnexcludeInclusionResponse>('inclusions/unexclude', query, { studyId })

  req.then(({ body, error, status }) => {
    if (error) {
      createErrorsHandlers<UnexcludeInclusionResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.status)
    }
  })

  return cancel
}

export const fetchInclusionsVariable = (
  { studyId, searchPhrase: filter_lookup = '', limit = true }: FetchInclusionsVariablePayload,
  responseHandlers: FetchInclusionsVariableResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RecordVariable[]>('studies/variables', { filter_lookup, limit }, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchInclusionsVariableResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}

export const setInclusionsVariable = (
  { studyId, variable }: SetInclusionsVariablePayload,
  responseHandlers: SetInclusionsVariableResponseHandlers
) => {
  const { req, cancel } = fetchApi.post<RecordVariableRemote>('inclusions/variable', variable, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<SetInclusionsVariableResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRecordVariable(body))
    }
  })

  return cancel
}

export const deleteInclusionsVariable = (
  { studyId }: SetInclusionsVariablePayload,
  responseHandlers: SetInclusionsVariableResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete('inclusions/variable', {}, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<SetInclusionsVariableResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(null)
    }
  })

  return cancel
}
