/* eslint-disable camelcase */
import { createErrorsHandlers, prepareSorter } from '../../utils'
import { SorterOrder } from '../SorterOrder'
import { fetchApi } from '../fetchApi'
import {
  RecordStatus,
  RemoteSectionAdvancement,
  SectionAdvancement,
  parseRemoteRecordAdvancement
} from '../sectionAdvancement'

export enum EproStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Ended = 'ENDED',
  Archived = 'ARCHIVED'
}

export interface Epro {
  eproName: string
  surveyName: string
  id: string
  studyId: string
  questionsNum: number
  creationDate: Date
  publishDate: Date
  recordsNum: number
  completedRecordsNum: number
  status: EproStatus
  lastEproRecordId?: string
}

export interface RemoteEpro {
  epro_name: string
  survey_name: string
  study: string
  id: number
  date_added: string
  date_published: string
  questions_count: number
  records_count: number
  completed_records_count: number
  status: EproStatus
  last_epro_record_id?: string
}

export type EproRecordQuestionsCount = { done: number; total: number }

interface RemoteEproRecord {
  epro: number
  id: number
  investigator: string
  start_date: string
  status: RecordStatus
  subject: string
  questions_count: number
  invitation_url: string
  answers_count: number
  occurrence_no: number
  sections?: RemoteSectionAdvancement[]
  randomisation_id: string
  randomisation_group_name: string
  epro_name?: string
}

export interface EproRecord {
  id: string
  subjectId: string
  sendDate: Date
  investigator: string
  invitationUrl: string
  questionsCount: EproRecordQuestionsCount
  occurrenceNumber: number
  status: RecordStatus
  name: string
  progress: number
  sectionsAdvancement?: { [sectionId: string]: SectionAdvancement }
  randomisationId: string
  randomisationGroup: string
  eproName?: string
  eproId?: string
}

const parseRemoteEpro = (remoteEpro: Partial<RemoteEpro>) => ({
  eproName: remoteEpro?.epro_name,
  surveyName: remoteEpro?.survey_name,
  id: String(remoteEpro?.id),
  creationDate: remoteEpro.date_added && new Date(remoteEpro.date_added),
  publishDate: remoteEpro.date_published && new Date(remoteEpro.date_published),
  studyId: remoteEpro?.study,
  questionsNum: remoteEpro?.questions_count,
  recordsNum: remoteEpro?.records_count,
  status: remoteEpro?.status,
  completedRecordsNum: remoteEpro?.completed_records_count,
  lastEproRecordId: remoteEpro?.last_epro_record_id
})

const parseRemoteEproRecord = (remoteRecord: RemoteEproRecord) => ({
  id: String(remoteRecord.id),
  subjectId: remoteRecord.subject,
  investigator: remoteRecord.investigator,
  sendDate: new Date(remoteRecord.start_date),
  invitationUrl: remoteRecord.invitation_url,
  questionsCount: { done: remoteRecord.answers_count, total: remoteRecord.questions_count },
  occurrenceNumber: remoteRecord.occurrence_no,
  status: remoteRecord.status,
  name: String(remoteRecord.id),
  progress: 0,
  sectionsAdvancement: parseRemoteRecordAdvancement(remoteRecord.sections),
  randomisationId: remoteRecord.randomisation_id,
  randomisationGroup: remoteRecord.randomisation_group_name,
  eproName: remoteRecord.epro_name,
  eproId: String(remoteRecord.epro)
})

interface CreateEproResponseHandlers {
  onSuccess?: (eproId: string) => void
  onRequestError?: (code: number) => void
}

interface CreateEproOptions {
  eproName: string
  surveyName: string
  studyId: string
}

interface CreateEproResponse {
  id: string
}

export const createEpro = (
  { studyId, eproName, surveyName }: CreateEproOptions,
  responseHandlers?: CreateEproResponseHandlers
) => {
  const query = {
    epro_name: eproName,
    survey_name: surveyName,
    study: studyId
  }
  const { req, cancel } = fetchApi.post<CreateEproResponse>('epro', query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<CreateEproResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.id)
    }
  })

  return cancel
}

interface ArchiveEproOptions {
  studyId: string
  eproId: string
}

interface ArchiveEproResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const archiveEpro = (
  { studyId, eproId }: ArchiveEproOptions,
  responseHandlers?: ArchiveEproResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete<RemoteEpro>(`epro/${eproId}`, {}, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<ArchiveEproResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

type FetchEprosResponse = { results: RemoteEpro[] }

interface FetchEprosResponseHandlers {
  onSuccess?: (epros: Epro[]) => void
  onRequestError?: (code: number) => void
}

interface FetchEprosOptions {
  studyId: string
  subjectId?: string
}

export const fetchEpros = (
  { studyId, subjectId }: FetchEprosOptions,
  responseHandlers?: FetchEprosResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<FetchEprosResponse>('studies/epro', { subject_uuid: subjectId }, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchEprosResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.results.map(parseRemoteEpro))
    }
  })

  return cancel
}

interface FetchEproOptions {
  studyId: string
  eproId: string
}

interface FetchEproResponseHandlers {
  onSuccess?: (epro: Epro) => void
  onRequestError?: (code: number) => void
}

export const fetchEpro = ({ studyId, eproId }: FetchEproOptions, responseHandlers?: FetchEproResponseHandlers) => {
  const { req, cancel } = fetchApi.get<RemoteEpro>(`epro/${eproId}`, {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchEproResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteEpro(body))
    }
  })

  return cancel
}

export interface EproRecordsSorter {
  field: keyof EproRecord
  order: SorterOrder
}

const sorterFields = {
  subjectId: ['subject'],
  sendDate: ['start_date'],
  investigator: ['investigator'],
  occurrenceNumber: ['occurrence_no'],
  randomisationId: ['randomisation_id'],
  randomisationGroup: ['randomisation_group_name']
}

interface FetchEproRecordsOptions {
  studyId: string
  eproId: string
  options?: {
    limit?: number
    offset?: number
    sorter?: EproRecordsSorter
    search?: string
    filters?: Record<string, string[]>
  }
}

interface FetchEproRecordsResponseHandlers {
  onSuccess?: ({ eproRecords, allEproRecordsCount }: { eproRecords: EproRecord[]; allEproRecordsCount: number }) => void
  onRequestError?: (code: number) => void
}

interface FetchEproRecordsResponse {
  count: number
  next: number
  previous: number
  results: RemoteEproRecord[]
}

export const fetchEproRecords = (
  { studyId, eproId, options }: FetchEproRecordsOptions,
  responseHandlers?: FetchEproRecordsResponseHandlers
) => {
  const sorter = prepareSorter<typeof sorterFields, EproRecordsSorter>(sorterFields, options.sorter)
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    status: options.filters?.status,
    study_center_id: options.filters?.center,
    search: options.search,
    filter_lookup: options.search
  }

  const { req, cancel } = fetchApi.get<FetchEproRecordsResponse>(`epro/${eproId}/records`, query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchEproRecordsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        eproRecords: body.results.map(parseRemoteEproRecord),
        allEproRecordsCount: body.count
      })
    }
  })

  return cancel
}

interface FetchSubjectEproRecordsOptions {
  subjectId: string
  studyId: string
  options?: {
    limit?: number
    offset?: number
    sorter?: EproRecordsSorter
  }
}

export const fetchSubjectEproRecords = (
  { studyId, subjectId, options }: FetchSubjectEproRecordsOptions,
  responseHandlers?: FetchEproRecordsResponseHandlers
) => {
  const sorter = prepareSorter<typeof sorterFields, EproRecordsSorter>(sorterFields, options.sorter)
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter
  }

  const { req, cancel } = fetchApi.get<FetchEproRecordsResponse>(`epro/records/${subjectId}`, query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchEproRecordsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        eproRecords: body.results.map(parseRemoteEproRecord),
        allEproRecordsCount: body.count
      })
    }
  })

  return cancel
}

interface FetchEproRecordOptions {
  studyId: string
  eproId: string
  recordId: string
}

interface FetchEproRecordResponseHandlers {
  onSuccess?: (eproRecord: EproRecord) => void
  onNotFound?: () => void
  onRequestError?: (code: number) => void
}

export const fetchEproRecord = (
  { studyId, eproId, recordId }: FetchEproRecordOptions,
  responseHandlers?: FetchEproRecordResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteEproRecord>(`epro/${eproId}/${recordId}`, {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchEproRecordResponseHandlers>(
        {
          404: 'onNotFound'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteEproRecord(body))
    }
  })

  return cancel
}

interface ArchiveEproRecordOptions {
  studyId: string
  eproId: string
  eproRecordId: string
}

interface ArchiveEproRecordResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const archiveEproRecord = (
  { studyId, eproId, eproRecordId }: ArchiveEproRecordOptions,
  responseHandlers?: ArchiveEproRecordResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete<RemoteEproRecord>(`epro/${eproId}/${eproRecordId}`, {}, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<ArchiveEproRecordResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
