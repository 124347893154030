import { SubjectRepositoryProfileTabs } from './components/SubjectRepository/SubjectRepositoryProfile'
import { AccountType } from './requests'
import { PagesAvailableToGoBack } from './utils'

const accountRoutePrefix = (accountType: AccountType) => (accountType === AccountType.User ? '/auth' : '/public')

export enum RouteStrings {
  UseHistoryBack = '#useHistoryBack'
}

export const routes = {
  home: '/',
  notFound: (accountType: AccountType) => `/${accountType === AccountType.User ? 'users' : ''}404`,
  sessionTimeout: (accountType: AccountType) => `${accountRoutePrefix(accountType)}/session-timeout`,
  signIn: (accountType: AccountType, qrCode?: string, email?: string) =>
    `${accountRoutePrefix(accountType)}/signin${qrCode && email ? `#${[qrCode, email].join(',')}` : ''}`,
  signUp: (accountType: AccountType) => `${accountRoutePrefix(accountType)}/signup`,
  signUpSuccess: (accountType: AccountType, token?: string) =>
    `${accountRoutePrefix(accountType)}/signup-success#${token}`,
  signUpFail: (accountType: AccountType) => `${accountRoutePrefix(accountType)}/signup-fail`,
  recoverPassword: (accountType: AccountType) => `${accountRoutePrefix(accountType)}/recover-password`,
  recoverPasswordSuccess: (accountType: AccountType) => `${accountRoutePrefix(accountType)}/recover-password-success`,
  changePassword: (accountType: AccountType, token?: string) =>
    `${accountRoutePrefix(accountType)}/change-password${token ? `/?token=${token}` : null}`,
  changePasswordSuccess: (accountType: AccountType) => `${accountRoutePrefix(accountType)}/change-password-success`,
  subjectDashboard: '/public',
  subjectDashboardSettings: '/public/settings',
  subjectBookAppointments: (recruitmentId: string, hadSurvey: boolean) =>
    `/book-appointments#${recruitmentId},${hadSurvey ? ',1' : ''}`,
  subjectBookAppointment: (recruitmentId: string, appointmentId: number) =>
    `/book-appointments#${recruitmentId},${appointmentId}`,
  studies: '/studies',
  studyEproSurvey: '/studies/epro-survey',
  eproSurvey: (token: string) => `/epro-survey/#${token}`,
  econsentSurvey: (token: string, qrCode?: string) => `/secure-econsent/#${token}${qrCode ? `,${qrCode}` : ''}`,
  studyDashboard: (studyId: string) => `/studies/${studyId}/dashboard`,
  studyEcrf: (studyId: string) => `/studies/${studyId}/ecrf`,
  studyEcrfStructure: (studyId: string) => `/studies/${studyId}/ecrf/structure`,
  studyEcrfNew: (studyId: string) => `/studies/${studyId}/ecrf/new`,
  studyInclusions: (studyId: string) => `/studies/${studyId}/inclusions`,
  studyGoBack: (studyId: string, studyPage: PagesAvailableToGoBack) => `/studies/${studyId}/${studyPage}`,
  studyAnalytics: (studyId: string) => `/studies/${studyId}/analytics`,
  studyDataAnalysis: (studyId: string) => `/studies/${studyId}/data-analysis`,
  studyMonitoring: (studyId: string) => `/studies/${studyId}/monitoring`,
  studyTranslations: (studyId: string) => `/studies/${studyId}/translations`,
  studyEpro: (studyId: string) => `/studies/${studyId}/epro`,
  studyEproBuilderStructure: (studyId: string, eproId: string) =>
    `/studies/${studyId}/epro/${eproId}/builder/structure`,
  studyEproBuilder: (studyId: string, eproId: string) => `/studies/${studyId}/epro/${eproId}/builder`,
  studyEproSection: (studyId?: string, eproId?: string, sectionId?: string) =>
    `/studies/${studyId ?? ':studyId'}/epro/${eproId ?? ':eproId'}/builder/section/${sectionId ?? ':sectionId'}`,
  studyEproRecords: (studyId: string, eproId: string) => `/studies/${studyId}/epro/${eproId}/records`,
  studyEproFulfillment: (
    studyId: string,
    eproId: string,
    recordId: string,
    sectionId?: string,
    subsectionId?: string
  ) =>
    `/studies/${studyId}/epro/${eproId}/records/${recordId}${sectionId ? `/${sectionId}` : ''}${
      subsectionId ? `/${subsectionId}` : ''
    }`,
  studyEproPublic: (token: string) => `/epro-public#${token}`,
  studyEconsent: (studyId: string) => `/studies/${studyId}/econsent`,
  studyEconsentSection: (studyId?: string, sectionId?: string) =>
    `/studies/${studyId ?? ':studyId'}/econsent/builder/section/${sectionId ?? ':sectionId'}`,
  studyEconsentStructure: (studyId: string) => `/studies/${studyId}/econsent/builder/structure`,
  studySettings: (studyId: string) => `/studies/${studyId}/settings`,
  studyAutomation: (studyId: string) => `/studies/${studyId}/automation`,
  studyDocuments: (studyId: string, folderPath?: string[]) => {
    const path = folderPath?.length ? `/${folderPath.join('/')}` : ''
    return `/studies/${studyId}/documents${path}`
  },
  studySubjects: (studyId: string, subjectId?: string) =>
    `/studies/${studyId}/subjects${subjectId ? `#${subjectId}` : ''}`,
  studyAuditTrails: (studyId: string) => `/studies/${studyId}/audit-trails`,
  studyUsers: (studyId: string) => `/studies/${studyId}/users`,
  studyEcrfSection: (studyId?: string, sectionId?: string) =>
    `/studies/${studyId ?? ':studyId'}/ecrf/section/${sectionId ?? ':sectionId'}`,
  studyEcrfFulfillment: (
    studyId: string,
    inclusionId: string,
    sectionId?: string,
    subsectionId?: string,
    queryId?: string
  ) =>
    `/studies/${studyId}/inclusions/${inclusionId}${sectionId ? `/${sectionId}` : ''}${
      subsectionId ? `/${subsectionId}` : ''
    }${queryId ? `#${queryId}` : ''}`,
  studyEconsentBuilder: (studyId: string) => `/studies/${studyId}/econsent/builder`,
  studyEconsentList: (studyId: string) => `/studies/${studyId}/econsent/dashboard`,
  studyEconsentSigning: (studyId: string, subjectId?: string) =>
    `/studies/${studyId}/econsent/signatures${subjectId ? `#${subjectId}` : ''}`,
  settingsPersonalDetails: '/settings',
  settingsPasswordChange: '/settings/password-change',
  settingsUsers: '/settings/users',
  settingsRoles: '/settings/roles',
  settingsCenters: '/settings/centers',
  settingsTemplates: '/settings/templates',
  settingsAuditTrails: '/settings/audit-trails',
  settingsPrivacy: '/settings/compliance/privacy',
  settingsCompliance: '/settings/compliance',
  settingsApiKey: '/settings/api-credentials',
  settingsTranslations: '/settings/translations',
  settingsCustomisation: '/settings/customization',
  eConsultSubjectMeeting: '/econsult',
  subjectRepository: '/subject-database',
  subjectRepositoryBuilder: '/subject-database/builder',
  subjectRepositoryBuilderStructure: '/subject-database/builder/structure',
  subjectRepositoryQrCodes: '/subject-database/qr-codes',
  subjectRepositoryBuilderSection: (sectionId?: string) => `/subject-database/builder/section/${sectionId ?? ''}`,
  subjectRepositoryProfile: (subjectId: string, tab?: SubjectRepositoryProfileTabs) =>
    `/subject-database/profile#${subjectId}/${tab?.toLowerCase() || ''}`,
  subjectRepositorySettings: '/subject-database/settings',
  subjectRepositorySignup: (token: string) => `/public/signup-qr/#${token}`,
  subjectRepositorySurvey: (subjectId: string) => `/subject-survey/#${subjectId}`,
  recruitment: '/recruitment',
  recruitmentStudy: (studyId: string) => `/recruitment/${studyId}`,
  recruitmentStudySchedules: (studyId: string) => `/recruitment/${studyId}/schedules`,
  recruitmentStudySettings: (studyId: string) => `/recruitment/${studyId}/settings`,
  recruitmentStudySettingsUsers: (studyId: string) => `/recruitment/${studyId}/settings/users`,
  recruitmentStudySettingsDetails: (studyId: string) => `/recruitment/${studyId}/settings/details`,
  recruitmentStudySettingsWashOut: (studyId: string) => `/recruitment/${studyId}/settings/washout`,
  recruitmentStudyBuilder: (studyId: string) => `/recruitment/${studyId}/builder`,
  recruitmentStudyBuilderStructure: (studyId: string) => `/recruitment/${studyId}/builder/structure`,
  recruitmentStudySettingsPayment: (studyId: string) => `/recruitment/${studyId}/settings/payment`,
  recruitmentStudySettingsLink: (studyId: string) => `/recruitment/${studyId}/settings/link`,
  recruitmentStudySettingsQrCodes: (studyId: string) => `/recruitment/${studyId}/settings/qr-codes`,
  recruitmentStudyBuilderSection: (studyId?: string, sectionId?: string) =>
    `/recruitment/${studyId ?? ':studyId'}/builder/section/${sectionId ?? ':sectionId'}`,
  recruitmentStudyPayments: (studyId: string) => `/recruitment/${studyId}/payments`,
  recruitmentSignup: (qrCode: string, email?: string) => `/public/recruitment-qr/#${qrCode},${email ?? ''}`,
  recruitmentSurvey: (qrCode: string, email?: string) => `/recruitment-survey/#${qrCode},${email ?? ''}`,
  calendar: '/calendar',
  bookings: '/bookings',
  paymentOrders: '/payment-orders',
  sideBySide: '/side-by-side',
  sideBySideProject: (projectId: string) => `/side-by-side/${projectId}`,
  sideBySideProjectTest: (
    projectId: string,
    testId: string,
    sectionId?: string,
    subsectionId?: string,
    useHistoryBack?: boolean
  ) =>
    `/side-by-side/${projectId}/${testId}${sectionId ? `/${sectionId}` : ''}${subsectionId ? `/${subsectionId}` : ''}${
      useHistoryBack ? RouteStrings.UseHistoryBack : ''
    }`,
  sideBySideProjectSettings: (projectId: string) => `/side-by-side/${projectId}/settings`,
  sideBySideProjectBuilder: (projectId: string) => `/side-by-side/${projectId}/builder`,
  sideBySideProjectBuilderStructure: (projectId: string) => `/side-by-side/${projectId}/builder/structure`,
  sideBySideProjectSettingsUsers: (projectId: string) => `/side-by-side/${projectId}/settings/users`,
  sideBySideProjectSettingsPanelists: (projectId: string) => `/side-by-side/${projectId}/settings/panelists`,
  sideBySideProjectReports: (projectId: string) => `/side-by-side/${projectId}/reports`,
  sideBySideProjectProducts: (projectId: string) => `/side-by-side/${projectId}/products`,
  sideBySideProjectBuilderSection: (projectId?: string, sectionId?: string) =>
    `/side-by-side/${projectId ?? ':studyId'}/builder/section/${sectionId ?? ':sectionId'}`,
  products: '/products'
}

export const routesWithoutAutoRedirect = [
  'users/404',
  '404',
  'auth/session-timeout',
  'public/session-timeout',
  'auth/signin',
  'public/signin'
]
