/* eslint-disable camelcase */
import {
  Condition,
  ConditionalLogicJoinOperator,
  RemoteCondition,
  parseRemoteCondition,
  prepareConditionForSave
} from '../conditionalLogic'
import { DateTimePeriodType, QuestionType, operatorsWithoutAmount } from '../questions'

export enum ValidationRuleType {
  Warning = 'WARNING',
  Information = 'INFORMATION',
  Exclusion = 'EXCLUSION',
  Unqualified = 'UNQUALIFIED'
}

export enum ValidationOperator {
  Equal = 'EQUAL',
  NotEqual = 'NOT_EQUAL',
  Lower = 'LOWER',
  LowerOrEqual = 'LOWER_OR_EQUAL',
  Greater = 'GREATER',
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  MoreThanBefore = 'MORE_THAN_BEFORE',
  LessThanBefore = 'LESS_THAN_BEFORE',
  LessThanAfter = 'LESS_THAN_AFTER',
  MoreThanAfter = 'MORE_THAN_AFTER'
}

type NumberValidationValue = number

interface SelectValidationValue {
  selections: string[]
  other: boolean
}
interface DatetimeValidationValue {
  question_id: string
}

enum DateTimeValidationRuleReferenceType {
  Linked = 'LINKED'
}

type RemoteValidationRuleValue = NumberValidationValue | SelectValidationValue | DatetimeValidationValue

export interface RemoteValidationRule {
  operator: ValidationOperator
  value: RemoteValidationRuleValue
  validation_type?: DateTimeValidationRuleReferenceType
  operator_args?: {
    period_amount: number
    period_type: DateTimePeriodType
  }
  message_type: ValidationRuleType
  message: string
  join_operator: ConditionalLogicJoinOperator
  conditions: RemoteCondition[]
}

export interface ValidationRule {
  operator: ValidationOperator
  value: number | string[]
  type: ValidationRuleType
  message: string
  referenceQuestionId?: string
  periodAmount?: number
  periodType?: DateTimePeriodType
  conditions: Condition[]
}

export const parseRemoteValidation = (remoteRules: RemoteValidationRule[], questionType: QuestionType) => {
  return {
    validationRules: remoteRules?.map(remoteRule => {
      let value: number | string[]
      let referenceQuestionId: string
      switch (questionType) {
        case QuestionType.Number:
          value = remoteRule.value as NumberValidationValue
          break
        case QuestionType.Radio:
          value = (remoteRule.value as SelectValidationValue)?.selections
          if ((remoteRule.value as SelectValidationValue)?.other) value.push('other')
          break
        case QuestionType.DateTime:
          referenceQuestionId = (remoteRule.value as DatetimeValidationValue)?.question_id?.toString()
          break
        default:
          value = undefined
          break
      }

      return {
        operator: remoteRule.operator,
        value,
        type: remoteRule.message_type,
        message: remoteRule.message,
        referenceQuestionId,
        periodAmount: remoteRule.operator_args?.period_amount,
        periodType: remoteRule.operator_args?.period_type,
        conditions: remoteRule.conditions?.length
          ? remoteRule.conditions.map((remoteCondition, index) =>
              parseRemoteCondition(remoteCondition, index ? remoteRule.join_operator : ConditionalLogicJoinOperator.And)
            )
          : []
      }
    })
  }
}

export const prepareValidationForSave = (rules: ValidationRule[], questionType: QuestionType) => {
  return (
    rules?.map(rule => {
      let value: RemoteValidationRuleValue
      let validation_type: DateTimeValidationRuleReferenceType
      switch (questionType) {
        case QuestionType.Number:
          value = rule.value as number
          break
        case QuestionType.Radio:
          value = {
            selections: (rule.value as string[]).filter(o => o !== 'other'),
            other: !!(rule.value as string[]).find(o => o === 'other')
          }
          break
        case QuestionType.DateTime:
          value = { question_id: rule.referenceQuestionId }
          validation_type = DateTimeValidationRuleReferenceType.Linked
          break
        default:
          value = undefined
          break
      }
      const finalCondition = rule?.conditions?.[rule.conditions.length - 1]

      return {
        value,
        message: rule.type !== ValidationRuleType.Unqualified ? rule.message : undefined,
        operator: rule.operator,
        operator_args: operatorsWithoutAmount.includes(rule.operator)
          ? null
          : {
              period_amount: rule.periodAmount,
              period_type: rule.periodType
            },
        message_type: rule.type,
        conditions: rule.conditions?.map(prepareConditionForSave),
        join_operator: finalCondition?.joinOperator,
        validation_type
      }
    }) || []
  )
}
