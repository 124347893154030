/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'

export enum SdvRequestType {
  Section = 'SECTION',
  Subsection = 'SUBSECTION',
  Question = 'QUESTION'
}

interface QuestionSdvOptions {
  requestType: SdvRequestType.Question
  questionId: string
  measureId: string
}

interface SectionSdvOptions {
  requestType: SdvRequestType.Section
  sectionId: string
}

interface SubsectionSdvOptions {
  requestType: SdvRequestType.Subsection
  subsectionId: string
}

export type SdvOptions = QuestionSdvOptions | SectionSdvOptions | SubsectionSdvOptions

interface SdvRequestOptions {
  type: SdvRequestType
  parentId: string
  measureId?: string
  inclusionId: string
  studyId: string
}

export interface SdvResponseHandlers {
  onSuccess?: () => void
  onAlreadyReviewed?: () => void
  onRequestError?: (code: number) => void
  onError?: () => void
}

export const sdvRequest =
  (method: 'DELETE' | 'POST') =>
  ({ studyId, parentId, measureId, inclusionId, type }: SdvRequestOptions, responseHandlers?: SdvResponseHandlers) => {
    const query = {
      parent_type: type,
      parent: parentId,
      measure: measureId || undefined,
      inclusion: inclusionId
    }

    const { req, cancel } =
      method === 'POST' ? fetchApi.post(`sdv`, query, { studyId }) : fetchApi.delete(`sdv`, query, { studyId })

    req.then(({ error, status }) => {
      if (error) {
        createErrorsHandlers<SdvResponseHandlers>(
          {
            [BackendError.REVIEW_SUBSECTION_ALREADY_REVIEWED_BY_USER]: 'onAlreadyReviewed'
          },
          error,
          responseHandlers,
          status
        )
      } else if (responseHandlers?.onSuccess) {
        responseHandlers.onSuccess()
      }
    })

    return cancel
  }
