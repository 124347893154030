/* eslint-disable camelcase */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { createErrorsHandlers } from '../../utils'
import { fetchApi } from '../fetchApi'
import { AnswerValidationMessage } from '../forms'
import { EcrfQuestionAnswer, EcrfRemoteQuestionAnswer, parseEcrfRemoteQuestionAnswer } from './index'

interface CreateMeasureAnswerOptions {
  studyId: string
  inclusionId: string
  repeatedMeasuresId: string
  rowId?: number
}

interface CreateMeasureResponse {
  id: number
  row?: number
}

interface CreateMeasureResponseHandlers {
  onSuccess?: (newMeasureId: string) => void
  onRequestError?: (code: number) => void
}

export const createMeasure = (
  { studyId, inclusionId, repeatedMeasuresId, rowId }: CreateMeasureAnswerOptions,
  responseHandlers: CreateMeasureResponseHandlers
) => {
  const query = {
    inclusion: inclusionId,
    repeated_measure: repeatedMeasuresId,
    ...(rowId && { row: rowId })
  }
  const { req, cancel } = fetchApi.post<CreateMeasureResponse>('answers/measure', query, { studyId })

  req.then(({ body, error, status }) => {
    if (error) {
      createErrorsHandlers<CreateMeasureResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(String(body.id))
    }
  })

  return cancel
}

interface CreateMeasuresResponseHandlers {
  onSuccess?: (newMeasures: CreateMeasureResponse[]) => void
  onRequestError?: (code: number) => void
}

export const createMeasures = (
  measuresData: CreateMeasureAnswerOptions[],
  responseHandlers: CreateMeasuresResponseHandlers
) => {
  const requests = measuresData.map(({ studyId, inclusionId, repeatedMeasuresId, rowId }) => {
    const query = {
      inclusion: inclusionId,
      repeated_measure: repeatedMeasuresId,
      ...(rowId && { row: rowId })
    }
    const { req } = fetchApi.post<CreateMeasureResponse>('answers/measure', query, { studyId })
    return req
  })
  Promise.all(requests).then(results => {
    const newIds: CreateMeasureResponse[] = []
    results.forEach(({ body, error, status }, index) => {
      if (error) {
        createErrorsHandlers<CreateMeasuresResponseHandlers>({}, error, responseHandlers, status)
      } else {
        newIds.push(body)
      }
    })
    if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(newIds)
    }
  })
}

interface DeleteMeasureOptions {
  studyId: string
  measureId: string
}

interface DeleteMeasureResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const deleteMeasure = (
  { studyId, measureId }: DeleteMeasureOptions,
  responseHandlers: DeleteMeasureResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete(`answers/measure/${measureId}`, {}, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<DeleteMeasureResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

export interface RemoteRepeatedMeasuresAnswer {
  type: 'REPEATED_MEASURE'
  repeated_measure: number
  results: {
    single_measure: number
    row?: number
    results: EcrfRemoteQuestionAnswer[]
  }[]
}

export interface RepeatedMeasuresAnswer {
  value: {
    measures: {
      measureId: string
      questionAnswers: EcrfQuestionAnswer[]
      rowId?: number
    }[]
  }
  blockId: string
  productId?: number
  validationMessages: AnswerValidationMessage[]
}

export const parseRemoteRepeatedMeasuresAnswer = (answer: RemoteRepeatedMeasuresAnswer) => ({
  blockId: String(answer.repeated_measure),
  value: {
    measures: answer.results.map(remoteMeasure => ({
      measureId: String(remoteMeasure.single_measure),
      questionAnswers: remoteMeasure.results.map(parseEcrfRemoteQuestionAnswer),
      rowId: remoteMeasure.row
    }))
  },
  validationMessages: [] as AnswerValidationMessage[]
})
