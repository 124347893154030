/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { fetchApi } from '../fetchApi'

interface RemotePanelist {
  id: number
  full_name: string
  datacapt_id: string
}

export interface Panelist {
  id: number
  fullName: string
  datacaptId: string
}

const parseRemotePanelist = (remotePanelist: RemotePanelist) => ({
  id: remotePanelist.id,
  fullName: remotePanelist.full_name,
  datacaptId: remotePanelist.datacapt_id
})

interface FetchPanelistsOptions {
  projectId: string
  search: string
  formula: number[]
  allTested: boolean
}

interface FetchPanelistsResponse {
  results: RemotePanelist[]
  count: number
}

interface FetchPanelistsResponseHandlers {
  onSuccess?: ({ panelists, countAll }: { panelists: Panelist[]; countAll: number }) => void
  onRequestError?: (code: number) => void
}

export const fetchPanelists = (
  { projectId, search, formula, allTested }: FetchPanelistsOptions,
  responseHandlers: FetchPanelistsResponseHandlers
) => {
  const url = `side_by_side/projects/${projectId}/panelists`
  const { req, cancel } = fetchApi.get<FetchPanelistsResponse>(url, { search, formula, all_tested: allTested })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchPanelistsResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess({
        panelists: body.results?.map(panelist => parseRemotePanelist(panelist)),
        countAll: body.count
      })
    }
  })

  return cancel
}
