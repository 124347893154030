/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { fetchApi } from '../fetchApi'

const apiKeyPath = 'users/settings/api_key'

interface CommonApiKeyResponse {
  api_key: string
}

interface CommonApiKeyHandlers {
  onSuccess?: (key: string) => void
  onRequestError?: (code: number) => void
}

export const fetchApiKey = (responseHandlers?: CommonApiKeyHandlers) => {
  const { req, cancel } = fetchApi.get<CommonApiKeyResponse>(apiKeyPath)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<CommonApiKeyHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.api_key)
    }
  })

  return cancel
}

export const generateApiKey = (responseHandlers?: CommonApiKeyHandlers) => {
  const { req, cancel } = fetchApi.post<CommonApiKeyResponse>(apiKeyPath, {})

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<CommonApiKeyHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.api_key)
    }
  })

  return cancel
}

export const deleteApiKey = (responseHandlers?: CommonApiKeyHandlers) => {
  const { req, cancel } = fetchApi.delete(apiKeyPath)
  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<CommonApiKeyHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(null)
    }
  })

  return cancel
}
