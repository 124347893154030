/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { fetchApi, mapData, mapFetchedData } from '../fetchApi'

const customisationDataUrl = 'customer_settings/global_customization'
const customisationDataPublicUrl = 'customer_settings/public_settings'

interface RemoteCustomisationData {
  main_colour?: string
  button_colour?: string
  logo?: string
  logo_url?: string
  reply_to_email?: string
  languages?: string[]
}

export interface CustomisationData {
  logoId?: string
  logoUrl?: string
  mainColor?: string
  buttonColor?: string
  replyToEmail?: string
  languages?: string[]
}

const customisationDataMapping = {
  mainColor: 'main_colour',
  buttonColor: 'button_colour',
  logoId: 'logo',
  logoUrl: 'logo_url',
  replyToEmail: 'reply_to_email',
  languages: 'languages'
}

interface FetchCustomisationDataResponseHandlers {
  onSuccess?: (data: CustomisationData) => void
  onRequestError?: (code: number) => void
}

export const fetchCustomisationData = (
  publicMode: boolean,
  responseHandlers?: FetchCustomisationDataResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteCustomisationData>(
    publicMode ? customisationDataPublicUrl : customisationDataUrl
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchCustomisationDataResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(mapFetchedData<CustomisationData>(customisationDataMapping, body))
    }
  })

  return cancel
}

interface UpdateCustomisationDataResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const updateCustomisationData = (
  options: CustomisationData,
  responseHandlers?: UpdateCustomisationDataResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch(
    customisationDataUrl,
    mapData<CustomisationData>(customisationDataMapping, options)
  )

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<UpdateCustomisationDataResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
