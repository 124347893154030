/* eslint-disable camelcase */
import { ContentState } from 'draft-js'

import { createErrorsHandlers } from '../../utils'
import { fetchApi } from '../fetchApi'
import {
  LanguageTranslations,
  RemoteLanguageTranslations,
  TranslatedLanguagesResponse,
  parseRemoteLanguageTranslations,
  parseTranslationValueForSave
} from '../generalSettings/translations'

interface FetchStudyLanguageTranslationsResponseHandlers {
  onSuccess?: (translations: LanguageTranslations[]) => void
  onRequestError?: (code: number) => void
}

interface FetchStudyLanguageTranslationsOptions {
  studyId: string
  ecrf: boolean
  econsent: boolean
  automation: boolean
  epros: string[]
  firstAccess?: boolean
}

export const fetchStudyTranslations = (
  { studyId, firstAccess, ecrf, econsent, automation, epros }: FetchStudyLanguageTranslationsOptions,
  responseHandlers?: FetchStudyLanguageTranslationsResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteLanguageTranslations[]>(
    'translations/studies/translations',
    { ecrf, econsent, automation, epros, first_access: !!firstAccess },
    { studyId }
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchStudyLanguageTranslationsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.map(parseRemoteLanguageTranslations))
    }
  })

  return cancel
}

interface ChooseStudyDefaultLanguageResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const chooseStudyDefaultLanguage = (
  { studyId, locale }: { studyId: string; locale: string },
  responseHandlers?: ChooseStudyDefaultLanguageResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch(
    'translations/studies/languages/default',
    { language_name: locale },
    { studyId }
  )

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<ChooseStudyDefaultLanguageResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface addStudyLanguageResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const addStudyLanguage = (
  { studyId, locale }: { studyId: string; locale: string },
  responseHandlers?: addStudyLanguageResponseHandlers
) => {
  const { req, cancel } = fetchApi.post('translations/studies/languages', { name: locale }, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<addStudyLanguageResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface deleteStudyLanguageResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const deleteStudyLanguage = (
  { studyId, locale }: { studyId: string; locale: string },
  responseHandlers?: deleteStudyLanguageResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete(`translations/studies/languages/${locale}`, {}, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<deleteStudyLanguageResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface changeStudyTranslationResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

interface changeStudyTranslationOptions {
  studyId: string
  language: string
  original: string | ContentState
  translated: string | ContentState
}

export const changeStudyTranslation = (
  { studyId, language, original, translated }: changeStudyTranslationOptions,
  responseHandlers?: changeStudyTranslationResponseHandlers
) => {
  const { req, cancel } = fetchApi.post(
    'translations/studies/translations',
    {
      language_name: language,
      original: parseTranslationValueForSave(original),
      translated: parseTranslationValueForSave(translated || '')
    },
    { studyId }
  )

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<changeStudyTranslationResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface FetchStudyTranslatedLanguagesResponseHandlers {
  onSuccess?: (languages: string[]) => void
  onRequestError?: (code: number) => void
}

const parseTranslatedLanguagesResponse = (response: TranslatedLanguagesResponse) => {
  return response.results.map(({ name }) => name)
}

interface FetchStudyTranslatedLanguagesOptions {
  studyId?: string
  token?: string
  feature?: string
  qrCode?: string
  hasTranslations?: boolean
}

export const fetchStudyTranslatedLanguages = (
  { studyId, token, feature, qrCode, hasTranslations }: FetchStudyTranslatedLanguagesOptions,
  responseHandlers?: FetchStudyTranslatedLanguagesResponseHandlers
) => {
  const { req, cancel } = studyId
    ? fetchApi.get<TranslatedLanguagesResponse>('translations/studies/languages', {}, { studyId })
    : fetchApi.get<TranslatedLanguagesResponse>('translations/studies/languages', { token, feature }, { qrCode })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchStudyTranslatedLanguagesResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(
        parseTranslatedLanguagesResponse({
          ...body,
          // filter locales with existing translations + default language(index = 0)
          results: hasTranslations
            ? body.results.filter((lang, index) => lang.has_translations || index === 0)
            : body.results
        })
      )
    }
  })

  return cancel
}
