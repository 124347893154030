import React, { useEffect, useState } from 'react'

import {
  AclAction,
  AclFeature,
  AvailableLocales,
  CurrentUserData,
  GlobalFeatures,
  LoginType,
  NextRelease,
  PredefinedUserRoleName,
  RoleDetails,
  UserConfig,
  UserConfigKey,
  UserConfigValue,
  ViewOptionsValue,
  getBackendUrl,
  setBackendUrl,
  updateUserConfig
} from '../../../requests'

type UserWithRole = CurrentUserData & { roleDetails?: RoleDetails; studyRoleDetails?: RoleDetails }

export class User {
  public readonly id: string

  private subjectId: string

  public readonly name: string

  public readonly email: string

  public readonly isDataAnalysisEnabled: boolean

  public readonly isEconsentEnabled: boolean

  public readonly isEconsultEnabled: boolean

  public readonly isEproEnabled: boolean

  public readonly isSubjectRepositoryEnabled: boolean

  public readonly isRecruitmentEnabled: boolean

  public readonly isSideBySideEnabled: boolean

  public readonly isCalendarEnabled: boolean

  public readonly isPaymentsEnabled: boolean

  public readonly isApiEnabled: boolean

  private roleDetails: RoleDetails

  private studyRoleDetails: RoleDetails

  public readonly isAuthorizedTo: Permissions

  public readonly loginType: LoginType

  public readonly language: AvailableLocales

  public readonly timezone: string

  public readonly roleId: string

  public readonly centerIds: string[]

  private config: UserConfig

  private nextRelease: NextRelease

  constructor(options: UserWithRole) {
    this.id = options?.id
    this.subjectId = options?.subjectId
    this.name = options?.name
    this.email = options?.email
    this.isDataAnalysisEnabled = options?.isDataAnalysisEnabled
    this.isEconsentEnabled = options?.isEconsentEnabled
    this.isEconsultEnabled = options?.isEconsultEnabled
    this.isEproEnabled = options?.isEproEnabled
    this.isSubjectRepositoryEnabled = options?.isSubjectRepositoryEnabled
    this.isApiEnabled = options?.isApiEnabled
    this.isRecruitmentEnabled = options?.isRecruitmentEnabled
    this.isCalendarEnabled = options?.isCalendarEnabled
    this.isPaymentsEnabled = options?.isPaymentsEnabled
    this.isSideBySideEnabled = options?.isSideBySideEnabled
    this.roleDetails = options?.roleDetails
    this.studyRoleDetails = options?.studyRoleDetails
    this.loginType = options?.loginType
    this.language = options?.language
    this.roleId = options?.roleId
    this.config = options?.config
    this.nextRelease = options?.nextRelease
    this.centerIds = options?.centerIds
    this.timezone = options?.timezone
  }

  isAuthorized() {
    return !!this.id
  }

  isAdmin() {
    return this.roleDetails.name === PredefinedUserRoleName.Admin
  }

  getName() {
    return this.name
  }

  getRoleName() {
    return this.roleDetails.name
  }

  getId() {
    return this.id
  }

  getSubjectId() {
    return this.subjectId
  }

  getRole() {
    return this.roleDetails
  }

  getStudyRole() {
    return this.studyRoleDetails
  }

  isRoleIdDifferent(roleIdToCompare: string) {
    return this.roleDetails.id !== roleIdToCompare
  }

  // global - some features, like translations can be global or study-specific. If we want to target them globally, we pass this argument
  private permissionArrayIncludes = (feature: AclFeature, actions: AclAction | AclAction[], global?: boolean) => {
    const permissions =
      global || GlobalFeatures.includes(feature) ? this.roleDetails?.permissions : this.studyRoleDetails?.permissions
    const availableActions = permissions?.[feature]
      ?.filter(permission => permission.isOn)
      .map(permission => permission.name)

    if (Array.isArray(actions)) {
      return actions.some(action => availableActions.includes(action))
    }

    return availableActions?.includes(actions)
  }

  canDo = (feature: AclFeature, global?: boolean) => (actions: AclAction | AclAction[]) =>
    this.permissionArrayIncludes(feature, actions, global)

  canAccess = (features: AclFeature | AclFeature[], global?: boolean) => {
    if (Array.isArray(features)) {
      return features.some(feature => this.canDo(feature, global)(AclAction.Access))
    }

    return this.canDo(features, global)(AclAction.Access)
  }

  getConfig = () => this.config

  getConfigValue = <T extends UserConfigValue>(key: UserConfigKey, defaultValue: T) => {
    const savedItem = this.config?.[key]
    return savedItem != null ? (savedItem as T) : defaultValue
  }

  setConfigValue = (key: UserConfigKey, value: UserConfigValue) => {
    this.config = {
      ...this.config,
      [key]: value
    }
    updateUserConfig(this.config)
  }

  getViewOptions = (viewId: string) => {
    return this.getConfigValue<Record<string, ViewOptionsValue>>(UserConfigKey.ViewOptionsKey, {})?.[viewId]
  }

  setViewOptions = (viewId: string, value: ViewOptionsValue) => {
    this.setConfigValue(UserConfigKey.ViewOptionsKey, {
      ...this.getConfigValue<Record<string, ViewOptionsValue>>(UserConfigKey.ViewOptionsKey, {}),
      [viewId]: value
    })
  }

  getRecentColors = () => {
    return this.getConfigValue<string[]>(UserConfigKey.RecentColors, [])
  }

  saveRecentColors = (hexColors: string[]) => {
    if (!hexColors?.length) return
    const previous = this.getConfigValue<string[]>(UserConfigKey.RecentColors, [])
    const newColors = hexColors.filter(color => !previous.includes(color))
    if (!newColors.length) return
    previous.unshift(...newColors)
    this.setConfigValue(UserConfigKey.RecentColors, previous.slice(0, 6))
  }

  getNextRelease = () => this.nextRelease

  shouldShowNextReleaseInfo = () =>
    this.nextRelease.active && this.config?.[UserConfigKey.ClosedReleaseNotes] !== this.nextRelease.code

  closeNextReleaseNotes = (code: string) => this.setConfigValue(UserConfigKey.ClosedReleaseNotes, code)
}

interface UserContextValue {
  user: User
  setUserData: (user: UserWithRole) => void
  setUserLanguage: (language: AvailableLocales) => void
}

export const UserContext = React.createContext<UserContextValue>({
  user: new User(null),
  setUserData: () => null,
  setUserLanguage: () => null
})

export const UserProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [userData, setUserData] = useState<UserWithRole>(null)
  const [isFetchingBackendUrl, setIsFetchingBackendUrl] = useState(true)
  const setUserLanguage = (language: AvailableLocales) => {
    setUserData({ ...userData, language })
  }

  useEffect(() => {
    if (getBackendUrl()) return

    const setUrl = (url?: string) => {
      const newUrl = url || process.env.GATSBY_BACKEND_API_URL
      setBackendUrl(newUrl)
      setIsFetchingBackendUrl(false)
    }

    fetch('/tenants/backend.json')
      .then(response => {
        if (!response.ok) throw new Error('Network response for backend.json was not ok')
        return response.json()
      })
      .then(data => {
        const getValue = (key: string) => {
          const value = Object.entries(data)?.find(([k]) => k === key)?.[1] as string
          return value && `https://${value}`
        }

        let url = getValue(window.location.host)
        if (!url) url = getValue('default')
        setUrl(url)
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.warn(error)
        setUrl()
      })
  }, [])

  return (
    !isFetchingBackendUrl && (
      <UserContext.Provider value={{ user: new User(userData), setUserData, setUserLanguage }}>
        {children}
      </UserContext.Provider>
    )
  )
}
