import { createErrorsHandlers } from '../../utils'
import { Feature } from '../Feature'
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'
import {
  BaseRemoteQuestionAnswer,
  FileAnswer,
  FulfillmentQuestionAnswer,
  QuestionType,
  RemoteFileAnswer,
  deleteAnswer,
  deleteFileAnswer,
  downloadFileAnswer,
  parseRemoteFileAnswer,
  parseRemoteQuestionAnswer,
  saveAnswer,
  saveFileAnswer
} from '../forms'
import {
  RemoteRepeatedMeasuresAnswer,
  RepeatedMeasuresAnswer,
  parseRemoteRepeatedMeasuresAnswer
} from './repeatedMeasuresAnswers'

export interface EcrfQuestionAnswer extends FulfillmentQuestionAnswer {
  inclusionId: string
  measureId?: string
  productId?: number
}

export interface EcrfRemoteQuestionAnswer extends BaseRemoteQuestionAnswer {
  inclusion: number
  measure?: number
}

export type RemoteEcrfAnswer = EcrfRemoteQuestionAnswer | RemoteRepeatedMeasuresAnswer
export type EcrfAnswer = EcrfQuestionAnswer | RepeatedMeasuresAnswer

export const parseEcrfRemoteQuestionAnswer = (answer: EcrfRemoteQuestionAnswer) => ({
  ...parseRemoteQuestionAnswer(answer),
  inclusionId: String(answer.inclusion),
  blockId: String(answer.question),
  measureId: answer.measure ? String(answer.measure) : null,
  productId: answer.product_id
})

export const parseEcrfRemoteAnswer = (answer: RemoteEcrfAnswer) =>
  answer.type === 'REPEATED_MEASURE'
    ? parseRemoteRepeatedMeasuresAnswer(answer as RemoteRepeatedMeasuresAnswer)
    : parseEcrfRemoteQuestionAnswer(answer as EcrfRemoteQuestionAnswer)

const getAnswersPatch = (feature: Feature, recordId: string, recruitmentId?: string, projectId?: string) => {
  switch (feature) {
    case Feature.SubjectRepository:
      return `subject_repository/answers/${recordId}`
    case Feature.Epro:
      return `epro/answers/${recordId}`
    case Feature.Ecrf:
      return `answers/${recordId}`
    case Feature.Recruitment:
      return `recruitment/studies/${recruitmentId}/answers/${recordId}`
    case Feature.SideBySide:
      return `side_by_side/projects/${projectId}/answers/${recordId}`
    default:
      return null
  }
}

interface FetchAnswersResponse {
  results: RemoteEcrfAnswer[]
}

type FetchAnswersOptions = {
  feature: Feature
  studyId: string
  recordId: string
  subsectionId: string
  language?: string
  recruitmentId?: string
  projectId?: string
}

export interface FetchAnswersResponseHandlers {
  onSuccess?: (answers: EcrfAnswer[]) => void
  onRequestError?: (code: number) => void
  onSectionHidden?: () => void
}

export const fetchAnswers = (
  { feature, studyId, recordId, subsectionId, language, recruitmentId, projectId }: FetchAnswersOptions,
  responseHandlers?: FetchAnswersResponseHandlers
) => {
  const path = `${getAnswersPatch(feature, recordId, recruitmentId, projectId)}/${subsectionId}`

  const { req, cancel } = fetchApi.get<FetchAnswersResponse>(path, {}, { studyId, language })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchAnswersResponseHandlers>(
        { [BackendError.ECRF_SECTION_HIDDEN]: 'onSectionHidden' },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.results.map(parseEcrfRemoteAnswer))
    }
  })

  return cancel
}

export interface ConditionReferenceAnswer extends FulfillmentQuestionAnswer {
  measureId?: string
}

interface RemoteConditionReferenceAnswer extends BaseRemoteQuestionAnswer {
  measure?: number
}

type FetchConditionReferenceAnswersOptions = {
  feature: Feature
  studyId: string
  recordId: string
  recruitmentId?: string
}

interface FetchConditionReferenceAnswersResponseHandlers {
  onSuccess?: (answers: ConditionReferenceAnswer[]) => void
  onRequestError?: (code: number) => void
}

export const parseRemoteConditionReferenceAnswer = (answer: RemoteConditionReferenceAnswer) => ({
  ...parseRemoteQuestionAnswer(answer),
  blockId: String(answer.question),
  measureId: answer.measure ? String(answer.measure) : null
})

export const fetchConditionReferenceAnswers = (
  { feature, recordId, studyId, recruitmentId }: FetchConditionReferenceAnswersOptions,
  responseHandlers?: FetchConditionReferenceAnswersResponseHandlers
) => {
  const path = getAnswersPatch(feature, recordId, recruitmentId)

  const { req, cancel } = fetchApi.get<RemoteConditionReferenceAnswer[]>(path, {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchConditionReferenceAnswersResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.map(parseRemoteConditionReferenceAnswer))
    }
  })

  return cancel
}

const ecrfAnswerMapping = {
  subjectId: 'subject',
  measureId: 'single_measure',
  reason: 'reason',
  missingReasonType: 'missing_reason_type'
}

type EcrfAnswerFields = {
  studyId: string
  subjectId: string
  measureId?: string
  reason?: string
  missingReasonType?: MissingReasonType
  nullAnswerType?: QuestionType
}

const getFilePath = () => 'uploaded_files/answer'
const getAnswerPath = (data: EcrfAnswerFields) => (data.measureId ? 'answers/measure/answer' : 'answers')

export const saveEcrfAnswer = saveAnswer<EcrfAnswerFields, EcrfRemoteQuestionAnswer, EcrfAnswer>(
  getAnswerPath,
  ecrfAnswerMapping,
  parseEcrfRemoteAnswer
)

export const deleteEcrfAnswer = deleteAnswer<EcrfAnswerFields>(getAnswerPath, ecrfAnswerMapping)

export const saveEcrfFileAnswer = saveFileAnswer<EcrfAnswerFields, RemoteFileAnswer, FileAnswer>(
  getFilePath,
  ecrfAnswerMapping,
  parseRemoteFileAnswer
)

interface EcrfDeleteFileFields {
  reason: string
}

const EcrfDeleteFileFieldsMapping = {
  reason: 'reason'
}

export const deleteEcrfFileAnswer = deleteFileAnswer<EcrfDeleteFileFields>(getFilePath, EcrfDeleteFileFieldsMapping)

export const downloadEcrfFileAnswer = downloadFileAnswer(getFilePath())

export enum MissingReasonType {
  notDone = 'ND',
  notApplicable = 'NA',
  Unknown = 'UNK',
  notAsked = 'NASK',
  askedButUnknown = 'ASKU'
}

export interface SaveMissingAnswersOptions {
  sectionId: string
  subsectionId: string
  reason: string
  missingReasonType: MissingReasonType
}

export interface SaveEcrfMissingAnswersOptions {
  studyId: string
  inclusionId: string
  sectionId: string
  subsectionId: string
  reason: string
  missingReasonType: MissingReasonType
}

export interface SaveMissingAnswersResponseHandlers {
  onSuccess?: () => void
  onAlreadyReviewed?: () => void
  onRequestError?: (code: number) => void
}

export const saveEcrfMissingAnswers = (
  { studyId, inclusionId, sectionId, subsectionId, reason, missingReasonType }: SaveEcrfMissingAnswersOptions,
  responseHandlers?: SaveMissingAnswersResponseHandlers
) => {
  const query = {
    inclusion: inclusionId,
    section: sectionId,
    subsection: subsectionId,
    reason,
    missing_reason_type: missingReasonType
  }
  const { req, cancel } = fetchApi.patch<SaveMissingAnswersOptions>('answers/missing', query, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<SaveMissingAnswersResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
