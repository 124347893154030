/* eslint-disable camelcase */
import { createErrorsHandlers } from '../utils'
import { BackendError } from './RequestError'
import { fetchApi } from './fetchApi'
import { LoginType } from './generalSettings'
import { InternationalPhoneNumber, phoneNumberToString } from './subjects'

export enum AccountType {
  User = 'user',
  Subject = 'subject'
}

export const accountUrlPrefix = (accountType: AccountType) =>
  accountType === AccountType.Subject ? 'subject_accounts' : 'users'

interface RegisterNewAccountOptions {
  firstname?: string
  lastname?: string
  email: string
  phone?: InternationalPhoneNumber
  language?: string
  company?: string
  password: string
  token: string
  accountType: AccountType
  isQrUsed?: boolean
}

interface RegisterNewAccountResponseHandlers {
  onSuccess?: (invitationToken: string) => void
  onRequestError?: (code: number) => void
  onNoSuchInvitation?: () => void
  onPasswordTooCommon?: () => void
  onPasswordTooSimilar?: () => void
  onWrongEmail?: () => void
  onEmailTaken?: () => void
}

interface registerNewAccountResponse {
  invitation_token?: string
}

export const registerNewAccount = (
  {
    firstname,
    lastname,
    email,
    company,
    password,
    token,
    accountType,
    phone,
    language,
    isQrUsed
  }: RegisterNewAccountOptions,
  responseHandlers?: RegisterNewAccountResponseHandlers
) => {
  const url = isQrUsed ? `subject_repository/qr/subjects/${token}` : `${accountUrlPrefix(accountType)}/register`
  const query = {
    first_name: firstname,
    last_name: lastname,
    email: email.toLowerCase(),
    phone: phoneNumberToString(phone) || null,
    language,
    company,
    password,
    token
  }
  const { req, cancel } = fetchApi.post<registerNewAccountResponse>(url, query)

  req.then(({ body, error, status }) => {
    if (error) {
      createErrorsHandlers<RegisterNewAccountResponseHandlers>(
        {
          [BackendError.USER_WITH_THAT_EMAIL_EXISTS]: 'onEmailTaken',
          [BackendError.USER_WRONG_INVITATION_EMAIL]: 'onWrongEmail',
          [BackendError.USER_PASSWORD_TOO_SIMILAR]: 'onPasswordTooSimilar',
          [BackendError.USER_PASSWORD_TOO_COMMON]: 'onPasswordTooCommon',
          [BackendError.USER_INVITATION_NOT_EXISTS]: 'onNoSuchInvitation'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.invitation_token)
    }
  })

  return cancel
}

interface ChangePasswordOptions {
  password: string
  token: string
  currentPassword?: string
}

interface ChangePasswordResponse {
  attempts_left: number
}

interface ChangePasswordResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onNoSuchInvitation?: () => void
  onPasswordTooCommon?: () => void
  onPasswordTooSimilar?: () => void
  onPasswordTheSameAsCurrent?: () => void
  onInvalidCredentials?: (attemptsLeft: number) => void
}

export const changePassword = (
  { password, token, currentPassword }: ChangePasswordOptions,
  responseHandlers?: ChangePasswordResponseHandlers
) => {
  const { req, cancel } = fetchApi.post<ChangePasswordResponse>('users/password_reset/confirm', {
    password,
    token,
    current_password: currentPassword
  })

  req.then(({ body, error, status }) => {
    if (error) {
      if (error?.code === BackendError.USER_PASSWORD_WRONG && responseHandlers?.onInvalidCredentials) {
        responseHandlers.onInvalidCredentials(body?.attempts_left)
      } else {
        createErrorsHandlers<Omit<ChangePasswordResponseHandlers, 'onInvalidCredentials'>>(
          {
            [BackendError.USER_PASSWORD_TOO_SIMILAR]: 'onPasswordTooSimilar',
            [BackendError.USER_PASSWORD_TOO_COMMON]: 'onPasswordTooCommon',
            [BackendError.USER_INVITATION_NOT_EXISTS]: 'onNoSuchInvitation',
            [BackendError.USER_PASSWORD_NEW_SAME_AS_OLD]: 'onPasswordTheSameAsCurrent'
          },
          error,
          responseHandlers,
          status
        )
      }
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface VerifyRegistrationTokenOptions {
  token: string
  accountType: AccountType
}

interface RemoteVerifyRegistrationToken {
  email?: string
  login_type?: LoginType
}

interface VerifyRegistrationTokenResponse {
  email?: string
  loginType?: LoginType
}

const parseVerifyRegistrationTokenResponse = (response: RemoteVerifyRegistrationToken) => ({
  email: response.email,
  loginType: response.login_type
})

interface VerifyRegistrationTokenResponseHandlers {
  onSuccess?: (response: VerifyRegistrationTokenResponse) => void
  onRequestError?: (code: number) => void
}

export const verifyRegistrationToken = (
  { token, accountType }: VerifyRegistrationTokenOptions,
  responseHandlers?: VerifyRegistrationTokenResponseHandlers
) => {
  const url =
    accountType === AccountType.Subject
      ? `${accountUrlPrefix(accountType)}/invitations/verify/${token}`
      : `invitations/${accountUrlPrefix(accountType)}/verify/${token}`

  const { req, cancel } = fetchApi.get<RemoteVerifyRegistrationToken>(url)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<VerifyRegistrationTokenResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseVerifyRegistrationTokenResponse(body))
    }
  })

  return cancel
}

interface ResendRegistrationConfirmMessageOptions {
  token?: string
  email: string
  accountType: AccountType
}

interface ResendRegistrationConfirmMessageResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onNoSuchInvitation?: () => void
}

export const resendRegistrationConfirmMessage = (
  { token, email, accountType }: ResendRegistrationConfirmMessageOptions,
  responseHandlers?: ResendRegistrationConfirmMessageResponseHandlers
) => {
  const { req, cancel } = fetchApi.post(`${accountUrlPrefix(accountType)}/register/resend`, {
    token,
    email: email.toLowerCase()
  })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<ResendRegistrationConfirmMessageResponseHandlers>(
        {
          [BackendError.USER_INVITATION_NOT_EXISTS]: 'onNoSuchInvitation'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface RequestPasswordChangeOptions {
  email: string
  accountType: AccountType
}

interface RequestPasswordChangeResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const requestPasswordChange = (
  { email, accountType }: RequestPasswordChangeOptions,
  responseHandlers?: RequestPasswordChangeResponseHandlers
) => {
  const { req, cancel } = fetchApi.post(`${accountUrlPrefix(accountType)}/password_reset`, {
    email: email.toLowerCase()
  })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<RequestPasswordChangeResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface VerifyAccountChangeOptions {
  token: string
  accountId: string
  accountType: AccountType
}

interface RemoteVerifyAccountResponse {
  econsent_token?: string
  access_token?: string
  subject_datacapt_id?: string
  qr_code?: string
}

interface VerifyAccountResponse {
  econsentToken?: string
  accessToken?: string
  subjectId?: string
  recruitmentToken?: string
}

const parseVerifyAccountResponse = (response: RemoteVerifyAccountResponse) => ({
  econsentToken: response.econsent_token,
  accessToken: response.access_token,
  subjectId: response.subject_datacapt_id,
  recruitmentToken: response.qr_code
})

interface VerifyAccountResponseHandlers {
  onSuccess?: (response: VerifyAccountResponse) => void
  onRequestError?: (code: number) => void
  onNoSuchInvitation?: () => void
}

export const verifyAccount = (
  { token, accountId, accountType }: VerifyAccountChangeOptions,
  responseHandlers?: VerifyAccountResponseHandlers
) => {
  const path = `${accountUrlPrefix(accountType)}/account/verify/${accountId}/${token}`
  const { req, cancel } = fetchApi.get<RemoteVerifyAccountResponse>(path)

  req.then(({ body, error, status }) => {
    if (error) {
      createErrorsHandlers<VerifyAccountResponseHandlers>(
        {
          [BackendError.USER_INVITATION_NOT_EXISTS]: 'onNoSuchInvitation'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseVerifyAccountResponse(body))
    }
  })

  return cancel
}

interface VerifyChangePasswordTokenOptions {
  token: string
  accountType: AccountType
}

interface VerifyChangePasswordTokenResponse {
  email: string
  is_old_password_needed?: boolean
  is_consent_needed?: boolean
}

interface VerifyChangePasswordTokenResponseHandlers {
  onSuccess?: (isOldPasswordNeeded: boolean, isConsentNeeded: boolean) => void
  onRequestError?: (code: number) => void
}

export const verifyChangePasswordToken = (
  { token, accountType }: VerifyChangePasswordTokenOptions,
  responseHandlers?: VerifyChangePasswordTokenResponseHandlers
) => {
  const path = `${accountUrlPrefix(accountType)}/password_reset/validate_token`
  const { req, cancel } = fetchApi.post<VerifyChangePasswordTokenResponse>(path, { token })

  req.then(({ body, error, status }) => {
    if (error) {
      createErrorsHandlers<VerifyChangePasswordTokenResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(!!body.is_old_password_needed, !!body.is_consent_needed)
    }
  })

  return cancel
}

export interface SettingsChangePasswordOptions {
  currentPassword: string
  newPassword: string
  accountType: AccountType
}

interface SettingsChangePasswordResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onInvalidPassword?: () => void
  onPasswordTheSameAsCurrent?: () => void
  onPasswordTooCommon?: () => void
  onPasswordTooSimilar?: () => void
  onError?: () => void
}

export const settingsChangePassword = (
  { currentPassword, newPassword, accountType }: SettingsChangePasswordOptions,
  responseHandlers?: SettingsChangePasswordResponseHandlers
) => {
  const { req, cancel } = fetchApi.post(`${accountUrlPrefix(accountType)}/change_password`, {
    current_password: currentPassword,
    new_password: newPassword
  })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<SettingsChangePasswordResponseHandlers>(
        {
          [BackendError.USER_PASSWORD_WRONG]: 'onInvalidPassword',
          [BackendError.USER_PASSWORD_TOO_SIMILAR]: 'onPasswordTooSimilar',
          [BackendError.USER_PASSWORD_TOO_COMMON]: 'onPasswordTooCommon',
          [BackendError.USER_PASSWORD_NEW_SAME_AS_OLD]: 'onPasswordTheSameAsCurrent'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

export enum MfaMethod {
  APP = 'app'
}

interface SettingsEnableMfaResponse {
  details: string
}

interface SettingsEnableMfaResponseHandlers {
  onSuccess?: (qrLink: string) => void
  onRequestError?: (code: number) => void
}

export const settingsEnableMfa = (responseHandlers?: SettingsEnableMfaResponseHandlers) => {
  const { req, cancel } = fetchApi.post<SettingsEnableMfaResponse>('users/auth/app/activate', { method: MfaMethod.APP })

  req.then(({ body, error, status }) => {
    if (error) {
      createErrorsHandlers<SettingsEnableMfaResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.details)
    }
  })

  return cancel
}

interface SettingsActivateMfaResponseHandlers {
  onSuccess?: () => void
  onWrongCode?: () => void
  onRequestError?: (code: number) => void
}

export const settingsActivateMfa = (
  { code }: { code: string },
  responseHandlers?: SettingsActivateMfaResponseHandlers
) => {
  const { req, cancel } = fetchApi.post('users/auth/app/activate/confirm', { code })
  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<SettingsActivateMfaResponseHandlers>(
        {
          400: 'onWrongCode'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

type SettingsCheckMfaMethodResponse = { results: { name: MfaMethod; is_primary: boolean }[] }

interface SettingsCheckMfaMethodResponseHandlers {
  onSuccess?: (name: MfaMethod) => void
  onRequestError?: (code: number) => void
}

export const settingsCheckMfaMethod = (responseHandlers?: SettingsCheckMfaMethodResponseHandlers) => {
  const { req, cancel } = fetchApi.get<SettingsCheckMfaMethodResponse>('users/auth/mfa/user-active-methods')

  req.then(({ body, error, status }) => {
    if (error) {
      createErrorsHandlers<SettingsCheckMfaMethodResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.results.filter(method => method.is_primary)[0]?.name)
    }
  })

  return cancel
}

interface SettingsDisableMfaResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const settingsDisableMfa = (responseHandlers?: SettingsDisableMfaResponseHandlers) => {
  const { req, cancel } = fetchApi.post('users/auth/app/deactivate', {})
  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<SettingsDisableMfaResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
