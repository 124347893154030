/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'
import { Epro, EproStatus } from './epros'

export enum EproReminderType {
  BeforeTarget = 'BEFORE_TARGET',
  Absolute = 'ABSOLUTE',
  AfterSending = 'AFTER_SENDING'
}

export enum EproPeriods {
  Days = 'DAYS',
  Hours = 'HOURS',
  Minutes = 'MINUTES'
}

type EproSettingsRelativeReminder = {
  type: EproReminderType.BeforeTarget | EproReminderType.AfterSending
  amount: number
  unit: EproPeriods
}

type EproSettingsAbsoluteReminder = {
  type: EproReminderType.Absolute
  sendDate: string
}

// eslint-disable-next-line max-len
export type EproSettingsReminder<T = EproSettingsAbsoluteReminder | EproSettingsRelativeReminder> =
  T extends EproSettingsAbsoluteReminder ? EproSettingsAbsoluteReminder : EproSettingsRelativeReminder

// eslint-disable-next-line max-len
export type RemoteEproSettingsReminder<T = RemoteEproSettingsAbsoluteReminder | RemoteEproSettingsRelativeReminder> =
  T extends RemoteEproSettingsAbsoluteReminder ? RemoteEproSettingsAbsoluteReminder : RemoteEproSettingsRelativeReminder

type RemoteEproSettingsRelativeReminder = {
  reminder_type: EproReminderType.BeforeTarget | EproReminderType.AfterSending
  amount: number
  unit: EproPeriods
}

type RemoteEproSettingsAbsoluteReminder = {
  reminder_type: EproReminderType.Absolute
  send_date: string
}

export interface EproQrCode {
  center: string
  token: string
}

export interface EproSettings {
  eproName: string
  surveyName: string
  status: EproStatus
  creationDate?: Date
  publishDate?: Date
  basicTargetDate?: string
  reminderSubject?: string
  reminderMessage?: string
  smsReminderActive?: boolean
  smsReminderMessage?: boolean
  reminders?: EproSettingsReminder[]
  occurrencesPlaned?: number
  occurrencesDistance?: number
  occurrencesDistanceType?: EproPeriods
  occurrencesMessageSubject?: string
  occurrencesMessageBody?: string
  qrIsOn: boolean
  qrEmailRequired?: boolean
  qrLandingTitle?: string
  qrLandingDescription?: string
  qrCodes: EproQrCode[]
}

interface RemoteEproSettings {
  epro_name: string
  survey_name: string
  status: string
  date_added?: string
  date_published?: string
  reminder_type?: string
  target_date?: string
  reminder_subject?: string
  reminder_message?: string
  sms_reminder_active?: boolean
  sms_reminder_message?: boolean
  reminders?: Array<RemoteEproSettingsRelativeReminder | RemoteEproSettingsAbsoluteReminder>
  occurrences_planned?: number
  occurrences_distance?: number
  occurrences_message_subject?: string
  occurrences_message_body?: string
  qr_on?: boolean
  qr_email_required?: boolean
  qr_landing_title?: string
  qr_landing_description?: string
  qr_codes?: Record<string, string>
}

const parseRemoteEproReminder = (reminder: RemoteEproSettingsReminder): EproSettingsReminder => {
  if (reminder.reminder_type === EproReminderType.Absolute) {
    return {
      type: reminder.reminder_type,
      sendDate: reminder.send_date
    }
  }

  return {
    type: reminder.reminder_type,
    amount: reminder.amount,
    unit: reminder.unit
  }
}

const parseEproReminderForSave = (reminder: EproSettingsReminder): RemoteEproSettingsReminder => {
  if (reminder.type === EproReminderType.Absolute) {
    return {
      reminder_type: reminder.type,
      send_date: reminder.sendDate && `${reminder.sendDate}Z` // it's UTC and BE needs 'Z' to parse it properly
    }
  }

  return {
    reminder_type: reminder.type,
    amount: reminder.amount,
    unit: reminder.unit
  }
}

// we need this parser because BE is sending us distance as seconds
// and we have two fields: distance and type (days/hours/minutes)
const parseOccurrencesDistance = (distance: number) => {
  if (!distance) return null

  if (Number.isInteger(distance / 24 / 60 / 60)) {
    return {
      occurrencesDistance: distance / 24 / 60 / 60,
      occurrencesDistanceType: EproPeriods.Days
    }
  }

  if (Number.isInteger(distance / 60 / 60)) {
    return {
      occurrencesDistance: distance / 60 / 60,
      occurrencesDistanceType: EproPeriods.Hours
    }
  }

  return {
    occurrencesDistance: Math.ceil(distance / 60),
    occurrencesDistanceType: EproPeriods.Minutes
  }
}

const parseRemoteEproSettings = (settings: RemoteEproSettings): EproSettings => {
  return {
    eproName: settings.epro_name,
    surveyName: settings.survey_name,
    status: settings.status as EproStatus,
    basicTargetDate: settings.target_date,
    reminderSubject: settings.reminder_subject,
    reminderMessage: settings.reminder_message,
    smsReminderActive: settings.sms_reminder_active,
    smsReminderMessage: settings.sms_reminder_message,
    creationDate: settings.date_added && new Date(settings.date_added),
    publishDate: settings.date_published && new Date(settings.date_published),
    occurrencesPlaned: settings.occurrences_planned,
    ...parseOccurrencesDistance(settings.occurrences_distance),
    occurrencesMessageSubject: settings.occurrences_message_subject,
    occurrencesMessageBody: settings.occurrences_message_body,
    ...(settings.reminders && { reminders: settings.reminders.map(parseRemoteEproReminder) }),
    qrIsOn: settings.qr_on,
    qrEmailRequired: settings.qr_on && settings.qr_email_required,
    qrLandingTitle: settings.qr_on && settings.qr_landing_title,
    qrLandingDescription: settings.qr_on && settings.qr_landing_description,
    qrCodes:
      settings.qr_on &&
      settings.qr_codes &&
      Object.entries(settings.qr_codes).map(([center, token]) => ({ center, token }))
  }
}

const parseOccurrencesDistanceForSave = (distance: number, period: EproPeriods) => {
  switch (period) {
    case EproPeriods.Days:
      return distance * 60 * 60 * 24
    case EproPeriods.Hours:
      return distance * 60 * 60
    default:
      return distance * 60
  }
}

const parseEproSettingsForSave = (
  settings: Exclude<EproSettings, 'creationDate' | 'publishDate'>
): RemoteEproSettings => {
  type OmitDate = Omit<
    EproSettings,
    | 'eproName'
    | 'surveyName'
    | 'reminderSubject'
    | 'reminderMessage'
    | 'smsReminderActive'
    | 'smsReminderMessage'
    | 'basicTargetDate'
    | 'creationDate'
    | 'publishDate'
    | 'occurrencesPlaned'
    | 'occurrencesDistance'
    | 'occurrencesDistanceType'
    | 'occurrencesMessageSubject'
    | 'occurrencesMessageBody'
    | 'qrIsOn'
    | 'qrEmailRequired'
    | 'qrLandingTitle'
    | 'qrLandingDescription'
    | 'qrCodes'
  >
  return Object.entries(settings).reduce((acc, [key, value]) => {
    switch (key as keyof EproSettings) {
      case 'eproName':
        acc.epro_name = value
        break
      case 'surveyName':
        acc.survey_name = value
        break
      case 'reminderSubject':
        acc.reminder_subject = value
        break
      case 'reminderMessage':
        acc.reminder_message = value
        break
      case 'smsReminderActive':
        acc.sms_reminder_active = !!value
        break
      case 'smsReminderMessage':
        acc.sms_reminder_message = value
        break
      case 'basicTargetDate':
        acc.target_date = value && `${value}Z` // it's UTC and BE needs 'Z' to parse it properly
        break
      case 'reminders':
        acc.reminders = value.map(parseEproReminderForSave)
        break
      case 'occurrencesPlaned':
        acc.occurrences_planned = value
        break
      case 'occurrencesDistance':
        acc.occurrences_distance = parseOccurrencesDistanceForSave(value, settings.occurrencesDistanceType)
        break
      case 'occurrencesDistanceType':
        break
      case 'occurrencesMessageSubject':
        acc.occurrences_message_subject = value
        break
      case 'occurrencesMessageBody':
        acc.occurrences_message_body = value
        break
      case 'qrEmailRequired':
        acc.qr_email_required = value
        break
      case 'qrLandingTitle':
        acc.qr_landing_title = value
        acc.qr_on = !!value
        break
      case 'qrLandingDescription':
        acc.qr_landing_description = value
        break
      default:
        acc[key as keyof OmitDate] = value
    }
    return acc
  }, {} as RemoteEproSettings)
}

interface FetchEproSettingsResponseHandlers {
  onSuccess?: (eproSettings: EproSettings) => void
  onRequestError?: (code: number) => void
}

interface FetchEproSettingsOptions {
  studyId: string
  eproId: string
}

export const fetchEproSettings = (
  { studyId, eproId }: FetchEproSettingsOptions,
  responseHandlers?: FetchEproSettingsResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteEproSettings>(`epro/settings/${eproId}`, {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchEproSettingsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteEproSettings(body))
    }
  })

  return cancel
}

export interface UpdateEproSettingOptions {
  studyId: string
  eproId: Epro['id']
  settings: EproSettings
}

interface UpdateEproSettingsResponseHandlers {
  onSuccess?: (eproSettings: EproSettings) => void
  onTransitionNotAllowed?: () => void
  onStudyNotLive?: () => void
  onInvalidTargetDate?: () => void
  onInvalidReminderDate?: () => void
  onReminderWithoutTargetDate?: () => void
  onRequestError?: (code: number) => void
  onError?: () => void
}

export const updateEproSettings = (
  { studyId, eproId, settings }: UpdateEproSettingOptions,
  responseHandlers?: UpdateEproSettingsResponseHandlers
) => {
  const path = `epro/${eproId}`
  const { req, cancel } = fetchApi.put<RemoteEproSettings>(path, parseEproSettingsForSave(settings), { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<UpdateEproSettingsResponseHandlers>(
        {
          [BackendError.EPRO_STATUS_TRANSITION_NOT_ALLOWED]: 'onTransitionNotAllowed',
          [BackendError.STUDY_STATUS_NOT_LIVE]: 'onStudyNotLive',
          [BackendError.EPRO_INVALID_TARGET_DATE]: 'onInvalidTargetDate',
          [BackendError.EPRO_REMINDER_INVALID_DATE]: 'onInvalidReminderDate',
          [BackendError.EPRO_REMINDER_NO_TARGET_DATE]: 'onReminderWithoutTargetDate'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteEproSettings(body))
    }
  })

  return cancel
}
