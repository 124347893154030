/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'
import { OptionsRenderTypes } from '../studies'
import { RecordVariable } from '../variables'
import { TableResultRowItemClient } from './tables'

export enum AnalyticsTabType {
  Table = 'TABLE',
  Charts = 'CHARTS'
}

interface CreateTabPayload {
  studyId: string
  name: string
  type: AnalyticsTabType
}

interface DeleteTabPayload {
  studyId: string
  id: string
}

export interface AnalyticsTab {
  id: number
  name: string
  type: AnalyticsTabType
}

export interface FetchAnalyticsTabsResponse {
  count: number
  next: null | boolean
  previous: null | boolean
  results: AnalyticsTab[]
}

export interface AnalyticsTabDetails {
  id: string
  name: string
  type: AnalyticsTabType
  dateAdded: string
  allCount: number
  config: {
    variables: RecordVariable[]
    optionsRenderType: OptionsRenderTypes
  }
  results: TableResultRowItemClient[]
}

interface CreateTabResponseHandlers {
  onSuccess?: (response: AnalyticsTabDetails) => void
  onRequestError?: (code: number) => void
  onTablesLimitReached?: () => void
}

interface DeleteTabResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

interface FetchTabsResponseHandlers {
  onSuccess?: (response: FetchAnalyticsTabsResponse) => void
  onRequestError?: (code: number) => void
}

export const fetchTabList = (studyId: string, responseHandlers?: FetchTabsResponseHandlers) => {
  const { req, cancel } = fetchApi.get<FetchAnalyticsTabsResponse>('analytics', {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchTabsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}

export const createNewTab = (
  { studyId, name, type }: CreateTabPayload,
  responseHandlers?: CreateTabResponseHandlers
) => {
  const query = {
    name,
    study: studyId,
    config: {
      variables: [] as RecordVariable[],
      options_render_type: OptionsRenderTypes.LABEL
    },
    type
  }
  const { req, cancel } = fetchApi.post<AnalyticsTabDetails>('analytics', query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<CreateTabResponseHandlers>(
        {
          [BackendError.ANALYTICS_TABLE_LIMIT_REACHED]: 'onTablesLimitReached'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}

export const deleteTab = ({ studyId, id }: DeleteTabPayload, responseHandlers?: DeleteTabResponseHandlers) => {
  const { req, cancel } = fetchApi.delete(`analytics/${id}`, {}, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<DeleteTabResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
