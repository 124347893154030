/* eslint-disable camelcase */
import { ContentState, convertFromRaw, convertToRaw } from 'draft-js'

import { createErrorsHandlers } from '../../utils'
import { fetchApi } from '../fetchApi'

export interface RemoteLanguageTranslations {
  locale: string
  translations: { original: string; translated: string }[]
  default: boolean
}

export interface LanguageTranslations {
  locale: string
  translations: Record<string, string | ContentState>
  isDefault: boolean
}

export const parseRemoteLanguageTranslations = (languageTranslation: RemoteLanguageTranslations) => {
  return {
    locale: languageTranslation.locale,
    translations: languageTranslation.translations.reduce((acc, { translated }, index) => {
      let currentTranslation
      try {
        currentTranslation = convertFromRaw(JSON.parse(translated))
      } catch {
        currentTranslation = translated
      }
      acc[String(index)] = currentTranslation
      return acc
    }, {} as Record<string, string | ContentState>),
    isDefault: languageTranslation.default
  }
}

export interface FetchTranslationsOptions {
  subjectDatabase: boolean
  privacySettings: boolean
}

interface FetchLanguageTranslationsResponseHandlers {
  onSuccess?: (translations: LanguageTranslations[]) => void
  onRequestError?: (code: number) => void
}

export const fetchTranslations = (
  { subjectDatabase, privacySettings }: FetchTranslationsOptions,
  responseHandlers?: FetchLanguageTranslationsResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteLanguageTranslations[]>('translations/translations', {
    subject_database: subjectDatabase,
    privacy_settings: privacySettings
  })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchLanguageTranslationsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.map(parseRemoteLanguageTranslations))
    }
  })

  return cancel
}

interface ChooseDefaultLanguageResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const chooseDefaultLanguage = (
  { locale }: { locale: string },
  responseHandlers?: ChooseDefaultLanguageResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch('translations/languages/default', { language_name: locale })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<ChooseDefaultLanguageResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface addLanguageResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const addLanguage = ({ locale }: { locale: string }, responseHandlers?: addLanguageResponseHandlers) => {
  const { req, cancel } = fetchApi.post('translations/languages', { name: locale })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<addLanguageResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface deleteLanguageResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const deleteLanguage = ({ locale }: { locale: string }, responseHandlers?: deleteLanguageResponseHandlers) => {
  const { req, cancel } = fetchApi.delete(`translations/languages/${locale}`)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<deleteLanguageResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface changeTranslationResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

interface changeTranslationOptions {
  language: string
  original: string | ContentState
  translated: string | ContentState
}

export const parseTranslationValueForSave = (value: string | ContentState) => {
  if (typeof value === 'string') return value
  return JSON.stringify(convertToRaw(value))
}

export const changeTranslation = (
  { language, original, translated }: changeTranslationOptions,
  responseHandlers?: changeTranslationResponseHandlers
) => {
  const { req, cancel } = fetchApi.post('translations/translations', {
    language_name: language,
    original: parseTranslationValueForSave(original),
    translated: parseTranslationValueForSave(translated || '')
  })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<changeTranslationResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface FetchTranslatedLanguagesResponseHandlers {
  onSuccess?: (languages: string[]) => void
  onRequestError?: (code: number) => void
}

export interface TranslatedLanguagesResponse {
  results: { name: string; has_translations: boolean }[]
}

const parseTranslatedLanguagesResponse = (response: TranslatedLanguagesResponse) => {
  return response.results.map(({ name }) => name)
}

interface FetchTranslatedLanguagesOptions {
  hasTranslations?: boolean
}

export const fetchTranslatedLanguages = (
  { hasTranslations }: FetchTranslatedLanguagesOptions,
  responseHandlers?: FetchTranslatedLanguagesResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<TranslatedLanguagesResponse>('translations/languages', {})

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchTranslatedLanguagesResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(
        parseTranslatedLanguagesResponse({
          ...body,
          // filter locales with existing translations + default language(index = 0)
          results: hasTranslations
            ? body.results.filter((lang, index) => lang.has_translations || index === 0)
            : body.results
        })
      )
    }
  })

  return cancel
}
