/* eslint-disable camelcase */
import dayjs, { Dayjs } from 'dayjs'

import { createErrorsHandlers } from '../../utils'
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'
import { RemoteScheduleSlot, ScheduleSlot, parseRemoteScheduleSlot } from './slots'

interface RemoteScheduleVisitAvailability {
  date: string
  start_time: string
  end_time: string
}

interface RemoteScheduleVisit {
  id: number
  order: number
  title: string
  appointment_duration: number
  center_id: number
  center_name?: string
  booking_window: number
  slots_interval: number
  slot_capacity: number
  coordinator_id: number
  payment_amount: number
  timezone: string
  availabilities: RemoteScheduleVisitAvailability[]
  bookings_count?: number
  slots?: RemoteScheduleSlot[]
  schedule_name?: string
}

export interface ScheduleVisitAvailability {
  date: Dayjs
  startTime: string
  endTime: string
  isSameDay?: boolean
}

export interface ScheduleVisit {
  id?: number
  number: number
  title: string
  duration: number
  centerId: string
  centerName?: string
  window: number
  interval: number
  capacity: number
  coordinatorId: number
  payment: number
  timezone: string
  availability: ScheduleVisitAvailability[]
  bookingsCount?: number
  slots?: ScheduleSlot[]
  scheduleName?: string
}

export enum ScheduleType {
  Sequential = 'SEQUENTIAL',
  Open = 'OPEN'
}

export interface Schedule {
  id?: number
  title: string
  visitsCount: number
  participantsCount: number
  type: ScheduleType
  visits?: ScheduleVisit[]
  isPartiallyBooked?: boolean
  isPublished?: boolean
  study: {
    title: string
    centerName: string
  }
}

export interface RemoteSchedule {
  id?: number
  title: string
  visits_count: number
  participants_count: number
  booking_type: ScheduleType
  visits?: RemoteScheduleVisit[]
  is_published?: boolean
  study?: {
    title: string
    center_name: string
  }
}

const parseRemoteScheduleVisitAvailability: (
  availability: RemoteScheduleVisitAvailability
) => ScheduleVisitAvailability = (availability: RemoteScheduleVisitAvailability) => ({
  date: availability.date && dayjs(availability.date),
  startTime: availability.start_time?.substr(0, 5),
  endTime: availability.end_time?.substr(0, 5)
})

const parseRemoteScheduleVisit: (visit: RemoteScheduleVisit) => ScheduleVisit = (visit: RemoteScheduleVisit) => ({
  id: visit.id,
  number: visit.order,
  title: visit.title,
  duration: visit.appointment_duration,
  centerId: visit.center_id && String(visit.center_id),
  centerName: visit.center_name,
  window: visit.booking_window,
  interval: visit.slots_interval,
  capacity: visit.slot_capacity,
  coordinatorId: visit.coordinator_id,
  payment: visit.payment_amount,
  timezone: visit.timezone,
  bookingsCount: visit.bookings_count,
  slots: visit.slots?.map(parseRemoteScheduleSlot),
  availability: visit.availabilities?.map(parseRemoteScheduleVisitAvailability),
  scheduleName: visit.schedule_name
})

export const parseRemoteSchedule: (schedule: RemoteSchedule) => Schedule = (schedule: RemoteSchedule) => ({
  id: schedule.id,
  title: schedule.title,
  visitsCount: schedule.visits_count,
  participantsCount: schedule.participants_count,
  type: schedule.booking_type || ScheduleType.Open,
  visits: schedule.visits?.map(parseRemoteScheduleVisit),
  isPublished: schedule.is_published,
  study: {
    title: schedule.study?.title,
    centerName: schedule.study?.center_name
  }
})

const prepareScheduleForSave: (schedule: Schedule) => RemoteSchedule = (schedule: Schedule) => ({
  title: schedule.title,
  visits_count: schedule.visitsCount,
  participants_count: schedule.participantsCount,
  booking_type: schedule.type,
  is_published: schedule.isPublished,
  visits: schedule.visits?.map(visit => ({
    id: visit.id,
    order: visit.number,
    title: visit.title,
    appointment_duration: visit.duration,
    center_id: visit.centerId && Number(visit.centerId),
    booking_window: visit.window,
    slots_interval: visit.interval || undefined,
    slot_capacity: visit.capacity,
    coordinator_id: visit.coordinatorId,
    payment_amount: visit.payment,
    timezone: visit.timezone,
    availabilities: visit.availability?.map(availability => ({
      date: availability.date?.format('YYYY-MM-DD'),
      start_time: availability.startTime,
      end_time: availability.endTime
    }))
  }))
})

type ScheduleOptions = Schedule & { studyId: string }

interface ScheduleResponseHandlers {
  onSuccess?: (schedule: Schedule) => void
  onRequestError?: (code: number) => void
  onOverlappingError?: () => void
}

export const createSchedule = (
  { studyId, ...schedule }: ScheduleOptions,
  responseHandlers: ScheduleResponseHandlers
) => {
  const { req, cancel } = fetchApi.post<RemoteSchedule>(
    `recruitment/studies/${studyId}/schedules`,
    prepareScheduleForSave(schedule)
  )

  req.then(({ error, status, body }) => {
    if (error) {
      createErrorsHandlers<ScheduleResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess(parseRemoteSchedule(body))
    }
  })

  return cancel
}

export const updateSchedule = (
  { studyId, ...schedule }: ScheduleOptions,
  responseHandlers: ScheduleResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch<RemoteSchedule>(
    `recruitment/studies/${studyId}/schedules/${schedule.id}`,
    prepareScheduleForSave(schedule)
  )

  req.then(({ error, status, body }) => {
    if (error) {
      createErrorsHandlers<ScheduleResponseHandlers>(
        { [BackendError.CALENDAR_AVAILABILITY_OVERLAP]: 'onOverlappingError' },
        error,
        responseHandlers,
        status
      )
    } else {
      responseHandlers.onSuccess(parseRemoteSchedule(body))
    }
  })

  return cancel
}

interface FetchSchedulesResponseHandlers {
  onSuccess?: (schedules: Schedule[]) => void
  onRequestError?: (code: number) => void
}

export const fetchSchedules = ({ studyId }: { studyId: string }, responseHandlers?: FetchSchedulesResponseHandlers) => {
  const { req, cancel } = fetchApi.get<RemoteSchedule[]>(`recruitment/studies/${studyId}/schedules`)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSchedulesResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.map(parseRemoteSchedule))
    }
  })

  return cancel
}

interface FetchAllVisitsOptions {
  options: {
    hasPaymentOrder?: boolean
    search?: string
  }
}

interface FetchAllVisitsResponse {
  results: RemoteScheduleVisit[]
  count: number
}

interface FetchAllVisitsResponseHandlers {
  onSuccess?: ({ visits, countAll }: { visits: ScheduleVisit[]; countAll: number }) => void
  onRequestError?: (code: number) => void
}

export const fetchAllVisits = (
  { options }: FetchAllVisitsOptions,
  responseHandlers?: FetchAllVisitsResponseHandlers
) => {
  const query = {
    has_payment_order: options?.hasPaymentOrder ? 1 : 0,
    search: options?.search?.toLowerCase()
  }
  const { req, cancel } = fetchApi.get<FetchAllVisitsResponse>('calendar/schedules/visits', query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchAllVisitsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        visits: body.results.map(visit => parseRemoteScheduleVisit(visit)),
        countAll: body.count
      })
    }
  })

  return cancel
}
