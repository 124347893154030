/* eslint-disable camelcase */
import { QuestionBase, QuestionType, RemoteQuestionBase } from './base'

export interface NumberQuestion extends QuestionBase {
  type: QuestionType.Number
  config: {
    unit?: string
    placeholder?: string
    minValue?: number
    maxValue?: number
    maxDecimal?: number
  }
}

export interface RemoteNumberQuestion extends RemoteQuestionBase {
  type: QuestionType.Number
  config: {
    unit?: string
    placeholder?: string
    max_value?: number
    min_value?: number
    max_decimal?: number
  }
}

export const prepareNumberQuestionConfigForSave = (config: NumberQuestion['config']) => {
  return {
    unit: config.unit,
    placeholder: config.placeholder,
    max_value: config.maxValue,
    min_value: config.minValue,
    max_decimal: config.maxDecimal
  }
}

export const parseNumberTypeRemoteQuestionConfig = (config: RemoteNumberQuestion['config']) => ({
  unit: config.unit,
  placeholder: config.placeholder,
  minValue: config.min_value,
  maxValue: config.max_value,
  maxDecimal: config.max_decimal
})
