/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { fetchApi, mapData, mapFetchedData } from '../fetchApi'

const complianceDataUrl = 'customer_settings/general_settings'

interface RemoteComplianceData {
  subject_account_deletion_emails: string[]
  subject_retention_months: number
  consent_extension_days: number
  pending_subject_retention_days: number
  subject_set_inactive_months: number
  inactive_subject_retention_days: number
}

export interface ComplianceData {
  subjectAccountDeletionEmails: string[]
  subjectRetentionMonths: number
  consentExtensionDay: number
  pendingSubjectRetentionDays: number
  subjectSetInactiveMonths: number
  inactiveSubjectRetentionDays: number
}

const complianceDataMapping = {
  subjectAccountDeletionEmails: 'subject_account_deletion_emails',
  subjectRetentionMonths: 'subject_retention_months',
  consentExtensionDay: 'consent_extension_days',
  pendingSubjectRetentionDays: 'pending_subject_retention_days',
  subjectSetInactiveMonths: 'subject_set_inactive_months',
  inactiveSubjectRetentionDays: 'inactive_subject_retention_days'
}

interface FetchComplianceDataResponseHandlers {
  onSuccess?: (data: ComplianceData) => void
  onRequestError?: (code: number) => void
}

export const fetchComplianceData = (responseHandlers?: FetchComplianceDataResponseHandlers) => {
  const { req, cancel } = fetchApi.get<RemoteComplianceData>(complianceDataUrl)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchComplianceDataResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(mapFetchedData<ComplianceData>(complianceDataMapping, body))
    }
  })

  return cancel
}

interface UpdateComplianceDataResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const updateComplianceData = (
  options: ComplianceData,
  responseHandlers?: UpdateComplianceDataResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch(complianceDataUrl, mapData<ComplianceData>(complianceDataMapping, options))

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<UpdateComplianceDataResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
