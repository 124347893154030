/* eslint-disable camelcase */
import dayjs from 'dayjs'

import { humanizeDuration } from '../../utils'
import { MissingReasonType } from '../ecrf'
import { QueryStatus } from '../forms'
import { AuditTrailType } from './AuditTrail'

export enum StudyAuditTrailsEventType {
  Study = 'STUDY',
  Ecrf = 'ECRF',
  EcrfData = 'ECRF_DATA',
  Inclusion = 'INCLUSION',
  Settings = 'SETTINGS',
  StudyUser = 'STUDY_USER',
  Query = 'QUERY',
  EcrfFiles = 'ECRF_FILES',
  Document = 'DOCUMENT',
  Subject = 'SUBJECT',
  EproSurvey = 'EPRO_SURVEY',
  EproRecords = 'EPRO_RECORDS',
  EproInvitation = 'EPRO_INVITATION',
  EproFiles = 'EPRO_FILES',
  EconsentSurvey = 'ECONSENT_SURVEY',
  EconsentFiles = 'ECONSENT_FILES',
  EconsentInvitation = 'ECONSENT_INVITATION',
  VideoCall = 'VIDEO_CALL',
  Randomisation = 'RANDOMISATION',
  StudyAuditTrail = 'STUDY_AUDIT_TRAIL',
  Automation = 'AUTOMATION',
  Translation = 'TRANSLATION',
  DataAnalysis = 'DATA_ANALYSIS',
  QrCode = 'QR_CODE'
}

export enum StudyAuditTrailsActionType {
  Opened = 'OPEN',
  Created = 'CREATED',
  Updated = 'UPDATED',
  Added = 'ADDED',
  Removed = 'REMOVED',
  Deleted = 'DELETED',
  Archived = 'ARCHIVED',
  Exported = 'EXPORTED',
  Uploaded = 'UPLOADED',
  Signed = 'SIGNED',
  Unsigned = 'UNSIGNED',
  Excluded = 'EXCLUDED',
  Unexcluded = 'UNEXCLUDED',
  Downloaded = 'DOWNLOADED',
  Replied = 'REPLIED',
  Sent = 'SENT',
  Answered = 'ANSWERED',
  AnswerUpdated = 'ANSWER_UPDATED',
  AnswerDeleted = 'ANSWER_DELETED',
  Started = 'STARTED',
  Ended = 'ENDED',
  Joined = 'JOINED',
  Left = 'LEFT',
  Reviewed = 'REVIEWED',
  Unreviewed = 'UNREVIEWED',
  Rejected = 'REJECTED',
  ViewedExported = 'VIEWED_EXPORTED',
  Countersigned = 'COUNTERSIGNED',
  Randomised = 'RANDOMISED',
  Scheduled = 'SCHEDULED',
  Blocked = 'BLOCKED',
  Unblocked = 'UNBLOCKED',
  Locked = 'LOCKED',
  Unlocked = 'UNLOCKED',
  Sdv = 'SDV',
  SdvDeleted = 'SDV_DELETED'
}

export interface StudyAuditTrail {
  trailType: AuditTrailType.Study
  id: string
  studyId: string
  eventType: StudyAuditTrailsEventType
  actionType: StudyAuditTrailsActionType
  timestamp: Date
  datacaptSubjectId: string
  userName: string
  userEmail: string
  section: string
  subsection: string
  question: string
  variable: string
  newValue: string
  oldValue: string
  reason: string
  missingReasonType: MissingReasonType
  repeatedMeasuresTitle: string
  measureId: string
  newUserName: string
  newUserEmail: string
  removedUserName: string
  removedUserEmail: string
  replyId: string
  message: string
  newMessage: string
  oldMessage: string
  newStatus: QueryStatus
  filename: string
  sectionName: string
  signatureId: string
  center: string
  randomisationId: string
  randomisationGroup: string
  documentId: string
  subjectId: string
  email: string
  firstName: string
  lastName: string
  phone: string
  eproName: string
  surveyName: string
  subject: string
  subjects: string[]
  video: string
  duration: string
  subsectionName: string
  updatedRoleName: string
  updatedUserName: string
  fileFormat: string
}

export interface RemoteStudyAuditTrail {
  id: number
  study: number
  event_type: StudyAuditTrailsEventType
  action_type: StudyAuditTrailsActionType
  timestamp: string
  subject_id_field: string
  user_name: string
  user_email: string
  additional_data: {
    section: string
    subsection: string
    question: string
    variable: string
    new_value: string
    old_value: string
    reason: string
    missing_reason_type: MissingReasonType
    measure_id: string
    rm_title: string
    newUserName: string
    newUserEmail: string
    removedUserName: string
    removedUserEmail: string
    new_message: string
    old_message: string
    new_status: QueryStatus
    query_reply: string
    message: string
    filename: string
    section_name: string
    signature_id: string
    randomisation_id: string
    randomisation_group: string
    subsection_name: string
    study_center: string
    file_name: string
    document_id: string
    subject_id: string
    first_name: string
    last_name: string
    phone: string
    email: string
    epro_name: string
    survey_name: string
    subjects: string[]
    subject: string
    video: string
    duration: number
    RoleName: string
    UserName: string
    export_file_format: string
  }
}

export const parseRemoteStudyTrail = (trail: RemoteStudyAuditTrail): StudyAuditTrail => ({
  trailType: AuditTrailType.Study,
  id: trail.id && String(trail.id),
  studyId: trail.study && String(trail.study),
  timestamp: new Date(trail.timestamp),
  datacaptSubjectId: trail.subject_id_field && String(trail.subject_id_field),
  userName: trail.user_name,
  userEmail: trail.user_email,
  eventType: trail.event_type,
  actionType: trail.action_type,
  section: trail.additional_data?.section,
  subsection: trail.additional_data?.subsection,
  question: trail.additional_data?.question,
  variable: trail.additional_data?.variable,
  newValue: trail.additional_data?.new_value,
  repeatedMeasuresTitle: trail.additional_data?.rm_title,
  measureId: trail.additional_data?.measure_id && String(trail.additional_data?.measure_id),
  reason: trail.additional_data?.reason,
  missingReasonType: trail.additional_data?.missing_reason_type,
  oldValue: trail.additional_data?.old_value,
  sectionName: trail.additional_data?.section_name,
  signatureId: trail.additional_data?.signature_id,
  subsectionName: trail.additional_data?.subsection_name,
  randomisationGroup: trail.additional_data?.randomisation_group,
  randomisationId: trail.additional_data?.randomisation_id,
  newUserName: trail.additional_data?.newUserName,
  newUserEmail: trail.additional_data?.newUserEmail,
  removedUserName: trail.additional_data?.removedUserName,
  removedUserEmail: trail.additional_data?.removedUserEmail,
  updatedUserName: trail.additional_data?.UserName,
  updatedRoleName: trail.additional_data?.RoleName,
  message: trail.additional_data?.new_message,
  newStatus: trail.additional_data?.new_status,
  newMessage: trail.additional_data?.new_message,
  oldMessage: trail.additional_data?.old_message,
  replyId: trail.additional_data?.measure_id && String(trail.additional_data?.query_reply),
  filename: trail.additional_data?.filename,
  center: trail.additional_data?.study_center,
  documentId: trail.additional_data?.document_id,
  subjectId: trail.additional_data?.subject_id,
  email: trail.additional_data?.email,
  firstName: trail.additional_data?.first_name,
  lastName: trail.additional_data?.last_name,
  phone: trail.additional_data?.phone,
  eproName: trail.additional_data?.epro_name,
  surveyName: trail.additional_data?.survey_name,
  subject: trail.additional_data?.subject,
  subjects: trail.additional_data?.subjects,
  video: trail.additional_data?.video,
  duration:
    trail.additional_data?.duration && humanizeDuration(dayjs.duration(trail.additional_data?.duration, 'seconds')),
  fileFormat: trail.additional_data?.export_file_format
})
