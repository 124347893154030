module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Datacapt","short_name":"Datacapt","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6e8ff4a2ee8f4a73ff20665b2c442b67"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/app/src/intl","languages":["en","fr","it","pl","pt","de","es","ja","zh"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
