/* eslint-disable camelcase */
import dayjs, { Dayjs } from 'dayjs'

import { createErrorsHandlers, prepareSorter } from '../../utils'
import { BackendError } from '../RequestError'
import { SorterOrder } from '../SorterOrder'
import { fetchApi } from '../fetchApi'
import { PaymentStatus } from '../payments'
import {
  DisqualifiedCause,
  ParticipantStatus,
  RemoteParticipantStatus,
  participantStatusForSaveMapping,
  participantStatusMapping
} from './participants'

export enum BookedVisitStatus {
  Scheduled = 'SCHEDULED',
  Completed = 'COMPLETED',
  NotDone = 'NOT_DONE',
  Cancelled = 'CANCELLED'
}

export interface RemoteScheduleBookedVisit {
  id: number
  date: string
  start_time: string
  end_time: string
  visit_name: string
  visit_id: number
  schedule_name: string
  status: BookedVisitStatus
  cause: string
  participant_name: string
  participant_id: string
  participant_thumbnail?: string
  center_name: string
  payment_amount: number
  payment_amount_default: number
  payment_currency: string
  payment_status: PaymentStatus
  payment_date: string
  payment_created_at: string
  application_status: RemoteParticipantStatus
}

export interface ScheduleBookedVisit {
  id: string
  date: Dayjs
  startTime: string
  endTime: string
  visitName: string
  visitId: number
  scheduleName: string
  status: BookedVisitStatus
  cause: string
  participantName: string
  participantId: string
  participantPhotoThumbnail?: string
  centerName: string
  paymentAmount: number
  paymentCurrency: string
  paymentStatus: PaymentStatus
  paymentDate: Dayjs
  paymentCreatedAt: Dayjs
  applicationStatus: ParticipantStatus
  theoreticalAmount: number
}

export const parseRemoteScheduleBookedVisit = (remote: RemoteScheduleBookedVisit): ScheduleBookedVisit => ({
  id: String(remote.id),
  date: remote.date && dayjs(remote.date),
  startTime: remote.start_time?.slice(0, 5),
  endTime: remote.end_time?.slice(0, 5),
  visitName: remote.visit_name,
  visitId: remote.visit_id,
  scheduleName: remote.schedule_name,
  status: remote.status,
  cause: remote.cause,
  participantName: remote.participant_name,
  participantId: remote.participant_id,
  participantPhotoThumbnail: remote.participant_thumbnail,
  centerName: remote.center_name,
  paymentAmount: remote.payment_amount == null ? remote.payment_amount_default : remote.payment_amount,
  paymentCurrency: remote.payment_currency,
  paymentStatus: remote.payment_status,
  paymentDate: remote.payment_date && dayjs(remote.payment_date),
  paymentCreatedAt: remote.payment_created_at && dayjs(remote.payment_created_at),
  applicationStatus: participantStatusMapping[remote.application_status],
  theoreticalAmount: remote.payment_amount_default
})

export interface BookedVisitsSorter {
  field: keyof ScheduleBookedVisit
  order: SorterOrder
}

interface BookedVisitsSearchOptions {
  search?: string
  filters?: {
    status?: string[]
    visit?: string[]
    location?: string[]
    date_after?: string
    date_before?: string
  }
}

const parseBookedVisitsSearchOptions = (options: BookedVisitsSearchOptions) => ({
  search: options?.search?.toLowerCase(),
  status: options?.filters?.status?.length ? options.filters.status.join(',') : undefined,
  visit: options?.filters?.visit?.length ? options.filters.visit.join(',') : undefined,
  location: options?.filters?.location?.length ? options.filters.location.join(',') : undefined,
  date_after: options?.filters?.date_after,
  date_before: options?.filters?.date_before
})

interface FetchBookedVisitsOptions {
  studyId: string
  options: BookedVisitsSearchOptions & {
    sorter: BookedVisitsSorter
    limit: number
    offset: number
  }
}

export type BookedVisitStatusCount = Record<BookedVisitStatus, number>

interface FetchBookedVisitsResponseHandlers {
  onSuccess?: ({
    bookedVisits,
    bookedVisitDates,
    bookedVisitStatusCount,
    countAll
  }: {
    bookedVisits: ScheduleBookedVisit[]
    bookedVisitDates: Dayjs[]
    bookedVisitStatusCount: BookedVisitStatusCount
    countAll: number
  }) => void
  onRequestError?: (code: number) => void
}

interface FetchBookedVisitsResponse {
  results: RemoteScheduleBookedVisit[]
  dates: string[]
  count: number
  count_scheduled: number
  count_completed: number
  count_not_done: number
  count_cancelled: number
}

export interface ScheduleBookedVisitsSorter {
  field: keyof ScheduleBookedVisit
  order: SorterOrder
}

const sorterFields = {
  date: ['date', 'start_time', 'visit_name', 'participant_name'],
  visitName: ['visit_name'],
  participantName: ['participant_name'],
  centerName: ['center_name'],
  paymentAmount: ['payment_amount'],
  status: ['status']
}

export const fetchBookedVisits = (
  { studyId, options }: FetchBookedVisitsOptions,
  responseHandlers?: FetchBookedVisitsResponseHandlers
) => {
  const sorter = prepareSorter<typeof sorterFields, ScheduleBookedVisitsSorter>(sorterFields, options.sorter)
  const query = {
    ordering: sorter,
    limit: options?.limit,
    offset: options?.offset,
    ...parseBookedVisitsSearchOptions(options)
  }
  const { req, cancel } = fetchApi.get<FetchBookedVisitsResponse>(`recruitment/studies/${studyId}/visits/list`, query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchBookedVisitsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        bookedVisits: body.results.map(visit => parseRemoteScheduleBookedVisit(visit)),
        bookedVisitDates: body.dates.map(date => date && dayjs(date)),
        bookedVisitStatusCount: {
          [BookedVisitStatus.Scheduled]: body.count_scheduled,
          [BookedVisitStatus.Completed]: body.count_completed,
          [BookedVisitStatus.NotDone]: body.count_not_done,
          [BookedVisitStatus.Cancelled]: body.count_cancelled
        },
        countAll: body.count
      })
    }
  })

  return cancel
}

interface FetchBookedVisitsCausesResponseHandlers {
  onSuccess?: (causes: string[]) => void
  onRequestError?: (code: number) => void
}

export const fetchBookedVisitsCauses = (responseHandlers?: FetchBookedVisitsCausesResponseHandlers) => {
  const { req, cancel } = fetchApi.get<string[]>('calendar/visits/causes')

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchBookedVisitsCausesResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}

interface FetchAllSelectedBookedVisitsOptions {
  studyId: string
  options: BookedVisitsSearchOptions
}

interface FetchAllSelectedBookedVisitsResponseHandlers {
  onSuccess?: (bookedVisits: ScheduleBookedVisit[]) => void
  onRequestError?: (code: number) => void
}
export const fetchAllSelectedBookedVisits = (
  { studyId, options }: FetchAllSelectedBookedVisitsOptions,
  responseHandlers?: FetchAllSelectedBookedVisitsResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteScheduleBookedVisit[]>(
    `recruitment/studies/${studyId}/visits/list/full`,
    parseBookedVisitsSearchOptions(options)
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchAllSelectedBookedVisitsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.map(parseRemoteScheduleBookedVisit))
    }
  })

  return cancel
}

export interface VisitConfirmationOptions {
  visitStatus: BookedVisitStatus
  visitStatusCause: string
  applicationStatus: ParticipantStatus
  paymentAmount: number
  paymentComment: string
  disqualifiedCause: DisqualifiedCause
}

interface ConfirmVisitsRequestOptions {
  studyId: string
  slotSubjectIds: number[]
  confirmationOptions: VisitConfirmationOptions
}

interface ConfirmVisitsResponseHandlers {
  onSuccess?: (bookedVisits: ScheduleBookedVisit[]) => void
  onNoCurrency?: () => void
  onRequestError?: (code: number) => void
}
export const confirmVisits = (
  { studyId, slotSubjectIds, confirmationOptions }: ConfirmVisitsRequestOptions,
  responseHandlers?: ConfirmVisitsResponseHandlers
) => {
  const { req, cancel } = fetchApi.post<RemoteScheduleBookedVisit[]>(`recruitment/studies/${studyId}/visits/confirm`, {
    visit_status: confirmationOptions.visitStatus,
    cause: confirmationOptions.visitStatusCause ? confirmationOptions.visitStatusCause : undefined,
    disqualified_cause:
      confirmationOptions.applicationStatus === ParticipantStatus.Disqualified
        ? confirmationOptions.disqualifiedCause
        : undefined,
    application_status: participantStatusForSaveMapping[confirmationOptions.applicationStatus],
    payment_amount: confirmationOptions.paymentAmount || undefined,
    payment_comment: confirmationOptions.paymentComment,
    slot_subject_ids: slotSubjectIds
  })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<ConfirmVisitsResponseHandlers>(
        {
          [BackendError.PAYMENT_MISSING_CURRENCY]: 'onNoCurrency'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.map(parseRemoteScheduleBookedVisit))
    }
  })

  return cancel
}
