/* eslint-disable camelcase */
import { fetchOpenEndpointFilesUrls } from '../file'
import {
  BaseRemoteQuestionAnswer,
  FileAnswer,
  FulfillmentQuestionAnswer,
  RemoteFileAnswer,
  SurveyAnswer,
  SurveyRemoteQuestionAnswer,
  deleteAnswer,
  deleteFileAnswer,
  downloadFileAnswer,
  parseRemoteFileAnswer,
  parseRemoteQuestionAnswer,
  parseRemoteSurveyAnswer,
  saveAnswer,
  saveFileAnswer
} from '../forms'
import { RecordStatus, SectionAdvancement } from '../sectionAdvancement'

export const testAnswerMapping = {
  testId: 'test_id',
  productId: 'product_id'
}

interface TestAnswerFields {
  testId: string
  projectId: string
  productId?: string
}

export interface TestRemoteQuestionAnswer extends BaseRemoteQuestionAnswer {
  test_id: number
}

export interface TestQuestionAnswer extends FulfillmentQuestionAnswer {
  testId: string
}

const getTestAnswerPath = (data: TestAnswerFields) => `side_by_side/projects/${data.projectId}/answer/${data.testId}`

export const parseTestRemoteQuestionAnswer = (answer: TestRemoteQuestionAnswer) => ({
  ...parseRemoteQuestionAnswer(answer),
  testId: String(answer.test_id),
  blockId: String(answer.question)
})

export const saveTestAnswer = saveAnswer<TestAnswerFields, TestRemoteQuestionAnswer, TestQuestionAnswer>(
  getTestAnswerPath,
  testAnswerMapping,
  parseTestRemoteQuestionAnswer
)

export const deleteTestAnswer = deleteAnswer<TestAnswerFields>(getTestAnswerPath, testAnswerMapping)

export interface TestRecord {
  id: string
  progress: number
  status: RecordStatus
  sectionsAdvancement?: { [sectionId: string]: SectionAdvancement }
}

const getFilePath = () => `uploaded_files/side_by_side/answer`

export const saveTestFileAnswer = saveFileAnswer<TestAnswerFields, RemoteFileAnswer, FileAnswer>(
  getFilePath,
  testAnswerMapping,
  parseRemoteFileAnswer
)

export const saveTestSurveyFileAnswer = saveFileAnswer<TestAnswerFields, SurveyRemoteQuestionAnswer, SurveyAnswer>(
  getFilePath,
  testAnswerMapping,
  parseRemoteSurveyAnswer
)

export const deleteTestFileAnswer = deleteFileAnswer<TestAnswerFields>(getFilePath, testAnswerMapping)

export const fetchTestFilesUrls = fetchOpenEndpointFilesUrls('uploaded_files/side_by_side/fulfillment')

export const downloadTestFileAnswer = downloadFileAnswer(getFilePath())
