/* eslint-disable camelcase */
import { createErrorsHandlers, renderAnswer } from '../../utils'
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'
import { DateFormat, OptionsRenderTypes } from '../studies'
import {
  RecordVariable,
  RecordVariableRemote,
  VariableAnswerTypes,
  parseRecordVariable,
  parseRecordVariableConfig
} from '../variables'
import { AnalyticsTabDetails, AnalyticsTabType } from './tabs'

interface TableResultRowItemRemote {
  question_id: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
}

interface TableResultRowRemote {
  subject_id: string
  randomisation_id: string
  answers: TableResultRowItemRemote[]
}

export interface TableResultRowItemClient {
  subjectId: string
  randomisationId: string
  [key: number]: VariableAnswerTypes
}

export interface FetchSingleTablePayload {
  studyId: string
  tableId: string
  options: { limit: number; offset: number }
  dateFormat: DateFormat
}

interface UpdateTableDataPayload {
  studyId: string
  table: AnalyticsTabDetails
  variables: RecordVariable[]
}

interface FetchVariablesDataPayload {
  studyId: string
  searchPhrase?: string
  limit?: boolean
}

export interface AnalyticsTable {
  id: string
  name: string
  variables: RecordVariable[]
}

interface FetchSingleTableResponse {
  id: string
  name: string
  type: AnalyticsTabType
  date_added: string
  all_count: number
  config: {
    variables: RecordVariableRemote[]
    options_render_type: OptionsRenderTypes
  }
  results: TableResultRowRemote[]
}

interface FetchSingleTableResponseHandlers {
  onSuccess?: (response: AnalyticsTabDetails) => void
  onRequestError?: (code: number) => void
  onTableNotExists?: () => void
}

interface UpdateTableDataResponseHandlers {
  onSuccess?: (response: AnalyticsTabDetails) => void
  onRequestError?: (code: number) => void
  onVariableNotExists?: () => void
}

interface FetchVariablesDataResponseHandlers {
  onSuccess?: (response: RecordVariable[]) => void
  onRequestError?: (code: number) => void
}

const parseSingleTableForClient = (
  response: FetchSingleTableResponse,
  dateFormat: DateFormat
): AnalyticsTabDetails => ({
  ...response,
  config: {
    variables: response.config.variables.map(parseRecordVariable),
    optionsRenderType: response.config.options_render_type
  },
  dateAdded: response.date_added,
  allCount: response.all_count,
  type: response.type,
  results: response.results.reduce((acc, result) => {
    let parsedResult: TableResultRowItemClient = {
      subjectId: result.subject_id,
      randomisationId: result.randomisation_id
    }

    result.answers.forEach((item: TableResultRowItemRemote) => {
      const variable = response.config.variables.find(v => v.id === item.question_id)
      const question = {
        type: variable.type,
        config: parseRecordVariableConfig(variable.config)
      }
      const value = renderAnswer(
        question as RecordVariable,
        item.value,
        dateFormat,
        response.config.options_render_type
      )

      parsedResult = {
        ...parsedResult,
        [item.question_id]: value
      }
    })

    return acc.concat(parsedResult)
  }, [])
})

export const fetchSingleTable = (
  { studyId, tableId, options, dateFormat }: FetchSingleTablePayload,
  responseHandlers?: FetchSingleTableResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<FetchSingleTableResponse>(`analytics/${tableId}`, options, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSingleTableResponseHandlers>(
        {
          [BackendError.ANALYTICS_ENTITY_NOT_EXIST]: 'onTableNotExists'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseSingleTableForClient(body, dateFormat))
    }
  })

  return cancel
}

export const updateTableData = (
  { studyId, table, variables }: UpdateTableDataPayload,
  responseHandlers?: UpdateTableDataResponseHandlers
) => {
  const query = {
    name: table.name,
    study: studyId,
    config: {
      options_render_type: table.config.optionsRenderType,
      variables
    },
    type: table.type
  }
  const { req, cancel } = fetchApi.patch<AnalyticsTabDetails>(`analytics/${table.id}`, query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<UpdateTableDataResponseHandlers>(
        {
          [BackendError.ECRF_QUESTION_VARIABLE_NOT_EXISTS]: 'onVariableNotExists'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}

export const fetchVariablesForTable = (
  { studyId, searchPhrase = '', limit = false }: FetchVariablesDataPayload,
  responseHandlers: FetchVariablesDataResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RecordVariable[]>(
    'analytics/variables',
    { filter_lookup: searchPhrase, limit },
    { studyId }
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchVariablesDataResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.reverse())
    }
  })

  return cancel
}
