/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'

export interface LockRecordOptions {
  sectionId: string
  subsectionId: string
  unlock: boolean
}

export interface LockEcrfOptions {
  studyId: string
  inclusionIds: string[]
  sectionId?: string
  subsectionId?: string
  unlock: boolean
  search?: string
  status?: string[]
  center?: string[]
  investigator?: string[]
}

export interface LockRecordResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onQueryExists?: () => void
  onError?: () => void
}

export const lockEcrf = (
  { studyId, inclusionIds, sectionId, subsectionId, unlock, search, status, center, investigator }: LockEcrfOptions,
  responseHandlers?: LockRecordResponseHandlers
) => {
  const query = {
    record_ids: inclusionIds,
    section: sectionId || undefined,
    subsection: subsectionId || undefined,
    search: search || undefined,
    status: status?.length ? status.join(',') : undefined,
    study_center_ids: center,
    investigator_id: investigator?.length ? investigator.join(',') : undefined
  }
  const { req, cancel } = unlock
    ? fetchApi.delete('inclusions/lock', query, { studyId })
    : fetchApi.post('inclusions/lock', query, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<LockRecordResponseHandlers>(
        {
          [BackendError.QUERY_ALREADY_EXISTS]: 'onQueryExists'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
