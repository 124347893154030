/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { Feature } from '../Feature'
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'

export enum AnalyticsChartType {
  Pie = 'PIE',
  Bar = 'BAR'
}

export const chartColors = ['#1bcfa9', '#ffca54', '#ff6a5b', '#49C3FF', '#1bcfa9', '#fe63b4', '#a261fa']

interface ChartResult extends Record<string, number> {
  value: number
}

interface RemoteAnalyticsChart {
  id: number
  variable: string
  results: ChartResult[]
  type: AnalyticsChartType
  document_type: Feature
  document_id: string
  variable_by?: string
}

export interface AnalyticsChart {
  id: number
  variable: string
  results: ChartResult[]
  type: AnalyticsChartType
  documentType: Feature
  documentId: string
  variableBy?: string
}

const parseRemoteChart = (chart: RemoteAnalyticsChart): AnalyticsChart => ({
  id: chart.id,
  variable: chart.variable,
  results: chart.results,
  type: chart.type,
  documentType: chart.document_type,
  documentId: chart.document_id,
  variableBy: chart.variable_by
})

interface FetchAnalyticsChartsResponseHandlers {
  onSuccess?: (response: AnalyticsChart[]) => void
  onRequestError?: (code: number) => void
}

export const fetchAnalyticsCharts = (
  { studyId, centers, tabId }: { studyId: string; centers: string; tabId: string },
  responseHandlers?: FetchAnalyticsChartsResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteAnalyticsChart[]>(
    `analytics/${tabId}/charts`,
    { study_center_id: centers },
    { studyId }
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchAnalyticsChartsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.map(parseRemoteChart))
    }
  })

  return cancel
}

interface DeleteAnalyticsCharPayload {
  studyId: string
  chartId: number
  tabId: string
}

interface DeleteAnalyticsCharResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const deleteAnalyticsChart = (
  { studyId, chartId, tabId }: DeleteAnalyticsCharPayload,
  responseHandlers?: DeleteAnalyticsCharResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete(`analytics/${tabId}/charts/${chartId}`, {}, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<DeleteAnalyticsCharResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface CreateAnalyticsChartPayload {
  studyId: string
  variable: string
  type: AnalyticsChartType
  tabId: string
}

interface CreateAnalyticsChartResponseHandlers {
  onSuccess?: () => void
  onChartLimit?: () => void
  onRequestError?: (code: number) => void
}

export const createAnalyticsChart = (
  { studyId, variable, type, tabId }: CreateAnalyticsChartPayload,
  responseHandlers?: CreateAnalyticsChartResponseHandlers
) => {
  const { req, cancel } = fetchApi.post(
    `analytics/${tabId}/charts`,
    { question: variable, study: studyId, type },
    { studyId }
  )

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<CreateAnalyticsChartResponseHandlers>(
        {
          [BackendError.ANALYTICS_CHART_LIMIT_REACHED]: 'onChartLimit'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface AddChartQuestionByPayload {
  studyId: string
  tabId: string
  chartId: number
  questionBy: string
}

interface AddChartQuestionByResponseHandlers {
  onSuccess?: () => void
  onVariableNotExists?: () => void
  onRequestError?: (code: number) => void
}

export const addByToChart = (
  { studyId, chartId, questionBy, tabId }: AddChartQuestionByPayload,
  responseHandlers?: AddChartQuestionByResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch(
    `analytics/${tabId}/charts/${chartId}`,
    { question_by: questionBy },
    { studyId }
  )

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<AddChartQuestionByResponseHandlers>(
        {
          [BackendError.ECRF_QUESTION_VARIABLE_NOT_EXISTS]: 'onVariableNotExists'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
