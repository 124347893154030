/* eslint-disable camelcase */
import dayjs, { Dayjs } from 'dayjs'

import { createErrorsHandlers } from '../../utils'
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'
import { BookedVisitStatus } from './visits'

interface RemoteScheduleSlotSubject {
  datacapt_id: string
  name: string
  thumbnail?: string
  status: BookedVisitStatus
}

export interface ScheduleSlotSubject {
  datacaptId: string
  name: string
  thumbnail?: string
  status: BookedVisitStatus
}

export interface ScheduleSlot {
  id?: number
  date: Dayjs
  startTime: string
  subjects?: ScheduleSlotSubject[]
  isAvailable?: boolean
}

export interface RemoteScheduleSlot {
  id: number
  date: string
  start_time: string
  subjects?: RemoteScheduleSlotSubject[]
  is_available?: boolean
}

export const parseRemoteScheduleSlot: (slot: RemoteScheduleSlot) => ScheduleSlot = (slot: RemoteScheduleSlot) => ({
  id: slot.id,
  date: slot.date && dayjs(slot.date),
  startTime: slot.start_time?.substr(0, 5),
  subjects:
    slot.subjects?.map(subject => ({
      datacaptId: subject.datacapt_id,
      name: subject.name,
      thumbnail: subject.thumbnail,
      status: subject.status
    })) || [],
  isAvailable: slot.is_available
})

interface FetchScheduleSlotsResponseHandlers {
  onSuccess?: (schedules: ScheduleSlot[]) => void
  onRequestError?: (code: number) => void
}

interface FetchScheduleSlotsOptions {
  studyId: string
  scheduleId: number
  visitId: number
}

const getSlotUrl = ({ studyId, scheduleId, visitId }: FetchScheduleSlotsOptions) =>
  `recruitment/studies/${studyId}/schedules/${scheduleId}/visits/${visitId}/slots`

export const fetchScheduleSlots = (
  options: FetchScheduleSlotsOptions,
  responseHandlers?: FetchScheduleSlotsResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteScheduleSlot[]>(getSlotUrl(options))

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchScheduleSlotsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.map(parseRemoteScheduleSlot))
    }
  })

  return cancel
}

interface SubjectToSlotOptions {
  studyId: string
  scheduleId: number
  visitId: number
  slotId: number
  subjectId: string
}

interface AddSubjectToSlotResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onSubjectAlreadyBooked?: () => void
  onWrongSlotInSequentialBooking?: () => void
  onMissingSlotForSequentialBooking?: () => void
}

export const addSubjectToSlot = (
  { slotId, subjectId, ...urlOptions }: SubjectToSlotOptions,
  responseHandlers: AddSubjectToSlotResponseHandlers
) => {
  const { req, cancel } = fetchApi.post(`${getSlotUrl(urlOptions)}/${slotId}/subjects/${subjectId}`, {})

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<AddSubjectToSlotResponseHandlers>(
        {
          [BackendError.CALENDAR_SLOT_SUBJECT_ALREADY_BOOKED]: 'onSubjectAlreadyBooked',
          [BackendError.CALENDAR_SEQUENTIAL_BOOKING_WRONG_SLOT]: 'onWrongSlotInSequentialBooking',
          [BackendError.CALENDAR_MISSING_SLOT_FOR_SEQUENTIAL_BOOKING]: 'onMissingSlotForSequentialBooking'
        },
        error,
        responseHandlers,
        status
      )
    } else {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface RemoveSubjectFromSlotResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const removeSubjectFromSlot = (
  { slotId, subjectId, ...urlOptions }: SubjectToSlotOptions,
  responseHandlers: RemoveSubjectFromSlotResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete(`${getSlotUrl(urlOptions)}/${slotId}/subjects/${subjectId}`)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<RemoveSubjectFromSlotResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
