/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { fetchApi } from '../fetchApi'
import { RoleName } from '../generalSettings'
import { RecordStatus } from '../sectionAdvancement'

export enum QueryType {
  Question = 'question',
  Measure = 'measure',
  TableMatrix = 'table_matrix'
}

export enum QueryStatus {
  Open = 'OPEN',
  Resolved = 'RESOLVED',
  Closed = 'CLOSED'
}

export interface RemoteQuery {
  type: string
  inclusion: number
  question?: number
  repeated_measure?: number
  single_measure?: number
  message: string
  id: string
  date_added: string
  last_updated: string
  status: QueryStatus
  user: {
    id: number
    name: string
    role: string
  }
  replies: [
    {
      message: string
      date_added: string
      user: {
        id: number
        name: string
        role: string
      }
    }
  ]
}

export interface QueryReply {
  dateAdded: Date
  message: string
  user: {
    id: string
    name: string
    role: RoleName
  }
}

interface QueryBase {
  inclusionId: string
  message: string
  id: string
  dateAdded: Date
  lastUpdatedDate: Date
  status: QueryStatus
  user: {
    id: string
    name: string
    role: RoleName
  }
  replies: QueryReply[]
  nr?: number
}

export interface QuestionQuery extends QueryBase {
  type: QueryType.Question
  questionId: string
}

export interface MeasureQuery extends QueryBase {
  type: QueryType.Measure
  measureId: string
  repeatedMeasuresId: string
}

export interface TableQuery extends QueryBase {
  type: QueryType.TableMatrix
  tableMatrixId: string
}

export type Query = QuestionQuery | MeasureQuery | TableQuery

export const maxQueries = 10

export const prepareQueryStatusForSave = (status: QueryStatus) => {
  switch (status) {
    case QueryStatus.Open:
      return 'OPENED'
    case QueryStatus.Resolved:
      return 'RESOLVED'
    case QueryStatus.Closed:
      return 'CLOSED'
    default:
      return null
  }
}

export const parseRemoteQueryStatus = (status: string) => {
  switch (status) {
    case 'OPEN':
      return QueryStatus.Open
    case 'RESOLVED':
      return QueryStatus.Resolved
    case 'CLOSED':
      return QueryStatus.Closed
    default:
      return null
  }
}

const parseRemoteQueryType = (remoteType: string) => {
  switch (remoteType) {
    case 'MEASURE_QUERY':
      return QueryType.Measure
    case 'REPEATED_MEASURE_QUERY':
      return QueryType.TableMatrix
    case 'QUESTION_QUERY':
    default:
      return QueryType.Question
  }
}

export const parseRemoteQuery = (remoteQuery: RemoteQuery) => {
  const type = parseRemoteQueryType(remoteQuery.type)

  const commonFields = {
    type,
    inclusionId: String(remoteQuery.inclusion),
    message: remoteQuery.message,
    id: String(remoteQuery.id),
    dateAdded: new Date(remoteQuery.date_added),
    lastUpdatedDate: new Date(remoteQuery.last_updated),
    status: remoteQuery.status,
    user: {
      id: String(remoteQuery.user.id),
      name: remoteQuery.user.name,
      role: remoteQuery.user.role
    },
    replies: remoteQuery.replies.map(reply => ({
      message: reply.message,
      dateAdded: new Date(reply.date_added),
      user: {
        id: String(reply.user.id),
        name: reply.user.name,
        role: reply.user.role
      }
    }))
  }

  switch (type) {
    case QueryType.Measure:
      return {
        measureId: String(remoteQuery.single_measure),
        repeatedMeasuresId: String(remoteQuery.repeated_measure),
        ...commonFields
      } as MeasureQuery
    case QueryType.TableMatrix:
      return {
        tableMatrixId: String(remoteQuery.repeated_measure),
        ...commonFields
      } as TableQuery
    case QueryType.Question:
    default:
      return {
        questionId: String(remoteQuery.question),
        ...commonFields
      } as QuestionQuery
  }
}

interface CreateQueryOptions {
  studyId: string
  inclusionId: string
  questionId?: string
  measureId?: string
  tableId?: string
  message: string
}

type CreateQueryResponse = RemoteQuery & { inclusion_status: RecordStatus }

interface CreateQueryResponseHandlers {
  onSuccess?: (query: Query, newInclusionStatus: RecordStatus) => void
  onRequestError?: (code: number) => void
}

export const createQuery = (
  {
    questionId: question,
    measureId: single_measure,
    tableId: repeated_measure,
    inclusionId: inclusion,
    message,
    studyId
  }: CreateQueryOptions,
  responseHandlers?: CreateQueryResponseHandlers
) => {
  const query = { question, single_measure, repeated_measure, inclusion, message }
  const { req, cancel } = fetchApi.post<CreateQueryResponse>('queries', query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<CreateQueryResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteQuery(body), body.inclusion_status)
    }
  })

  return cancel
}

interface FetchQueriesOptions {
  studyId: string
  inclusionId: string
  subsectionId: string
}

interface FetchQueriesResponse {
  results: RemoteQuery[]
}

interface FetchQueriesResponseHandlers {
  onSuccess?: (queries: Query[]) => void
  onRequestError?: (code: number) => void
}

export const fetchQueries = (
  { studyId, inclusionId, subsectionId }: FetchQueriesOptions,
  responseHandlers?: FetchQueriesResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<FetchQueriesResponse>(`queries/${inclusionId}/${subsectionId}`, {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchQueriesResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.results.map(parseRemoteQuery))
    }
  })

  return cancel
}

interface AddQueryReplyOptions {
  studyId: string
  queryId: string
  message: string
}

interface AddQueryReplyResponseHandlers {
  onSuccess?: ({ dateAdded, message, user: { id, name, role } }: QueryReply) => void
  onRequestError?: (code: number) => void
}

interface AddQueryReplyResponse {
  message: string
  id: number
  date_added: string
  user: {
    id: string
    name: string
    role: RoleName
  }
}

export const addQueryReply = (
  { studyId, queryId: query_id, message }: AddQueryReplyOptions,
  responseHandlers?: AddQueryReplyResponseHandlers
) => {
  const { req, cancel } = fetchApi.post<AddQueryReplyResponse>('queries/replies', { message, query_id }, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<AddQueryReplyResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        dateAdded: new Date(body.date_added),
        message: body.message,
        user: {
          id: body.user.id,
          name: body.user.name,
          role: body.user.role
        }
      })
    }
  })

  return cancel
}

interface UpdateQueryStatusOptions {
  studyId: string
  queryId: string
  status: QueryStatus
}

interface UpdateQueryStatusResponseHandlers {
  onSuccess?: (data: Partial<Query>, newInclusionStatus: RecordStatus) => void
  onRequestError?: (code: number) => void
}

interface UpdateQueryStatusResponse {
  status: QueryStatus
  id: number
  last_updated: string
  inclusion_status: RecordStatus
}

export const updateQueryStatus = (
  { studyId, queryId, status }: UpdateQueryStatusOptions,
  responseHandlers?: UpdateQueryStatusResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch<UpdateQueryStatusResponse>(
    `queries/${queryId}/status/change`,
    { status },
    { studyId }
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<UpdateQueryStatusResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({ lastUpdatedDate: new Date(body.last_updated) }, body.inclusion_status)
    }
  })

  return cancel
}
