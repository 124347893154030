/* eslint-disable camelcase */
import { QuestionType } from './forms'

export type VariableAnswerTypes =
  | string
  | {
      selections: string[]
      other?: string
    }
  | {
      selected: string
      other?: string
    }

interface VariableConfigClientOption {
  label: string
  value: string
  numberLabel: number
}

export interface VariableConfigRemoteOption {
  label: string
  value: string
  number_label: number
}

interface VariableConfigClient {
  maxValue?: string
  minValue?: string
  options?: VariableConfigClientOption[]
  subtype?: string
  other?: string
}

interface VariableConfigRemote {
  max_value?: string
  min_value?: string
  options?: VariableConfigRemoteOption[]
  subtype?: string
  other?: string
}

export interface VariableBasic {
  id: string | number
  variable: string
}

export interface RecordVariable extends VariableBasic {
  type: QuestionType
  config: VariableConfigClient
  title: string
}

export interface RecordVariableRemote {
  id: string | number
  variable: string
  title: string
  type: QuestionType
  config: VariableConfigRemote
}

export const parseRecordVariableConfig = (config: VariableConfigRemote) => ({
  ...config,
  options:
    config.options &&
    config.options.map((option: VariableConfigRemoteOption) => ({
      label: option.label,
      value: option.value,
      numberLabel: option.number_label
    })),
  minValue: config.min_value,
  maxValue: config.max_value
})

export const parseRecordVariable = (inclusionVariable: RecordVariableRemote) => ({
  ...inclusionVariable,
  config: parseRecordVariableConfig(inclusionVariable.config)
})

export enum TableColumnVariableSource {
  SubjectDatabase = 'SUBJECT_DATABASE',
  RecruitmentSurvey = 'RECRUITMENT'
}
