/* eslint-disable camelcase */
import { RemoteMeetingInfo } from '../components/studies/Econsult/EconsultMeetingContext'
import { createErrorsHandlers } from '../utils'
import { BackendError } from './RequestError'
import { fetchApi } from './fetchApi'
import { MessageType } from './subjects'

export enum MeetingStatusOptions {
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED',
  Suspended = 'FINISHED'
}

export type MeetingStatus =
  | MeetingStatusOptions.NotStarted
  | MeetingStatusOptions.Started
  | MeetingStatusOptions.Suspended

export interface BaseInvestigatorMeetingOptions {
  user: number
  subject: string
  study: string
  messageType: MessageType
  smsBody: string
  emailBody: string
  emailSubject: string
}

interface RoomResponse {
  user: number
  subject: string
  study: string
  uuid: string
  room_id: string
  room_name: string
  status: MeetingStatus
  duration: number
  created_at: string
  updated_at: string
  url: string
}

interface RoomResponseHandlers {
  onSuccess?: (response: RemoteMeetingInfo) => void
  onRequestError?: (code: number) => void
  onTenantForbidden?: () => void
  onSubjectStudyNotExist?: () => void
  onTooManyRequests?: () => void
  onNoEmail?: () => void
  onNoPhone?: () => void
}

interface StartOrEndInvestigatorMeetingOptions {
  user: number
  subject: string
  study: string
  uuid: string
}

interface StartMeetingResponse {
  status: MeetingStatus
  created_at: string
}

interface ClientStartMeeting {
  status: MeetingStatus
  createdAt: string
}

interface StartMeetingResponseHandlers {
  onSuccess?: (response: ClientStartMeeting) => void
  onRequestError?: (code: number) => void
}

interface LeaveMeetingOptions {
  study: string
  uuid: string
  shouldTerminateMeeting: boolean
}

export interface ClientJoinMeetingResponse {
  token: string
  roomName: string
  status: MeetingStatus
}

interface JoinOrLeaveRoomResponseVideo {
  uuid: string
  room_id: string
  status: MeetingStatus
  room_name: string
}

export interface JoinMeetingResponse {
  token: string
  video: JoinOrLeaveRoomResponseVideo
}

interface JoinMeetingResponseHandlers {
  onSuccess?: (response: ClientJoinMeetingResponse) => void
  onRequestError?: (code: number) => void
  onVideoNotExist?: () => void
}

interface LeaveMeetingResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

const parseRemoteJoinMeeting = (response: JoinMeetingResponse) => {
  return {
    token: response.token,
    roomName: response.video.room_name,
    status: response.video.status
  }
}

const parseRemoteCreateRoom = (response: RoomResponse) => ({
  ...response,
  roomId: response.room_id,
  roomName: response.room_name,
  createdAt: response.created_at
})

const parseRemoteStartMeeting = (response: StartMeetingResponse) => ({
  status: response.status,
  createdAt: response.created_at
})

export const createNewRoom = (payload: BaseInvestigatorMeetingOptions, responseHandlers?: RoomResponseHandlers) => {
  const query = {
    user: payload.user,
    subject: payload.subject,
    study: payload.study,
    message_body: payload.emailBody ?? payload.smsBody,
    message_subject: payload.emailSubject,
    message_type: payload.messageType
  }
  const { req, cancel } = fetchApi.post<RoomResponse>('econsult', query, { studyId: payload.study })

  req.then(({ error, body, status }) => {
    if (status === 429) {
      if (responseHandlers.onTooManyRequests) {
        responseHandlers.onTooManyRequests()
      }
    } else if (error || status === 429) {
      createErrorsHandlers<RoomResponseHandlers>(
        {
          429: 'onTooManyRequests',
          [BackendError.INVITE_SUBJECT_HAS_NO_EMAIL]: 'onNoEmail',
          [BackendError.INVITE_SUBJECT_HAS_NO_PHONE]: 'onNoPhone',
          [BackendError.ECONSULT_MODULE_FORBIDDEN]: 'onTenantForbidden',
          [BackendError.ECONSULT_SUBJECT_STUDY_DOES_NOT_EXIST]: 'onSubjectStudyNotExist'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteCreateRoom(body))
    }
  })

  return cancel
}

export const startMeetingFromLobby = (
  payload: StartOrEndInvestigatorMeetingOptions,
  responseHandlers?: StartMeetingResponseHandlers
) => {
  const path = `econsult/${payload.uuid}/start_call`
  const { req, cancel } = fetchApi.post<StartMeetingResponse>(path, payload, { studyId: payload.study })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<StartMeetingResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteStartMeeting(body))
    }
  })

  return cancel
}

export const joinMeeting = (uuid: string, responseHandlers?: JoinMeetingResponseHandlers) => {
  const { req, cancel } = fetchApi.post<JoinMeetingResponse>(`econsult/${uuid}/join`, {})

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<JoinMeetingResponseHandlers>(
        {
          [BackendError.ECONSULT_VIDEO_DOES_NOT_EXIST]: 'onVideoNotExist'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteJoinMeeting(body))
    }
  })

  return cancel
}

export const leaveMeeting = (
  { study, uuid, shouldTerminateMeeting }: LeaveMeetingOptions,
  responseHandlers?: LeaveMeetingResponseHandlers
) => {
  const path = shouldTerminateMeeting ? `${uuid}/end_call` : `${uuid}/leave`
  const { req, cancel } = fetchApi.post<JoinOrLeaveRoomResponseVideo>(`econsult/${path}`, {}, { studyId: study })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<LeaveMeetingResponseHandlers>(
        {
          404: 'onSuccess' // no meeting so close everything
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface CheckMeetingStatusResponseHandlers {
  onSuccess?: (response: RemoteMeetingInfo) => void
  onRequestError?: (code: number) => void
}

export const checkMeetingStatus = (
  { studyId, subjectId }: { studyId: string; subjectId: string },
  responseHandlers?: CheckMeetingStatusResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RoomResponse>(`econsult/subject/${subjectId}`, {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<CheckMeetingStatusResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteCreateRoom(body))
    }
  })

  return cancel
}
