/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'

interface RemotePublicEproLandingPage {
  qr_email_required: boolean
  qr_landing_title: string
  qr_landing_description: string
  logo_url: string
}

interface PublicEproLandingPage {
  emailRequired: boolean
  title: string
  description: string
  logoUrl: string
}

const parseRemotePublicEproLandingPage = (remoteData: RemotePublicEproLandingPage) => ({
  emailRequired: remoteData.qr_email_required,
  title: remoteData.qr_landing_title,
  description: remoteData.qr_landing_description,
  logoUrl: remoteData.logo_url
})

interface FetchPublicEproLandingPageResponseHandlers {
  onSuccess?: (data: PublicEproLandingPage) => void
  onNotFound?: () => void
  onExpired?: () => void
  onQrIsOff?: () => void
  onRequestError?: (code?: number) => void
  onError?: () => void
}

export const fetchPublicEproLandingPage = (
  { token }: { token: string },
  responseHandlers?: FetchPublicEproLandingPageResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemotePublicEproLandingPage>(`epro/qr/landing-page/${token}`)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchPublicEproLandingPageResponseHandlers>(
        {
          404: 'onNotFound',
          [BackendError.EPRO_STATUS_ENDED]: 'onExpired',
          [BackendError.STUDY_STATUS_ENDED]: 'onExpired',
          [BackendError.STUDY_STATUS_ARCHIVED]: 'onExpired',
          [BackendError.STUDY_STATUS_SUSPENDED]: 'onExpired',
          [BackendError.EPRO_STATUS_ARCHIVED]: 'onExpired',
          [BackendError.EPRO_QR_OFF]: 'onQrIsOff'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemotePublicEproLandingPage(body))
    }
  })

  return cancel
}

interface CreatePublicEproRecordResponse {
  token: string
}

interface CreatePublicEproRecordResponseHandlers {
  onSuccess?: (token: string) => void
  onAlreadyExists?: () => void
  onQrIsOff?: () => void
  onSubjectHasOtherCenter?: () => void
  onRequestError?: (code?: number) => void
  onError?: () => void
}

interface CreateEproPublicRecordOptions {
  token: string
  email?: string
  messageSubject?: string
  messageMessage?: string
}

export const createEproPublicRecord = (
  { token, email, messageSubject, messageMessage }: CreateEproPublicRecordOptions,
  responseHandlers?: CreatePublicEproRecordResponseHandlers
) => {
  const query = {
    email,
    message_subject: messageSubject,
    message_body: messageMessage
  }
  const { req, cancel } = fetchApi.post<CreatePublicEproRecordResponse>(`epro/qr/records/${token}`, query)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<CreatePublicEproRecordResponseHandlers>(
        {
          [BackendError.SUBJECT_EMAIL_ALREADY_TAKEN]: 'onAlreadyExists',
          [BackendError.EPRO_SUBJECT_HAS_OTHER_CENTER]: 'onSubjectHasOtherCenter',
          [BackendError.EPRO_QR_OFF]: 'onQrIsOff'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.token)
    }
  })

  return cancel
}
