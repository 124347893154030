/* eslint-disable camelcase */
import { QuestionBase, QuestionType, RemoteQuestionBase } from './base'

export interface RemoteTextQuestion extends RemoteQuestionBase {
  type: QuestionType.Text
  config: {
    placeholder?: string
    max_characters?: number
  }
}

export interface TextQuestion extends QuestionBase {
  type: QuestionType.Text
  config: {
    placeholder?: string
    maxCharacters?: number
  }
}

export const prepareTextQuestionConfigForSave = (config: TextQuestion['config']) => {
  return {
    placeholder: config.placeholder,
    max_characters: config.maxCharacters
  }
}

export const parseTextTypeRemoteQuestionConfig = (config: RemoteTextQuestion['config']) => ({
  placeholder: config.placeholder,
  maxCharacters: config.max_characters
})
