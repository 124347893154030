/* eslint-disable camelcase */
import dayjs, { Dayjs } from 'dayjs'

import { createErrorsHandlers, localeFromPath } from '../../utils'
import { fetchApi } from '../fetchApi'

export interface SideBySideChartFormula {
  id: number
  formula_number: string
  brand_name: string
  category_name: string
  color: string
  order: number
}

export enum SideBySideChartType {
  Column = 'COLUMN',
  Radar = 'RADAR'
}

export interface SideBySideChartData {
  formula: string
  formula_id: string
  question: string
  question_id: string
  value: number
}

export interface RemoteSideBySideChart {
  id: number
  title: string
  type: SideBySideChartType
  formulas: SideBySideChartFormula[]
  questions: {
    id: number
    title: string
    variable: string
  }[]
  panelists: {
    id: number
    full_name: string
    datacapt_id: string
  }[]
  data: SideBySideChartData[]
  last_refresh: string
  all_tested: boolean
  axis?: {
    min: number
    max: number
  }
}

export interface SideBySideChart {
  id: number
  title: string
  type: SideBySideChartType
  formulas: SideBySideChartFormula[]
  questions: {
    id: string
    title: string
    variable: string
  }[]
  panelists: {
    id: number
    fullName: string
    datacaptId: string
  }[]
  data: SideBySideChartData[]
  lastRefresh: Dayjs
  allTested: boolean
  axis?: {
    min: number
    max: number
  }
}

const parseRemoteChart = (chart: RemoteSideBySideChart): SideBySideChart => ({
  id: chart.id,
  title: chart.title,
  type: chart.type,
  formulas: chart.formulas,
  questions:
    chart.questions?.map(question => ({
      id: `${question.id}`,
      title: question.title,
      variable: question.variable
    })) || null,
  panelists:
    chart.panelists?.map(panelist => ({
      id: panelist.id,
      fullName: panelist.full_name,
      datacaptId: panelist.datacapt_id
    })) || null,
  data: (chart.data || []).map(data => ({
    ...data,
    formula_id: `${data.formula_id}`,
    question_id: `${data.question_id}`
  })),
  lastRefresh: chart.last_refresh && dayjs(chart.last_refresh).locale(localeFromPath()),
  allTested: chart.all_tested,
  axis: chart.axis
})

const prepareChartForSave = (chart: Partial<SideBySideChart>): Partial<RemoteSideBySideChart> => ({
  ...(chart.title ? { title: chart.title } : {}),
  type: chart.type,
  formulas: chart.formulas,
  questions:
    chart.questions?.map(question => ({
      id: parseInt(question.id, 10),
      title: question.title,
      variable: question.variable
    })) || null,
  panelists: chart.panelists?.map(panelist => ({
    id: panelist.id,
    full_name: panelist.fullName,
    datacapt_id: panelist.datacaptId
  })),
  all_tested: chart.allTested
})

interface FetchSideBySideChartsResponse {
  results: RemoteSideBySideChart[]
  count: number
}

interface FetchSideBySideChartsResponseHandlers {
  onSuccess?: (response: SideBySideChart[], countAll: number) => void
  onRequestError?: (code: number) => void
}

export const fetchSideBySideCharts = (
  { projectId }: { projectId: string },
  responseHandlers?: FetchSideBySideChartsResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<FetchSideBySideChartsResponse>(`side_by_side/projects/${projectId}/charts`)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSideBySideChartsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.results.map(parseRemoteChart), body.count)
    }
  })

  return cancel
}

interface CreateSideBySideChartResponseHandlers {
  onSuccess?: ({ id }: CreateSideBySideChartResponse) => void
  onRequestError?: (code: number) => void
}

export type CreateSideBySideChartOptions = {
  projectId: string
  data: Omit<SideBySideChart, 'id' | 'data' | 'lastRefresh'>
}

interface CreateSideBySideChartResponse {
  id: number
}

export const createSideBySideChart = (
  { projectId, data }: CreateSideBySideChartOptions,
  responseHandlers?: CreateSideBySideChartResponseHandlers
) => {
  const { req, cancel } = fetchApi.post<CreateSideBySideChartResponse>(
    `side_by_side/projects/${projectId}/charts`,
    prepareChartForSave(data)
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<CreateSideBySideChartResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}

interface UpdateSideBySideChartResponseHandlers {
  onSuccess?: (chart: Partial<SideBySideChart>) => void
  onRequestError?: (code: number) => void
}

export interface UpdateSideBySideChartOptions {
  projectId: string
  id: number
  data: Partial<SideBySideChart>
}
type UpdateSideBySideChartResponse = RemoteSideBySideChart

export const updateSideBySideChart = (
  { projectId, id, data }: UpdateSideBySideChartOptions,
  responseHandlers?: UpdateSideBySideChartResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch<UpdateSideBySideChartResponse>(
    `side_by_side/projects/${projectId}/charts/${id}`,
    prepareChartForSave(data)
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<UpdateSideBySideChartResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteChart(body))
    }
  })

  return cancel
}

export interface RefreshSideBySideChartOptions {
  projectId: string
  id: number
}

type RefreshSideBySideChartResponse = RemoteSideBySideChart

interface RefreshSideBySideChartResponseHandlers {
  onSuccess?: (chart: SideBySideChart) => void
  onRequestError?: (code: number) => void
}

export const refreshSideBySideChart = (
  { projectId, id }: RefreshSideBySideChartOptions,
  responseHandlers?: RefreshSideBySideChartResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RefreshSideBySideChartResponse>(
    `side_by_side/projects/${projectId}/charts/${id}/refresh`
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<RefreshSideBySideChartResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteChart(body))
    }
  })

  return cancel
}

interface RemoveSideBySideChartOptions {
  projectId: string
  id: number
}

interface RemoveSideBySideChartResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const removeSideBySideChart = (
  { projectId, id }: RemoveSideBySideChartOptions,
  responseHandlers?: RemoveSideBySideChartResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete(`side_by_side/projects/${projectId}/charts/${id || 2}`)

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<RemoveSideBySideChartResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface PreviewSideBySideChartResponseHandlers {
  onSuccess?: (chart: SideBySideChart) => void
  onRequestError?: (code: number) => void
}

export type PreviewSideBySideChartOptions = {
  projectId: string
  data: Omit<SideBySideChart, 'id' | 'data' | 'lastRefresh' | 'title'>
}

type PreviewSideBySideChartResponse = RemoteSideBySideChart

export const previewSideBySideChart = (
  { projectId, data }: PreviewSideBySideChartOptions,
  responseHandlers?: PreviewSideBySideChartResponseHandlers
) => {
  const { req, cancel } = fetchApi.post<PreviewSideBySideChartResponse>(
    `side_by_side/projects/${projectId}/charts/preview`,
    prepareChartForSave(data)
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<PreviewSideBySideChartResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteChart(body))
    }
  })

  return cancel
}
