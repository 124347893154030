/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { ExportType } from '../exports'
import { fetchApi } from '../fetchApi'
import { FileAnswer } from '../forms'

interface GenerateEconsentPdfFileOptions {
  studyId?: string
  recordId?: string
  token?: string
  qrCode?: string
}

interface GenerateEconsentPdfFileResponse {
  export_uuid: string
  name: string
  size: number
}

const econsentPdfFileMapping = (response: GenerateEconsentPdfFileResponse) => ({
  id: response.export_uuid,
  name: response.name,
  size: response.size
})

interface GenerateEconsentPdfFileResponseHandlers {
  onSuccess?: (file: FileAnswer) => void
  onRequestError?: (code: number) => void
}

export const generateEconsentPdfFile = (
  { studyId, recordId, token, qrCode }: GenerateEconsentPdfFileOptions,
  responseHandlers?: GenerateEconsentPdfFileResponseHandlers
) => {
  const { req, cancel } = fetchApi.post<GenerateEconsentPdfFileResponse>(
    token ? `subject_accounts/exports/econsent/pdf/${token}` : 'exports/econsent/pdf',
    token ? {} : { record_id: recordId, export_type: ExportType.EconsentPdf },
    token ? { qrCode } : { studyId }
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<GenerateEconsentPdfFileResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(econsentPdfFileMapping(body))
    }
  })

  return cancel
}

interface FetchEconsentPdfUrlResponse {
  report_url: string
}

interface FetchEconsentPdfUrlResponseHandlers {
  onSuccess?: (fileId: string) => void
  onRequestError?: (code?: number) => void
}

interface FetchEconsentPdfUrlOptions {
  fileId: string
  token?: string
  studyId?: string
  qrCode?: string
}

export const fetchEconsentPdfUrl = (
  { fileId, token, studyId, qrCode }: FetchEconsentPdfUrlOptions,
  responseHandlers?: FetchEconsentPdfUrlResponseHandlers
) => {
  let cancelCheckStatusRequest: () => void = () => null
  let pingServerTimeout: number = null

  const checkStatus = () => {
    const { req, cancel } = token
      ? fetchApi.post<FetchEconsentPdfUrlResponse>(`subject_accounts/exports/status/${fileId}/${token}`, {}, { qrCode })
      : fetchApi.get<FetchEconsentPdfUrlResponse>(`exports/status/${fileId}`, {}, { studyId })
    cancelCheckStatusRequest = cancel

    return req.then(({ error, body, status }) => {
      if (status === 200) return Promise.resolve(body.report_url)
      if (status === 202) return Promise.resolve(null)

      if (error && responseHandlers?.onRequestError) {
        responseHandlers.onRequestError()
      }

      return Promise.reject()
    })
  }

  const pingServerForFile = (onReady: (url: string) => void) => {
    checkStatus().then(url => {
      if (url) {
        onReady(url)
      } else {
        pingServerTimeout = window.setTimeout(() => pingServerForFile(onReady), 1000)
      }
    })
  }

  pingServerForFile(fileUrl => {
    if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(fileUrl)
    }
  })

  return () => {
    cancelCheckStatusRequest()
    window.clearTimeout(pingServerTimeout)
  }
}
