/* eslint-disable camelcase */
import { TemplateApiPaths, createFromTemplates, insertTemplates } from '../forms'

interface InsertEcrfBlockTemplatesOptions {
  studyId: string
  subsectionId: string
  repeatedMeasureId: string
  templateIds: string[]
}

const ecrfBlockTemplateMapping = {
  subsectionId: 'subsection_id',
  repeatedMeasureId: 'repeated_measure_id',
  templateIds: 'template_ids'
}

export const insertEcrfBlockTemplates = insertTemplates<InsertEcrfBlockTemplatesOptions>(
  TemplateApiPaths.EcrfBlocks,
  ecrfBlockTemplateMapping
)

interface InsertEcrfSubsectionTemplatesOptions {
  studyId: string
  sectionId: string
  templateIds: string[]
}

const ecrfSubsectionTemplateMapping = {
  sectionId: 'section_id',
  templateIds: 'template_ids'
}

export const insertEcrfSubsectionTemplates = insertTemplates<InsertEcrfSubsectionTemplatesOptions>(
  TemplateApiPaths.EcrfSubsections,
  ecrfSubsectionTemplateMapping
)

interface InsertEcrfSectionTemplatesOptions {
  studyId: string
  templateIds: string[]
}

const ecrfSectionTemplateMapping = {
  templateIds: 'template_ids'
}

export const insertEcrfSectionTemplates = insertTemplates<InsertEcrfSectionTemplatesOptions>(
  TemplateApiPaths.EcrfSections,
  ecrfSectionTemplateMapping
)

interface InsertEcrfFullTemplatesOptions {
  studyId: string
  templateId: string
}

const ecrfFullTemplateMapping = {
  templateId: 'template_id'
}

export const insertEcrfFullTemplates = insertTemplates<InsertEcrfFullTemplatesOptions>(
  TemplateApiPaths.EcrfFull,
  ecrfFullTemplateMapping
)

interface CreateEcrfFullTemplatesOptions {
  studyId: string
  templateId?: string
}

const createEcrfFullFromTemplateMapping = {
  templateId: 'template_id'
}

export const createEcrfFromFullTemplates = createFromTemplates<CreateEcrfFullTemplatesOptions>(
  TemplateApiPaths.EcrfFull,
  createEcrfFullFromTemplateMapping
)

interface CreateEcrfTemplatesOptions {
  studyId: string
  templateIds?: string[]
}

const createEcrfFromTemplateMapping = {
  templateIds: 'template_ids'
}

export const createEcrfFromSectionTemplates = createFromTemplates<CreateEcrfTemplatesOptions>(
  TemplateApiPaths.EcrfSections,
  createEcrfFromTemplateMapping
)

export const createEcrfFromSubsectionTemplates = createFromTemplates<CreateEcrfTemplatesOptions>(
  TemplateApiPaths.EcrfSubsections,
  createEcrfFromTemplateMapping
)
