/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { fetchApi } from '../fetchApi'
import {
  BaseRemoteQuestionAnswer,
  FileAnswer,
  FulfillmentQuestionAnswer,
  QuestionType,
  RemoteFileAnswer,
  deleteAnswer,
  deleteFileAnswer,
  parseRemoteFileAnswer,
  parseRemoteQuestionAnswer,
  saveAnswer,
  saveFileAnswer
} from '../forms'
import {
  RecordStatus,
  RemoteSectionAdvancement,
  SectionAdvancement,
  parseRemoteSectionAdvancement
} from '../sectionAdvancement'

interface RecruitmentAnswerFields {
  recruitmentId: string
  recordId: string
  reason?: string
  nullAnswerType?: QuestionType
}

const recruitmentAnswerMapping = {
  reason: 'reason'
}

const recruitmentFileAnswerMapping = {
  reason: 'reason',
  recordId: 'recruitment_record'
}

const getRecruitmentAnswerPath = (data: RecruitmentAnswerFields) =>
  `recruitment/studies/${data.recruitmentId}/answers/${data.recordId}`

const getRecruitmentFilePath = () => `uploaded_files/recruitment/answer`

export interface RecruitmentRemoteQuestionAnswer extends BaseRemoteQuestionAnswer {
  datacapt_id: number
}

export interface RecruitmentQuestionAnswer extends FulfillmentQuestionAnswer {
  subjectId: string
}

export const saveRecruitmentFileAnswer = saveFileAnswer<RecruitmentAnswerFields, RemoteFileAnswer, FileAnswer>(
  getRecruitmentFilePath,
  recruitmentFileAnswerMapping,
  parseRemoteFileAnswer
)

export const deleteRecruitmentFileAnswer = deleteFileAnswer<RecruitmentAnswerFields>(
  getRecruitmentFilePath,
  recruitmentFileAnswerMapping
)

export const parseRecruitmentRemoteQuestionAnswer = (answer: RecruitmentRemoteQuestionAnswer) => ({
  ...parseRemoteQuestionAnswer(answer),
  subjectId: String(answer.datacapt_id),
  blockId: String(answer.question)
})

export const saveRecruitmentAnswer = saveAnswer<
  RecruitmentAnswerFields,
  RecruitmentRemoteQuestionAnswer,
  RecruitmentQuestionAnswer
>(getRecruitmentAnswerPath, recruitmentAnswerMapping, parseRecruitmentRemoteQuestionAnswer)

export const deleteRecruitmentAnswer = deleteAnswer<RecruitmentAnswerFields>(
  getRecruitmentAnswerPath,
  recruitmentAnswerMapping
)

interface RemoteRecruitmentRecord {
  id: number
  subject: string
  status: RecordStatus
  sections?: RemoteSectionAdvancement[]
}

export interface RecruitmentRecord {
  id: string
  name: string
  progress: number
  status: RecordStatus
  sectionsAdvancement?: { [sectionId: string]: SectionAdvancement }
}

const parseRemoteRecruitmentRecord = (remoteRecord: RemoteRecruitmentRecord) => ({
  id: String(remoteRecord.id),
  name: String(remoteRecord.subject),
  status: remoteRecord.status,
  progress: 0,
  sectionsAdvancement: Object.entries(remoteRecord.sections || {}).reduce(
    (acc, [sectionId, remoteSection]) => ({
      ...acc,
      [sectionId]: parseRemoteSectionAdvancement(remoteSection)
    }),
    {}
  )
})

interface FetchRecruitmentRecordOptions {
  recordId: string
  recruitmentId: string
}

interface FetchRecruitmentRecordResponseHandlers {
  onSuccess?: (record: RecruitmentRecord) => void
  onNotFound?: () => void
  onRequestError?: (code: number) => void
}

export const fetchRecruitmentRecord = (
  { recordId, recruitmentId }: FetchRecruitmentRecordOptions,
  responseHandlers?: FetchRecruitmentRecordResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteRecruitmentRecord>(
    `recruitment/studies/${recruitmentId}/records/${recordId}`
  )

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchRecruitmentRecordResponseHandlers>(
        {
          404: 'onNotFound'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteRecruitmentRecord(body))
    }
  })

  return cancel
}
