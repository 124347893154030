import {
  createFromStructure,
  fetchQuestionsDependencies,
  fetchSection,
  fetchStructure,
  fetchStructureSummary,
  fetchSubsection,
  searchQuestions,
  updateSection,
  updateStructure,
  updateSubsection
} from '../forms'

export const fetchEproQuestionsDependencies = fetchQuestionsDependencies<{ eproId: string }>(
  ({ eproId }) => `epro/${eproId}/blocks_for_builder_logic`
)

export const fetchEproStructure = fetchStructure<{ eproId: string }>(({ eproId }) => `epro/structure/${eproId}`)

export const fetchEproSection = fetchSection<{ eproId: string; sectionId: string }>(
  ({ eproId, sectionId }) => `epro/structure/${eproId}/${sectionId}`
)

export const fetchEproSubsection = fetchSubsection<{
  eproId: string
  sectionId: string
  subsectionId: string
}>(({ eproId, sectionId, subsectionId }) => `epro/${eproId}/${sectionId}/${subsectionId}`)

export const createEproFromStructure = createFromStructure<{ eproId: string }>('epro/structure', { eproId: 'epro_id' })

export const updateEproStructure = updateStructure<{ eproId: string }>(() => 'epro/structure', { eproId: 'epro_id' })

export const fetchEproStructureSummary = fetchStructureSummary<{ eproId: string }>(
  ({ eproId }) => `epro/${eproId}/structure_for_export`
)

export const searchEproQuestions = searchQuestions<{ eproId: string }>(({ eproId }) => `epro/${eproId}/variables`)

export const updateEproSection = updateSection<{ sectionId: string; eproId: string }>(
  ({ sectionId, eproId }) => `epro/structure/${eproId}/${sectionId}`
)

export const updateEproSubsection = updateSubsection<{ sectionId: string; subsectionId: string; eproId: string }>(
  ({ sectionId, subsectionId, eproId }) => `epro/${eproId}/${sectionId}/${subsectionId}`
)
