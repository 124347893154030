/* eslint-disable camelcase */

/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs'

import { createErrorsHandlers } from '../../../utils'
import { BackendError } from '../../RequestError'
import { EcrfAnswer } from '../../ecrf'
import { DataMapping, fetchApi, mapData } from '../../fetchApi'
import { RemoteFile, parseRemoteFile } from '../../file'
import { SurveyAnswer } from '../../forms'
import { CalculError, QuestionType, ValidationRuleType } from '../blocks'

export interface AnswerValidationMessage {
  message: string | string[]
  type: ValidationRuleType
  questionId: string
}

export interface BaseRemoteQuestionAnswer {
  type: undefined
  value?: any
  error?: {
    code: number
    message: string
  }
  is_prefilled?: boolean
  sdv_date?: string
  files_data?: RemoteFile[]
  id: number
  question: number
  data_validation?: {
    validation_messages: {
      message: string
      message_type: string
      question_id: number
    }[]
  }
  product_id?: number
}

export interface BaseQuestionAnswer {
  value: any
  id: string
  validationMessages: AnswerValidationMessage[]
  isPrefilled: boolean
  isSdv: boolean
  type: QuestionType
}

export interface FulfillmentQuestionAnswer extends BaseQuestionAnswer {
  blockId: string
  productId?: number
}

export type Answer = EcrfAnswer | SurveyAnswer

const parseRemoteValidationMessageType = (remoteMessageType: string) => {
  switch (remoteMessageType) {
    case 'WARNING':
      return ValidationRuleType.Warning
    case 'INFORMATION':
      return ValidationRuleType.Information
    case 'EXCLUSION':
      return ValidationRuleType.Exclusion
    case 'UNQUALIFIED':
      return ValidationRuleType.Unqualified
    default:
      return ValidationRuleType.Warning
  }
}

const parseFilesData = (filesData?: RemoteFile[]) => filesData?.map(parseRemoteFile)

const parseLsaData = (filesData?: RemoteFile[], value?: any) =>
  filesData?.map(fileData => ({
    id: fileData.uuid,
    name: fileData.name,
    size: fileData.size,
    description: value
  }))

const parseCalculError = (error: number) => {
  switch (error) {
    case BackendError.CALCUL_EMPTY_FORMULA:
      return CalculError.EmptyFormula
    case BackendError.CALCUL_INCORRECT_FORMULA_FORMAT:
      return CalculError.IncorrectFormulaFormat
    case BackendError.CALCUL_EMPTY_VARIABLES_IN_FORMULA:
      return CalculError.EmptyVariablesInFormula
    case BackendError.CALCUL_VARIABLE_MISMATCH:
      return CalculError.VariableMismatch
    case BackendError.CALCUL_VARIABLE_NO_ANSWER:
      return CalculError.VariableNoAnswer
    case BackendError.CALCUL_INCORRECT_ANSWER_FORMAT_FOR_VARIABLE:
      return CalculError.IncorrectAnswerFormat
    case BackendError.CALCUL_ANSWER_IS_NOT_NUMBER_TYPE:
      return CalculError.AnswerIsNotNumberType
    case BackendError.CALCUL_INCORRECT_COMPILED_FORMULA_FORMAT:
      return CalculError.IncorrectCompiledFormulaFormat
    case BackendError.CALCUL_ERROR_IN_EVALUATION:
      return CalculError.ErrorInEvaluation
    case BackendError.CALCUL_RESULT_NOT_NUMBER:
      return CalculError.ResultNotNumber
    case BackendError.CALCUL_NUMBER_TOO_BIG:
      return CalculError.ResultTooBigNumber
    case BackendError.CALCUL_REFERENCE_NO_ANSWER:
      return CalculError.ReferenceNoAnswer
    case BackendError.CALCUL_INVALID_REFERENCE_QUESTION:
      return CalculError.InvalidReferenceQuestion
    default:
      return null
  }
}

const parseCalculData = (data?: BaseRemoteQuestionAnswer) => ({
  result: data.value,
  error: parseCalculError(data.error?.code)
})

export const parseQuestionAnswerValue = (answer: BaseRemoteQuestionAnswer) => {
  switch (answer.type) {
    case QuestionType.Calcul:
      return parseCalculData(answer)
    case QuestionType.File:
      return parseFilesData(answer.files_data)
    case QuestionType.Lsa:
      return parseLsaData(answer.files_data, answer.value)
    default:
      return answer.value
  }
}

export const parseDataValidation = (answer: Partial<BaseRemoteQuestionAnswer>, skipUnqualifiedValidation = false) => {
  return (
    answer.data_validation?.validation_messages
      ?.map(({ message, message_type, question_id }) => ({
        message,
        type: parseRemoteValidationMessageType(message_type),
        questionId: question_id?.toString()
      }))
      // unqualified validations are skipped in subject surveys as subject shouldn't know which answers made him unqualified
      .filter(v => !skipUnqualifiedValidation || v.type !== ValidationRuleType.Unqualified) || []
  )
}

export const parseRemoteQuestionAnswer = (answer: BaseRemoteQuestionAnswer) => ({
  value: parseQuestionAnswerValue(answer),
  id: String(answer.id),
  validationMessages: parseDataValidation(answer),
  isPrefilled: !!answer.is_prefilled,
  isSdv: !!answer.sdv_date,
  type: answer.type
})

export interface SaveAnswerResponseHandlers<T> {
  onSuccess?: (answer: T) => void
  onRequestError?: (code: number) => void
}

export interface SaveAnswerResponseOptions {
  studyId?: string
  questionId: string
  measureId?: string
  reason?: string
  nullAnswerType?: QuestionType
  answer: any
  language?: string
  qrCode?: string
}

const nullAnswer = (nullAnswerType: QuestionType): any => {
  switch (nullAnswerType) {
    case QuestionType.Radio:
    case QuestionType.Dropdown:
      return { selected: null, other: null }
    case QuestionType.Checkbox:
      return { selections: [], other: null }
    default:
      return null
  }
}

export const saveAnswer =
  <T, K extends BaseRemoteQuestionAnswer, U extends Answer>(
    getPath: (data: T) => string,
    dataMapping: DataMapping<T>,
    returnValueParser: (body: K) => U
  ) =>
  (data: T & SaveAnswerResponseOptions, responseHandlers?: SaveAnswerResponseHandlers<U>) => {
    const query = {
      ...mapData(dataMapping, data),
      question: data.questionId,
      value: data.answer !== null ? data.answer : nullAnswer(data.nullAnswerType),
      ...(data.reason && { reason: data.reason }),
      timezone: dayjs.tz.guess()
    }
    const { req, cancel } = fetchApi.post<K>(getPath(data), query, {
      studyId: data.studyId,
      language: data.language,
      qrCode: data.qrCode
    })

    req.then(({ error, body, status }) => {
      if (error) {
        createErrorsHandlers<SaveAnswerResponseHandlers<U>>({}, error, responseHandlers, status)
      } else if (responseHandlers?.onSuccess) {
        responseHandlers.onSuccess(returnValueParser(body))
      }
    })

    return cancel
  }

export interface DeleteAnswerResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onAnswerDoesntExist?: () => void
}

interface deleteAnswerResponseOptions {
  studyId?: string
  questionId: string
  measureId?: string
  reason?: string
  qrCode?: string
}

export const deleteAnswer =
  <T>(getPath: (data: T) => string, dataMapping: DataMapping<T>) =>
  (data: T & deleteAnswerResponseOptions, responseHandlers?: DeleteAnswerResponseHandlers) => {
    const query = {
      ...mapData(dataMapping, data),
      question: data.questionId,
      ...(data.reason && { reason: data.reason })
    }
    const { req, cancel } = fetchApi.delete(getPath(data), query, {
      studyId: data.studyId,
      qrCode: data.qrCode
    })

    req.then(({ error, status }) => {
      if (error) {
        createErrorsHandlers<DeleteAnswerResponseHandlers>(
          {
            [BackendError.ANSWER_NOT_EXISTS]: 'onAnswerDoesntExist'
          },
          error,
          responseHandlers,
          status
        )
      } else if (responseHandlers?.onSuccess) {
        responseHandlers.onSuccess()
      }
    })

    return cancel
  }
