/* eslint-disable camelcase */
import { Column, ColumnType } from 'src/components/shared/Builder/TableMatrixBuilder/Config/Columns'
import { Label } from 'src/components/shared/Builder/TableMatrixBuilder/Config/Labels'

import { BlockType, QuestionBlock, RemoteBlock, RemoteBlockType, prepareQuestionBlockForSave } from '../blocks'
import {
  Arrangement,
  DateTimeQuestion,
  DropdownQuestion,
  NumberQuestion,
  QuestionType,
  TextQuestion
} from '../questions'

interface RemoteTableMatrixRow {
  title: string
  order: number
  id?: number
  repeated_measure?: number
}

export interface RemoteTableMatrix {
  id: string
  title?: string
  description?: string
  rows?: RemoteTableMatrixRow[]
  blocks?: RemoteBlock[]
  subsection: string
}

export interface TableMatrix {
  id?: string
  tempId?: string
  title?: string
  description?: string
  config: {
    columnLabels: Label[]
    rowLabels: Label[]
    columns: Column[]
  }
  subsectionId?: string
}

export const maxTableColumns = 20
export const maxTableRows = 20

export const parseRemoteTableMatrix = (remoteTableMatrix: RemoteTableMatrix) => {
  const columnLabels: Label[] = []
  const columns: Column[] = []
  remoteTableMatrix.blocks?.forEach(block => {
    if (block.block_type === RemoteBlockType.Question) {
      columnLabels.push({
        tempId: block.id,
        id: parseInt(block.id, 10),
        text: block.title
      })
      const column: Column = {
        label: {
          tempId: block.id,
          id: parseInt(block.id, 10),
          text: block.title
        },
        type: block.type as ColumnType,
        variable: block.variable || block.variable_full
      }
      if (block.type === QuestionType.Dropdown) {
        column.options = block.config.options.map(option => ({
          label: option.label,
          numberLabel: parseInt(option.number_label, 10),
          value: option.value
        }))
      }
      if (block.type === QuestionType.DateTime) {
        column.dateType = block.config.subtype
      }
      columns.push(column)
    }
  })
  return {
    id: String(remoteTableMatrix.id),
    title: remoteTableMatrix.title,
    description: remoteTableMatrix.description,
    config: {
      columns,
      columnLabels,
      rowLabels: (remoteTableMatrix.rows || []).map(row => ({
        tempId: String(row.id),
        id: row.id,
        text: row.title
      })) as Label[]
    },
    subsectionId: String(remoteTableMatrix.subsection) || null
  }
}

type TableMatrixQuestion = TextQuestion | NumberQuestion | DateTimeQuestion | DropdownQuestion

export const prepareTableMatrixQuestionBlock = (
  column: Column,
  tableMatrixId: string
): QuestionBlock<TableMatrixQuestion> => {
  const blockData = {
    blockType: BlockType.Question,
    title: column.label.text,
    id: column.label.id && String(column.label.id),
    type: column.type,
    required: false,
    showInSurvey: true,
    showInProfile: true,
    isGlobal: false,
    variableName: column.variable,
    repeated_measure: tableMatrixId,
    config: {}
  }
  switch (column.type) {
    case QuestionType.Number:
      return blockData as QuestionBlock<NumberQuestion>
    case QuestionType.DateTime:
      return {
        ...blockData,
        config: {
          subtype: column.dateType,
          disableFutureDatetime: false
        }
      } as QuestionBlock<DateTimeQuestion>
    case QuestionType.Dropdown:
      return {
        ...blockData,
        config: {
          options: column.options,
          other: false,
          arrangement: Arrangement.Vertical
        }
      } as QuestionBlock<DropdownQuestion>
    case QuestionType.Text:
    default:
      return blockData as QuestionBlock<TextQuestion>
  }
}

const prepareRowsForSave = (rowLabels: Label[], tableMatrixId: string): RemoteTableMatrixRow[] => {
  return rowLabels.map((rowLabel, index) => ({
    title: rowLabel.text,
    order: index,
    id: rowLabel.id,
    repeated_measure: tableMatrixId && parseInt(tableMatrixId, 10)
  }))
}

export const prepareTableMatrixForSave = (tableMatrix: TableMatrix, order: number, subsectionId: string) => {
  return {
    title: tableMatrix.title,
    description: tableMatrix.description,
    subsection: subsectionId,
    max_measures: maxTableColumns,
    order,
    rows: prepareRowsForSave(tableMatrix.config.rowLabels, tableMatrix.id),
    blocks:
      tableMatrix.config.columns.map((column, index) => {
        const block = prepareTableMatrixQuestionBlock(column, tableMatrix.id)
        return prepareQuestionBlockForSave(block, index, subsectionId, true)
      }) || []
  }
}
