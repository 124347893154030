/* eslint-disable camelcase */
import { BackendError, SorterOrder } from '..'
import { createErrorsHandlers, prepareSorter } from '../../utils'
import { fetchApi } from '../fetchApi'

export enum EconsentStatus {
  All = 'ALL',
  Consented = 'CONSENTED',
  NeedsCounterSignature = 'NEEDS_COUNTER_SIGNATURE',
  NeedsSignature = 'NEEDS_SIGNATURE',
  Rejected = 'REJECTED',
  Archived = 'ARCHIVED'
}

export interface EconsentSubjectsFilters {
  status?: EconsentStatus[]
  center?: string[]
}

export interface Econsent {
  id: string
  subjectId: string
  subjectName: string
  noContact: boolean
  noInPersonSigning: boolean
  sendDate: Date
  consentDate: Date
  center: string
  centerName: string
  centerCode: string
  investigator: string
  status: EconsentStatus
  language: string
  randomisationId: string
  randomisationGroup: string
}

interface EconsentRemote {
  id: string
  subject: {
    subject_id: string
    first_name: string
    last_name: string
    language: string
    no_contact?: boolean
    no_in_person_signing?: boolean
  }
  send_date: string
  date_countersigned: string
  center: string
  center_name: string
  center_code: string
  investigator_name: string
  status: EconsentStatus
  randomisation_id: string
  randomisation_group_name: string
}

interface EconsentSubjectsResponse {
  subjects_count: number
  count: number
  consented_subjects_count: number
  needs_counter_signature_subjects_count: number
  needs_signature_subjects_count: number
  rejected_subjects_count: number
  results: EconsentRemote[]
}

const parseEconsentSubjectsResponse = (response: EconsentSubjectsResponse) => {
  return {
    subjectsCount: response.subjects_count || 0,
    consentedSubjectsCount: response.consented_subjects_count || 0,
    needsCounterSignatureSubjectsCount: response.needs_counter_signature_subjects_count || 0,
    needsSignatureSubjectsCount: response.needs_signature_subjects_count || 0,
    rejectedSubjectsCount: response.rejected_subjects_count || 0,
    allCount: response.count || 0,
    results: response.results?.map(result => ({
      id: String(result.id),
      subjectId: result.subject?.subject_id,
      subjectName: `${result.subject?.first_name} ${result.subject?.last_name}`,
      noContact: result.subject?.no_contact,
      noInPersonSigning: result.subject?.no_in_person_signing,
      sendDate: result.send_date ? new Date(result.send_date) : null,
      consentDate: result.date_countersigned ? new Date(result.date_countersigned) : null,
      center: result.center,
      centerName: result.center_name,
      centerCode: result.center_code,
      investigator: result.investigator_name,
      status: result.status,
      language: result.subject?.language,
      randomisationId: result.randomisation_id,
      randomisationGroup: result.randomisation_group_name
    }))
  }
}

export interface EconsentSubjectsSorter {
  field: keyof Econsent
  order: SorterOrder
}

interface FetchEconsentItemsOptions {
  studyId: string
  options?: {
    sorter?: EconsentSubjectsSorter
    filters?: EconsentSubjectsFilters
    search?: string
    limit?: number
    offset?: number
  }
}

interface EconsentSubjects {
  subjectsCount: number
  consentedSubjectsCount: number
  needsCounterSignatureSubjectsCount: number
  needsSignatureSubjectsCount: number
  rejectedSubjectsCount: number
  allCount: number
  results: Econsent[]
}

interface FetchEconsentItemsResponseHandlers {
  onSuccess?: (subjects: EconsentSubjects) => void
  onRequestError?: (code: number) => void
  onNotExist?: () => void
  onWrongParams?: () => void
}

interface FetchEconsentStatusOptions {
  studyId: string
}

interface FetchEconsentStatusResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

const sorterFields = {
  subjectId: ['subject_in_study__subject_id_field'],
  sendDate: ['send_date'],
  consentDate: ['consent_date'],
  investigator: ['investigator_name'],
  randomisationId: ['randomisation_id'],
  randomisationGroup: ['randomisation_group_name']
}

export const fetchEconsentStatus = (
  { studyId }: FetchEconsentStatusOptions,
  responseHandlers?: FetchEconsentStatusResponseHandlers
) => {
  const { req, cancel } = fetchApi.get('studies/econsentstatus', {}, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<FetchEconsentStatusResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

export const fetchEconsentItems = (
  { studyId, options }: FetchEconsentItemsOptions,
  responseHandlers?: FetchEconsentItemsResponseHandlers
) => {
  const sorter = prepareSorter<typeof sorterFields, EconsentSubjectsSorter>(sorterFields, options.sorter)
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    filter_lookup: options.search,
    status: options.filters?.status,
    study_center_id: options.filters?.center
  }
  const { req, cancel } = fetchApi.get<EconsentSubjectsResponse>('econsent/records', query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchEconsentItemsResponseHandlers>(
        {
          [BackendError.ECONSENT_NOT_EXISTS]: 'onNotExist'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseEconsentSubjectsResponse(body))
    }
  })

  return cancel
}

interface ImportEconsentOptions {
  studyId: string
  recordId: string
  email: string
  password: string
  fileId: string
}

interface ImportEconsentResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code?: number) => void
  onInvalidPassword?: () => void
  onUserBlocked?: () => void
  onError?: () => void
}

export const importEconsent = (
  { studyId, recordId, email, password, fileId }: ImportEconsentOptions,
  responseHandlers?: ImportEconsentResponseHandlers
) => {
  const query = {
    email,
    password,
    file_uuid: fileId
  }
  const { req, cancel } = fetchApi.post(`econsent/records/${recordId}/import`, query, { studyId })

  req.then(({ error }) => {
    if (error) {
      createErrorsHandlers<ImportEconsentResponseHandlers>(
        {
          [BackendError.USER_PASSWORD_WRONG]: 'onInvalidPassword',
          [BackendError.USER_TEMPORARILY_BLOCKED]: 'onUserBlocked'
        },
        error,
        responseHandlers
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface CreateEconsentQrCode {
  studyId: string
  recordId: string
}

interface CreateEconsentQrCodeResponse {
  token: string
  qr_code: string
}

interface CreateEconsentQrCodeResponseHandlers {
  onSuccess?: (token: string, qrCode: string) => void
  onRequestError?: (code?: number) => void
}

export const createEconsentQrCode = (
  { studyId, recordId }: CreateEconsentQrCode,
  responseHandlers?: CreateEconsentQrCodeResponseHandlers
) => {
  const path = `econsent/records/${recordId}/qr`
  const { req, cancel } = fetchApi.patch<CreateEconsentQrCodeResponse>(path, {}, { studyId })

  req.then(({ body, error }) => {
    if (error) {
      createErrorsHandlers<CreateEconsentQrCodeResponseHandlers>({}, error, responseHandlers)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.token, body.qr_code)
    }
  })

  return cancel
}
