/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { fetchApi } from '../fetchApi'

export interface PaymentStatsValues {
  currencies?: Record<
    string,
    {
      amount: number
      count: number
    }
  >
  count?: number
}

export interface PaymentStats {
  pending: PaymentStatsValues
  approved: PaymentStatsValues
  error: PaymentStatsValues
  processing: PaymentStatsValues
  total: PaymentStatsValues
}

interface FetchPaymentStatsResponseHandlers {
  onSuccess?: (stats: PaymentStats) => void
  onRequestError?: (code: number) => void
}

interface FetchPaymentStatsOptions {
  recruitmentStudyId?: string
}

export const fetchPaymentsStats = (
  { recruitmentStudyId }: FetchPaymentStatsOptions,
  responseHandlers: FetchPaymentStatsResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<PaymentStats>('payments/stats', {
    recruitment_uuid: recruitmentStudyId
  })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchPaymentStatsResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}
