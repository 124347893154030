export const getAnalysisParsedValue = (value: string, type: 'string' | 'number' | 'percentage') => {
  if (value == null) return '-'

  switch (type) {
    case 'number':
      return Math.round(Number(value) * 1000) / 1000
    case 'percentage':
      return `${Math.floor(Number(value) * 100)}%`
    default:
      return value
  }
}

export const getAnalysisParsedFilters = (filters: Record<string, string[]>) =>
  filters
    ? Object.entries(filters).reduce((acc, [name, selected]) => {
        if (!selected?.length) return acc
        return { ...acc, [name]: selected }
      }, {})
    : []
