/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'
import { FileAnswer } from '../forms'
import { parseRemotePhoneNumber, phoneNumberToString } from '../subjects'
import { EconsentStatus } from './dashboard'

const parseEconsentSubjects = (response: RemoteEconsentSubject[]): ClientEconsentSubjects => {
  return {
    consented: response.filter(item => item.status === EconsentStatus.Consented).map(item => parseRemoteSubject(item)),
    needSignature: response
      .filter(item => item.status === EconsentStatus.NeedsCounterSignature)
      .map(item => parseRemoteSubject(item))
  }
}

const parseEconsentSingleSubject = (response: RemoteEconsentSubjectForSigning): EconsentSubjectForSigning => ({
  id: response.subject.subject_id,
  uniqueId: response.datacapt_id,
  firstName: response.subject.first_name,
  lastName: response.subject.last_name,
  email: response.subject.email,
  internationalPhoneNumber: phoneNumberToString(parseRemotePhoneNumber(response.subject.phone)),
  signatureDate: response.date_signed ? new Date(response.date_signed) : undefined,
  status: response.status,
  center: response.center,
  centerCode: response.center_code,
  recordId: response.id,
  language: response.subject.language,
  investigatorName: response.investigator_name,
  security: response.security
})

export enum ClientEconsentSubjectsType {
  Consented = 'consented',
  NeedsCounterSignature = 'needSignature'
}

export interface ClientEconsentSubjects {
  consented: ClientEconsentSubject[]
  needSignature: ClientEconsentSubject[]
}

export interface RemoteEconsentSubject {
  subject: {
    subject_id: string
    first_name: string
    last_name: string
    language: string
  }
  status: EconsentStatus
  id: number
}

interface RemoteEconsentSubjectsList {
  results: RemoteEconsentSubject[]
}

export interface ClientEconsentSubject {
  subjectId: string
  firstName: string
  lastName: string
  status: EconsentStatus
  recordId: string
}

export interface RemoteEconsentSubjectForSigning {
  id: string
  subject: {
    subject_id: string
    first_name: string
    last_name: string
    email: string
    phone: string
    language: string
  }
  datacapt_id: string
  date_signed: string
  investigator_name: string
  status: EconsentStatus
  center: string
  center_code: string
  security: string
}

export interface EconsentSubjectForSigning {
  id: string
  uniqueId: string
  firstName: string
  lastName: string
  email: string
  internationalPhoneNumber: string
  signatureDate?: Date
  status: EconsentStatus
  center: string
  centerCode: string
  recordId: string
  language: string
  investigatorName: string
  security: string
}

const parseRemoteSubject = (remoteSubject: RemoteEconsentSubject) => ({
  subjectId: remoteSubject.subject.subject_id,
  firstName: remoteSubject.subject.first_name,
  lastName: remoteSubject.subject.last_name,
  status: remoteSubject.status,
  recordId: remoteSubject.id.toString(),
  language: remoteSubject.subject.language
})

interface FetchEconsentSubjectsOptions {
  studyId: string
  searchPhrase: string
}

interface FetchEconsentSubjectsResponseHandlers {
  onSuccess?: (subjects: ClientEconsentSubjects) => void
  onRequestError?: (code: number) => void
}

interface FetchSingleEconsentSubjectOptions {
  studyId: string
  recordId: string
}

interface FetchSingleEconsentSubjectResponseHandlers {
  onSuccess?: (subjects: EconsentSubjectForSigning) => void
  onRequestError?: (code: number) => void
  onError?: () => void
}

export interface CounterSignEconsentPayload {
  email: string
  password: string
  declaration: boolean
}

interface CounterSignEconsentOptions {
  studyId: string
  recordId: string
  payload: CounterSignEconsentPayload
}

interface CounterSignEconsentResponseHandlers {
  onSuccess?: () => void
  onWrongPasswordError?: () => void
  onRequestError?: (code: number) => void
}

export const fetchEconsentSubjectsForSigning = (
  { studyId, searchPhrase }: FetchEconsentSubjectsOptions,
  responseHandlers: FetchEconsentSubjectsResponseHandlers
) => {
  const query = { filter_lookup: searchPhrase, sign_dashboard: true }
  const { req, cancel } = fetchApi.get<RemoteEconsentSubjectsList>('econsent/records', query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchEconsentSubjectsResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess(parseEconsentSubjects(body.results))
    }
  })

  return cancel
}

export const fetchSingleEconsentSubjectForSigning = (
  { studyId, recordId }: FetchSingleEconsentSubjectOptions,
  responseHandlers: FetchSingleEconsentSubjectResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteEconsentSubjectForSigning>(`econsent/records/${recordId}`, {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSingleEconsentSubjectResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess(parseEconsentSingleSubject(body))
    }
  })

  return cancel
}

export const counterSignEconsent = (
  { recordId, studyId, payload }: CounterSignEconsentOptions,
  responseHandlers?: CounterSignEconsentResponseHandlers
) => {
  const { req, cancel } = fetchApi.post(`econsent/records/${recordId}/countersign`, payload, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<CounterSignEconsentResponseHandlers>(
        {
          [BackendError.ECONSENT_SECTION_WRONG_SIGNATURE]: 'onWrongPasswordError'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface SignEconsentSurveyOptions {
  token: string
  signature: string
}

interface SignEconsentSurveyResponseHandlers {
  onSuccess?: (pdfFile: FileAnswer) => void
  onRequestError?: (code: number) => void
}

export const signEconsentSurvey = (
  { token, signature }: SignEconsentSurveyOptions,
  responseHandlers?: SignEconsentSurveyResponseHandlers
) => {
  const { req, cancel } = fetchApi.post<FileAnswer>(`subject_accounts/econsent/answer/sign/${token}`, { signature })

  req.then(({ body, error, status }) => {
    if (error) {
      createErrorsHandlers<SignEconsentSurveyResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}

interface RejectEconsentSurveyCommonOptions {
  token: string
  qrCode?: string
  reason: string
}

interface RejectEconsentSurveyResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const rejectEconsentSurvey = (
  { token, reason, qrCode }: RejectEconsentSurveyCommonOptions,
  responseHandlers?: RejectEconsentSurveyResponseHandlers
) => {
  const url = `subject_accounts/econsent/answer/reject/${token}`
  const { req, cancel } = fetchApi.post<FileAnswer>(url, { reason }, { qrCode })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<RejectEconsentSurveyResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface ArchiveEconsentOptions {
  recordId: string
  studyId: string
}

interface ArchiveEconsentResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const archiveEconsent = (
  { recordId, studyId }: ArchiveEconsentOptions,
  responseHandlers?: ArchiveEconsentResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete(`econsent/records/${recordId}`, {}, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<ArchiveEconsentResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
