import { createErrorsHandlers, prepareSorter } from '../../utils'
import { BackendError } from '../RequestError'
import { fetchApi } from '../fetchApi'
import { RemoteUserData, UserData, UsersSorter, parseRemoteUser, usersSorterFields } from '../generalSettings'

interface FetchStudyUsersOptions {
  studyId: string
  options?: {
    limit?: number
    offset?: number
    sorter?: UsersSorter
    hasInclusions?: boolean
    search?: string
    filters?: Record<string, string[]>
  }
}

interface FetchStudyUsersResponseHandlers {
  onSuccess?: ({ studyUsers, allStudyUsersCount }: { studyUsers: UserData[]; allStudyUsersCount: number }) => void
  onRequestError?: (code: number) => void
}

interface FetchStudyUsersResponse {
  count: number
  results: RemoteUserData[]
}

export const fetchStudyUsers = (
  { studyId, options }: FetchStudyUsersOptions,
  responseHandlers?: FetchStudyUsersResponseHandlers
) => {
  const sorter = prepareSorter<typeof usersSorterFields, UsersSorter>(usersSorterFields, options.sorter, 'name')
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    has_inclusions: options.hasInclusions,
    search: options.search,
    role: options.filters?.role,
    study_center_id: options.filters?.center
  }

  const { req, cancel } = fetchApi.get<FetchStudyUsersResponse>('studies/users', query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchStudyUsersResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({ studyUsers: body.results.map(parseRemoteUser), allStudyUsersCount: body.count })
    }
  })

  return cancel
}

interface RemoveStudyUserOptions {
  studyId: string
  userId: string
}

interface RemoveStudyUserResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onCannotRemoveAdmin?: () => void
  onError?: () => void
}

export const removeStudyUser = (
  { studyId, userId }: RemoveStudyUserOptions,
  responseHandlers?: RemoveStudyUserResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete(`studies/users/${userId}`, {}, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<RemoveStudyUserResponseHandlers>(
        {
          [BackendError.STUDY_CANNOT_UPDATE_ADMIN]: 'onCannotRemoveAdmin'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface FetchUsersNotInStudyOptions {
  studyId: string
}

interface FetchUsersNotInStudyResponseHandlers {
  onSuccess?: (users: UserData[]) => void
  onRequestError?: (code: number) => void
}

export const fetchUsersNotInStudy = (
  { studyId }: FetchUsersNotInStudyOptions,
  responseHandlers?: FetchUsersNotInStudyResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteUserData[]>('studies/users/invite', {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchUsersNotInStudyResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.map(parseRemoteUser))
    }
  })

  return cancel
}

interface InviteUsersToStudyOptions {
  studyId: string
  userIds: string[]
  centerIds: string[]
}

interface InviteUsersToStudyResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const inviteUsersToStudy = (
  { studyId, userIds, centerIds }: InviteUsersToStudyOptions,
  responseHandlers?: InviteUsersToStudyResponseHandlers
) => {
  const query = {
    user_ids: userIds,
    center_ids: centerIds
  }
  const { req, cancel } = fetchApi.post('studies/users', query, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<InviteUsersToStudyResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface EditStudyUserOptions {
  studyId: string
  userId: string
  centerIds: string[]
  roleId: string
}

interface EditStudyUserResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onError?: () => void
}

export const editStudyUser = (
  { studyId, userId, centerIds, roleId }: EditStudyUserOptions,
  responseHandlers?: EditStudyUserResponseHandlers
) => {
  const query = {
    center_ids: centerIds,
    role_id: roleId
  }
  const { req, cancel } = fetchApi.patch(`studies/users/${userId}`, query, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<EditStudyUserResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
