/* eslint-disable camelcase */
import { ContentState, convertFromHTML, convertFromRaw } from 'draft-js'

import { createErrorsHandlers } from '../../utils'
import { fetchApi } from '../fetchApi'

export enum PaymentActivityType {
  Created = 'PAYMENT_CREATED',
  Confirmed = 'PAYMENT_CONFIRMED',
  StatusUpdated = 'PAYMENT_STATUS_UPDATED',
  Updated = 'PAYMENT_UPDATED'
}

type PaymentActivityChangeValues<T> = { old: T; new: T }

export type PaymentActivityChanges = Partial<{
  amount: PaymentActivityChangeValues<number>
  tax: PaymentActivityChangeValues<number>
  payment_date: PaymentActivityChangeValues<string>
  status: PaymentActivityChangeValues<string>
}>

interface RemotePaymentActivity {
  created_at: string
  type: PaymentActivityType
  user_name?: string
  changes?: PaymentActivityChanges
  details?: string
}

interface RemotePaymentActivityResponse {
  count: number
  results: RemotePaymentActivity[]
}

export interface PaymentActivity {
  date: Date
  type: PaymentActivityType
  userName?: string
  changes?: PaymentActivityChanges
  details?: ContentState
}

const parseRemotePaymentActivityDetails = (remoteDetails: string) => {
  if (!remoteDetails) return null

  try {
    return convertFromRaw(JSON.parse(remoteDetails))
  } catch {
    const blocksFromHTML = convertFromHTML(remoteDetails)
    return ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
  }
}

const parseRemotePaymentActivity = (activity: RemotePaymentActivity): PaymentActivity => {
  return {
    date: activity.created_at && new Date(activity.created_at),
    type: activity.type,
    userName: activity.user_name?.trim(),
    changes: activity.changes,
    details: parseRemotePaymentActivityDetails(activity.details)
  }
}

interface FetchPaymentActivitiesResponseHandlers {
  onSuccess?: (newActivities: PaymentActivity[], allActivitiesCount: number) => void
  onRequestError?: (code: number) => void
}

interface FetchPaymentActivitiesOptions {
  datacaptId: string
  recruitmentStudyId?: string
  options: {
    offset: number
    limit: number
  }
}

export const fetchPaymentActivities = (
  { datacaptId, recruitmentStudyId, options }: FetchPaymentActivitiesOptions,
  responseHandlers: FetchPaymentActivitiesResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemotePaymentActivityResponse>(`audit_trails/subject_activities/${datacaptId}`, {
    offset: options.offset,
    limit: options.limit,
    recruitment_id: recruitmentStudyId,
    type: Object.values(PaymentActivityType)
  })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchPaymentActivitiesResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess(body.results.map(parseRemotePaymentActivity), body.count)
    }
  })

  return cancel
}
