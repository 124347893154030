/* eslint-disable camelcase */
import { ContentState, EditorState, convertFromHTML, convertFromRaw, convertToRaw } from 'draft-js'

import {
  ConditionalRule,
  RemoteConditionalRule,
  parseRemoteConditionalLogic,
  prepareQuestionConditionalLogicForSave
} from '../conditionalLogic'

export enum StaticContentType {
  Instruction = 'INSTRUCTION',
  Header = 'HEADER',
  Image = 'IMAGE'
}

export interface StaticContentBase {
  id?: string
  tempId?: string
  type: StaticContentType
  isGlobal: boolean
  config: {}
  conditionalLogic?: ConditionalRule[]
}

export interface StaticContentInstruction extends StaticContentBase {
  type: StaticContentType.Instruction
  config: {
    instruction: ContentState | EditorState
  }
}

export interface StaticContentHeader extends StaticContentBase {
  type: StaticContentType.Header
  config: {
    header: string
  }
}

export interface StaticContentImage extends StaticContentBase {
  type: StaticContentType.Image
  config: {
    fileId: string
  }
}

export type StaticContent = StaticContentInstruction | StaticContentHeader | StaticContentImage

export interface RemoteStaticContentBase {
  id: string
  type: string
  is_global: boolean
  conditional_logic?: RemoteConditionalRule
  config: {}
}

export interface RemoteInstruction extends RemoteStaticContentBase {
  config: {
    content: string
  }
}

export interface RemoteHeader extends RemoteStaticContentBase {
  config: {
    content: string
  }
}

export interface RemoteImage extends RemoteStaticContentBase {
  config: {
    file_uuid: string
  }
}

export type RemoteStaticContent = RemoteInstruction | RemoteHeader | RemoteImage

const parseRemoteStaticContentType = (remoteStaticContentType: string) => {
  switch (remoteStaticContentType) {
    case 'INSTRUCTION':
      return StaticContentType.Instruction
    case 'HEADER':
      return StaticContentType.Header
    case 'IMAGE':
      return StaticContentType.Image
    default:
      return null
  }
}

export const parseRemoteStaticContentConfig = (remoteStaticContent: RemoteStaticContent) => {
  if (remoteStaticContent.type === StaticContentType.Header) {
    return { header: (remoteStaticContent as RemoteHeader)?.config?.content }
  }

  if (remoteStaticContent.type === StaticContentType.Instruction) {
    let remoteInstruction
    try {
      remoteInstruction = convertFromRaw(JSON.parse((remoteStaticContent as RemoteInstruction)?.config?.content))
    } catch {
      const blocksFromHTML = convertFromHTML((remoteStaticContent as RemoteInstruction)?.config?.content)
      remoteInstruction = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
    }
    return { instruction: remoteInstruction }
  }

  if (remoteStaticContent.type === StaticContentType.Image) {
    return {
      fileId: (remoteStaticContent as RemoteImage)?.config?.file_uuid
    }
  }

  return {}
}

export const parseRemoteStaticContent = (remoteStaticContent: RemoteStaticContent) => {
  return {
    id: String(remoteStaticContent.id),
    type: parseRemoteStaticContentType(remoteStaticContent.type),
    isGlobal: remoteStaticContent.is_global,
    conditionalLogic: parseRemoteConditionalLogic(remoteStaticContent.conditional_logic),
    config: parseRemoteStaticContentConfig(remoteStaticContent)
  }
}

const prepareStaticContentTypeForSave = (type: StaticContentType) => {
  switch (type) {
    case StaticContentType.Instruction:
      return 'INSTRUCTION'
    case StaticContentType.Header:
      return 'HEADER'
    case StaticContentType.Image:
      return 'IMAGE'
    default:
      return null
  }
}

const prepareConfigForSave = (staticContent: StaticContent) => {
  if (staticContent.type === StaticContentType.Header) {
    return { content: staticContent.config?.header }
  }

  if (staticContent.type === StaticContentType.Instruction) {
    let instruction
    try {
      instruction = convertToRaw((staticContent.config?.instruction as EditorState)?.getCurrentContent())
    } catch {
      instruction = convertToRaw(staticContent.config?.instruction as ContentState)
    }
    return { content: JSON.stringify(instruction) }
  }

  if (staticContent.type === StaticContentType.Image) {
    return { file_uuid: staticContent.config?.fileId }
  }

  return {}
}

export const prepareStaticContentForSave = (staticContent: StaticContent, order: number, subsectionId: string) => {
  return {
    id: staticContent.id,
    type: prepareStaticContentTypeForSave(staticContent.type),
    is_global: staticContent.isGlobal,
    conditional_logic:
      staticContent.conditionalLogic?.[0] && prepareQuestionConditionalLogicForSave(staticContent.conditionalLogic[0]),
    config: prepareConfigForSave(staticContent),
    subsection: subsectionId,
    order
  }
}
