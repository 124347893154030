/* eslint-disable camelcase */
import {
  Block,
  EcrfAnswer,
  Question,
  RemoteBlock,
  RemoteEcrfAnswer,
  ValidationRuleType,
  parseEcrfRemoteAnswer,
  parseRemoteBlock
} from '../../requests'
import { createErrorsHandlers } from '../../utils'
import { fetchApi } from '../fetchApi'

interface FetchReminderQuestionsOptions {
  studyId: string
  recordId: string
  questionId: string
}

interface FetchReminderQuestionsResponseHandlers {
  onSuccess?: (newReminderBlocks: Block[], newReminderAnswers: EcrfAnswer[]) => void
  onRequestError?: (code: number) => void
}

interface RemoteQuestionsFromStudy {
  blocks?: RemoteBlock[]
  results?: RemoteEcrfAnswer[]
}

export interface ReminderQuestionResponse {
  question: Question
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
  dataValidation?: {
    message: string
    type: ValidationRuleType
  }[]
}

export const fetchReminderQuestions = (
  { studyId, recordId, questionId }: FetchReminderQuestionsOptions,
  responseHandlers?: FetchReminderQuestionsResponseHandlers
) => {
  const path = `answers/${recordId}/${questionId}/reminder`
  const { req, cancel } = fetchApi.get<RemoteQuestionsFromStudy>(path, {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchReminderQuestionsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(
        body.blocks?.map(block => parseRemoteBlock(block)),
        body.results?.map(answer => parseEcrfRemoteAnswer(answer))
      )
    }
  })

  return cancel
}
