import {
  createFromStructure,
  fetchQuestionsDependencies,
  fetchSection,
  fetchStructure,
  fetchStructureSummary,
  fetchSubsection,
  searchQuestions,
  updateSection,
  updateStructure,
  updateSubsection
} from '../forms'

export const fetchEcrfQuestionsDependencies = fetchQuestionsDependencies(() => 'ecrf/blocks_for_builder_logic')

export const fetchEcrfStructure = fetchStructure(() => 'ecrf')

export const fetchEcrfSection = fetchSection<{ studyId: string; sectionId: string }>(
  ({ sectionId }) => `ecrf/${sectionId}`
)

export const fetchEcrfSubsection = fetchSubsection<{
  studyId: string
  sectionId: string
  subsectionId: string
  language: string
}>(({ sectionId, subsectionId }) => `ecrf/${sectionId}/${subsectionId}`)

export const createEcrfFromStructure = createFromStructure<{ studyId: string }>('ecrf', { studyId: 'study' })

export const updateEcrfStructure = updateStructure<{ studyId: string }>(() => 'ecrf', { studyId: 'study' })

export const fetchEcrfStructureSummary = fetchStructureSummary(() => 'ecrf/structure_for_export')

export const searchEcrfQuestions = searchQuestions(() => 'ecrf/variables')

export const updateEcrfSection = updateSection<{ sectionId: string }>(({ sectionId }) => `ecrf/${sectionId}`)

export const updateEcrfSubsection = updateSubsection<{ sectionId: string; subsectionId: string }>(
  ({ sectionId, subsectionId }) => `ecrf/${sectionId}/${subsectionId}`
)
