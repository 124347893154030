/* eslint-disable camelcase */
import { createErrorsHandlers, prepareSorter } from '../utils'
import { BackendError } from './RequestError'
import { SorterOrder } from './SorterOrder'
import { ExportFileFormat, canceller, downloadExportData } from './exports'
import { fetchApi } from './fetchApi'
import { FileInfo, RemoteFile, parseRemoteFile } from './file'

export const supportedDataAnalysisExtensions = ['xls', 'xlsx', 'csv', 'json']

export enum DataAnalyticsTableType {
  RawData = 'raw_data',
  AreaData = 'area_data',
  Statistics = 'statistics',
  Comparison = 'comparison',
  Report = 'report'
}
interface ImportDataAnalysisFileOptions {
  studyId: string
  file: File
}

interface ImportDataAnalysisFileResponseHandlers {
  onSuccess?: (savedFile: FileInfo) => void
  onWrongFileError?: (rowNr?: string, element?: string) => void
  onRequestError?: (code?: number) => void
}

export const importDataAnalysisFile = (
  { file, studyId }: ImportDataAnalysisFileOptions,
  responseHandlers: ImportDataAnalysisFileResponseHandlers
) => {
  const path = 'data_analysis/source_files'
  const { req, cancel } = fetchApi.postFile<RemoteFile>(path, { file }, { studyId })

  req.then(({ error, body }) => {
    if (error) {
      if (error?.code === BackendError.DATA_ANALYSIS_INVALID_FILE_CONTENT && responseHandlers?.onWrongFileError) {
        responseHandlers.onWrongFileError(error.data?.element, error.data?.row_no)
      } else {
        createErrorsHandlers<Omit<ImportDataAnalysisFileResponseHandlers, 'onWrongFileError'>>(
          {},
          error,
          responseHandlers
        )
      }
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteFile(body))
    }
  })

  return cancel
}

interface RefreshAnalysisDataResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code?: number) => void
}

export const refreshAnalysisData = (
  { studyId }: { studyId: string },
  responseHandlers: RefreshAnalysisDataResponseHandlers
) => {
  const { req, cancel } = fetchApi.post<RemoteFile>('data_analysis/refresh', {}, { studyId })

  req.then(({ error }) => {
    if (error) {
      createErrorsHandlers<RefreshAnalysisDataResponseHandlers>({}, error, responseHandlers)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

export interface RemoteRawData {
  id: string
  datetime: string
  probe_name: string
  subject: string
  kinetic: string
  zone: string
  product: string
  repetition: string
  value: string
  parameter_name: string
}

export interface RawData {
  id: string
  datetime: Date
  probe: string
  subject: string
  kinetic: string
  zone: string
  product: string
  repetition: string
  value: string
  parameter: string
}

interface FetchRawDataResponse {
  count: number
  next: number
  previous: number
  results: RemoteRawData[]
}

const parseRemoteRawData = (rawData: RemoteRawData) => ({
  id: String(rawData.id),
  datetime: rawData.datetime ? new Date(rawData.datetime) : undefined,
  probe: rawData.probe_name,
  subject: rawData.subject,
  kinetic: rawData.kinetic,
  zone: rawData.zone,
  product: rawData.product,
  repetition: rawData.repetition,
  value: rawData.value,
  parameter: rawData.parameter_name
})

export interface RawDataSorter {
  field: keyof RawData
  order: SorterOrder
}

const sorterFields = {
  probe: ['probe_name'],
  parameter: ['parameter__name']
}

interface FetchRawDataOptions {
  studyId: string
  options?: {
    limit?: number
    offset?: number
    sorter?: RawDataSorter
    search?: string
    filters?: Record<string, string>
  }
}

interface FetchRawDataResponseHandlers {
  onSuccess?: ({ rawData, allRawDataCount }: { rawData: RawData[]; allRawDataCount: number }) => void
  onRequestError?: (code: number) => void
}

export const fetchRawData = (
  { studyId, options }: FetchRawDataOptions,
  responseHandlers?: FetchRawDataResponseHandlers
) => {
  const sorter = prepareSorter<typeof sorterFields, RawDataSorter>(sorterFields, options.sorter)
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    parameter__name: options.filters.parameters,
    probe_name: options.filters.probe_names
  }

  const { req, cancel } = fetchApi.get<FetchRawDataResponse>('data_analysis/measurements', query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchRawDataResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        rawData: body.results.map(parseRemoteRawData),
        allRawDataCount: body.count
      })
    }
  })

  return cancel
}

export enum StatisticsParam {
  Parameters = 'parameters',
  ProbeNames = 'probe_names',
  Zones = 'zones',
  Products = 'products'
}

type RemoteStatisticsParams = Record<StatisticsParam, string[]>

export type StatisticsParams = {
  name: StatisticsParam
  options: string[]
}[]

const parseStatisticsParams = (remoteOptions: RemoteStatisticsParams) =>
  remoteOptions && Object.entries(remoteOptions).map(([name, options]) => ({ name: name as StatisticsParam, options }))

interface FetchStatisticsParamsResponseHandlers {
  onSuccess?: (parameters: StatisticsParams) => void
  onRequestError?: (code: number) => void
}

export const fetchStatisticsParams = (
  { studyId }: { studyId: string },
  responseHandlers?: FetchStatisticsParamsResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteStatisticsParams>('data_analysis/keys', {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchStatisticsParamsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseStatisticsParams(body))
    }
  })

  return cancel
}

type AreaDataZones = Record<string, number>
export type AreaDataKinetics = Record<string, AreaDataZones>

export interface RemoteAreaDataTable {
  probe_name: string
  parameter_name: string
  data: Record<string, AreaDataKinetics>
}

export interface AreaDataTable {
  probe: string
  parameter: string
  data: Record<string, AreaDataKinetics>
}

const parseRemoteAreaDataTable = (remoteTable: RemoteAreaDataTable) => ({
  probe: remoteTable.probe_name,
  parameter: remoteTable.parameter_name,
  data: remoteTable.data
})
interface FetchAreaDataOptions {
  studyId: string
  filters?: Record<string, string>
}

interface FetchAreaDataResponseHandlers {
  onSuccess?: (areaData: AreaDataTable[]) => void
  onRequestError?: (code: number) => void
}

export const fetchAreaData = (
  { studyId, filters }: FetchAreaDataOptions,
  responseHandlers?: FetchAreaDataResponseHandlers
) => {
  const query = {
    parameter__name: filters.parameters,
    probe_name: filters.probe_names
  }

  const { req, cancel } = fetchApi.get<RemoteAreaDataTable[]>('data_analysis/area_data', query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchAreaDataResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.map(parseRemoteAreaDataTable))
    }
  })

  return cancel
}

interface ExportDataAnalysisOptions {
  studyId: string
  fileFormat: ExportFileFormat
  zones: string[]
  products: string[]
}

interface ExportDataAnalysisResponse {
  export_uuid: string
}

interface ExportDataAnalysisResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const exportDataAnalysis = (
  { studyId, fileFormat, zones, products }: ExportDataAnalysisOptions,
  responseHandlers?: ExportDataAnalysisResponseHandlers
) => {
  const path = `exports/data_analysis`
  const query = {
    zone1: zones[0],
    zone2: zones[1],
    product1: products[0],
    product2: products[1],
    file_format: fileFormat
  }
  const { req, cancel } = fetchApi.post<ExportDataAnalysisResponse>(path, query, { studyId })
  canceller.cancelExportRequest = cancel
  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<ExportDataAnalysisResponseHandlers>({}, error, responseHandlers, status)
    } else {
      canceller.cancelDownload = downloadExportData({ exportId: body.export_uuid }, responseHandlers)
    }
  })

  return () => {
    canceller.cancelDownload()
    canceller.cancelExportRequest()
  }
}

interface RemoteStatistics {
  rows: Record<string, Record<string, string>>
  diff_rows: Record<string, Record<string, string>>
}
export enum StatisticsKeys {
  Count = 'count',
  Mean = 'mean',
  Median = 'median',
  Minimum = 'minimum',
  Maximum = 'maximum',
  Sem = 'sem',
  Cl95 = 'cl_95',
  TestName = 'test_name',
  Ttest = 'ttest',
  Improvement = 'improvement',
  Percent = 'percent_with_improvement'
}

const parseRemoteStatisticsDataResponse = ({ rows, diff_rows }: RemoteStatistics, tableKeys: string[]) => {
  const parsedRows = tableKeys.reduce(
    (acc, key) => [
      ...acc,
      {
        key,
        values: {
          ...([StatisticsKeys.Ttest, StatisticsKeys.Improvement, StatisticsKeys.Percent].includes(key as StatisticsKeys)
            ? {}
            : rows[key]),
          ...diff_rows[key]
        }
      }
    ],
    [] as StatisticsDataRow[]
  )

  const columns = Object.keys(parsedRows.find(r => r.key === 'count')?.values)

  // we need row with tests names. for now all tests are ttest, and BE doesn't sends that
  const testNameRow = {
    key: StatisticsKeys.TestName,
    values: columns.reduce(
      (acc, key) => ({
        ...acc,
        [key]: 't-Test'
      }),
      {} as Record<string, string>
    )
  }

  return [...parsedRows.filter(r => r.key !== StatisticsKeys.TestName), testNameRow]
}

const parseRemoteStatisticsResponse = (name: string, remoteStatistics: RemoteStatistics, tableKeys: string[]) => ({
  name,
  statistics: parseRemoteStatisticsDataResponse(remoteStatistics, tableKeys)
})

export interface StatisticsDataRow {
  key: StatisticsKeys | string
  values: Record<string, string>
}

export interface StatisticData {
  name: string
  statistics: StatisticsDataRow[]
}

interface FetchStatisticsOptions {
  studyId: string
  parameter: string
  zone: string
  product: string
}

interface FetchStatisticsResponseHandlers {
  onSuccess?: (statistics: StatisticData) => void
  onRequestError?: (code: number) => void
}

export const fetchStatistics = (
  { studyId, parameter: parameter_name, zone, product }: FetchStatisticsOptions,
  responseHandlers?: FetchStatisticsResponseHandlers
) => {
  const path = 'data_analysis/statistics'
  const { req, cancel } = fetchApi.get<RemoteStatistics>(path, { parameter_name, zone, product }, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchStatisticsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteStatisticsResponse(null, body, Object.keys(body.rows)))
    }
  })

  return cancel
}

interface FetchComparisonOptions {
  studyId: string
  parameter: string
  zones: string[]
  products: string[]
}

type RemoteComparison = Record<string, RemoteStatistics> & { comparison: Record<string, Record<string, string>> }

interface FetchComparisonResponseHandlers {
  onSuccess?: (comparison: StatisticData[]) => void
  onRequestError?: (code: number) => void
}

const parseRemoteComparisonResponse = (remoteComparison: RemoteComparison) => {
  const [firstTableName, secondTableName] = Object.keys(remoteComparison)
  const specialKeys = Object.values(StatisticsKeys)
  const sortedSubjectIds = Array.from(
    new Set([
      ...Object.keys(remoteComparison[firstTableName].diff_rows),
      ...Object.keys(remoteComparison[firstTableName].rows),
      ...Object.keys(remoteComparison[secondTableName].diff_rows),
      ...Object.keys(remoteComparison[secondTableName].rows)
    ])
  )
    .filter(v => !specialKeys.includes(v as StatisticsKeys))
    .sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))

  const tableKeys = [...sortedSubjectIds, ...specialKeys]

  const firstTable = parseRemoteStatisticsResponse(firstTableName, remoteComparison[firstTableName], tableKeys)
  const secondTable = parseRemoteStatisticsResponse(secondTableName, remoteComparison[secondTableName], tableKeys)
  const comparisonTable = Object.keys(remoteComparison.comparison).length
    ? parseRemoteStatisticsResponse(
        'comparison',
        {
          rows: {},
          diff_rows: remoteComparison.comparison
        },
        tableKeys
      )
    : null

  return [firstTable, secondTable, comparisonTable]
}

export const fetchComparison = (
  { studyId, parameter: parameter_name, zones, products }: FetchComparisonOptions,
  responseHandlers?: FetchComparisonResponseHandlers
) => {
  const query = { parameter_name, zone1: zones[0], zone2: zones[1], product1: products[0], product2: products[1] }
  const { req, cancel } = fetchApi.get<RemoteComparison>('data_analysis/comparison', query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchComparisonResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteComparisonResponse(body))
    }
  })

  return cancel
}

interface RemoteParameterReportZone {
  ttest: Record<string, string>
  decision: Record<string, ParameterKineticDecision>
  improvement: Record<string, string>
  percent_with_improvement: Record<string, string>
  mean: Record<string, string>
  sem: Record<string, string>
  variation_on_mean: Record<string, string>
}

type RemoteParameterReport = Record<string, RemoteParameterReportZone>

export enum ParameterKineticDecision {
  Yes = 'YES',
  No = 'NO'
}

export interface ParameterReportZone {
  zone: string
  kinetic: string[]
  decision: string[]
  ttest: string[]
  improvement: string[]
  percent: string[]
  mean: string[]
  sem: string[]
  variation: string[]
}

export interface ParameterReport {
  parameter: string
  zones: ParameterReportZone[]
}

const parseRemoteParameterReport = (remoteReport: RemoteParameterReport) =>
  remoteReport &&
  Object.entries(remoteReport).map(([parameter, zones]) => {
    return {
      parameter,
      zones:
        zones &&
        Object.entries(zones).map(([zone, data]) => {
          const values = data as RemoteParameterReportZone
          return {
            zone,
            kinetic: values?.ttest && Object.keys(values.ttest),
            ttest: values?.ttest && Object.values(values.ttest),
            decision: values?.decision && Object.values(values.decision),
            improvement: values?.improvement && Object.values(values.improvement),
            percent: values?.percent_with_improvement && Object.values(values.percent_with_improvement),
            mean: values?.mean && Object.values(values.mean),
            sem: values?.sem && Object.values(values.sem),
            variation: values?.variation_on_mean && Object.values(values.variation_on_mean)
          }
        })
    }
  })

interface FetchReportOptions {
  studyId: string
  zones: string[]
  products: string[]
  filters?: Record<string, string>
}

interface FetchReportResponseHandlers {
  onSuccess?: (report: ParameterReport[]) => void
  onRequestError?: (code: number) => void
}

export const fetchReport = (
  { studyId, zones, products, filters }: FetchReportOptions,
  responseHandlers?: FetchReportResponseHandlers
) => {
  const query = {
    zone1: zones[0],
    zone2: zones[1],
    product1: products[0],
    product2: products[1],
    parameter__name: filters.parameters,
    probe_name: filters.probe_names
  }

  const { req, cancel } = fetchApi.get<RemoteParameterReport>('data_analysis/report', query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchReportResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteParameterReport(body))
    }
  })

  return cancel
}
